import React from 'react'
import { useFormik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Col, Form, InputGroup, InputGroupText, Label, Modal, Row, FormGroup, Input, TabContent, TabPane, Nav, NavItem, NavLink, Collapse } from "reactstrap";
import Datetime from 'react-datetime';
import Select from 'react-select';
import moment from 'moment';
import { faCalendar, faXmark, faTrash } from '@fortawesome/free-solid-svg-icons';
import { selectDropdownStyle } from '../../../../constants/General';
import { addTimeSlots } from '../../utils/middleware/events';
import { removeEmptyKeysFromObject, trimObjectValues } from '../../../../services/middleware';
import { useState, useEffect } from 'react';

export default function EventTimeModal(props) {
  // Props
  const { open, handleCloseModal, eventData } = props
  const [selectRepeat, setSelectRepeat] = useState('once')
  const [cadence, setCadence] = useState('Month')
  const [options, setOptions] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [timeDiff, setTimeDiff] = useState("");
  const [repeatEvery, setRepeatEvery] = useState("");

  const [slots, setSlots] = useState([]);
  const [slotSingle, setSlotSingle] = useState([]);
  const [slotInterval, setSlotInterval] = useState(60);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedDays, setSelectedDays] = useState([]);

  // Predefined slot intervals
  const slotIntervals = [15, 30, 45, 60, 120, 180, 240];

  const [activeTab, setActiveTab] = useState("1");

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
      startTime: startTime,
      endTime: endTime,
      frequency: selectRepeat,
      categoryName: null,
      description: null,
      cadence: cadence,
      byDay: selectedDays,
      interval: repeatEvery,
      timeSlots: slots,
      timeSlot: slotSingle,
    },
    onSubmit: (values) => {
      const payload = {
        eventId: eventData?.id,
        timeSlots: slots,
        recurrence: {
          startDate: moment(values?.startDate?._d).format('YYYY-MM-DD'),
          endDate: moment(values?.endDate?._d).format('YYYY-MM-DD'),
          frequency: selectRepeat,
          interval: 1,
          byDay: selectedDays.join(","),
          cadence: cadence,
        }
      }
      const { sessions } = generateSessions(payload);
      payload.timeSlots = sessions;
      addTimeSlots(payload, handleCloseModal);
    }
  });

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const categories = [
    { id: 0, label: "Once", value: "once" },
    { id: 1, label: "Daily", value: "daily" },
    { id: 2, label: "Weekly", value: "weekly" },
    { id: 3, label: "Monthly", value: "monthly" },
    { id: 4, label: "Custom", value: "custom" }
  ];

  const days = [
    { id: 1, label: "Mo", value: "Monday" },
    { id: 2, label: "Tu", value: "Tuesday" },
    { id: 3, label: "We", value: "Wednesday" },
    { id: 4, label: "Th", value: "Thursday" },
    { id: 5, label: "Fr", value: "Friday" },
    { id: 6, label: "Sa", value: "Saturday" },
    { id: 7, label: "Su", value: "Sunday" },
  ];

  const toggleDay = (dayValue) => {
    setSelectedDays((prevSelected) =>
      prevSelected.includes(dayValue)
        ? prevSelected.filter((day) => day !== dayValue)
        : [...prevSelected, dayValue]
    );
  };


  const generateDropdownOptions = (date) => {
    if (!date || !moment(date).isValid()) return [];

    const selectedDate = moment(date);
    const dayNumber = selectedDate.date();
    const dayName = selectedDate.format("dddd");
    const nthWeek = Math.ceil(dayNumber / 7);

    return [
      { value: `on_${dayNumber}`, label: `On the ${dayNumber}th` },
      { value: `on_${nthWeek}_${dayName}`, label: `On the ${nthWeek} ${dayName}` },
    ];
  };


  useEffect(() => {
    if (formik.values.startDate) {
      setOptions(generateDropdownOptions(formik.values.startDate));
    }
  }, [formik.values.startDate]);


  const calculateTimeDifference = (start, end) => {
    if (!start || !end) return "";

    const startDate = new Date(`2000-01-01T${start}`);
    const endDate = new Date(`2000-01-01T${end}`);

    if (endDate < startDate) return "Invalid time";

    const diffMs = endDate - startDate;
    const diffMins = Math.floor(diffMs / (1000 * 60));

    return `${diffMins} mins`;
  };

  const handleEndTimeChange = (e) => {
    const newEndTime = e.target.value;
    const timeDIff = calculateTimeDifference(startTime, newEndTime)
    setEndTime(newEndTime);
    setTimeDiff(timeDIff);
    formik.setFieldValue("endTime", newEndTime)
    formik.setFieldValue("startTime", startTime)
    let slotsArray = [];
    slotsArray.push({
      start: startTime,
      end: newEndTime,
      duration: timeDIff,
    });
    setSlotSingle(slotsArray)
    generateTimeSlots()
    formik.setFieldValue("timeSlot", slotsArray)
  };

  useEffect(() => {
    generateTimeSlots()
  }, [startTime, endTime, slotInterval]);

  const generateTimeSlots = () => {
    if (!startTime || !endTime) return;

    let slotsArray = [];
    let current = new Date(`2000-01-01T${startTime}`);
    let end = new Date(`2000-01-01T${endTime}`);

    while (current < end) {
      let nextSlot = new Date(current);
      nextSlot.setMinutes(current.getMinutes() + slotInterval);

      if (nextSlot > end) break;

      slotsArray.push({
        start: current.toTimeString().slice(0, 5),
        end: nextSlot.toTimeString().slice(0, 5),
        duration: slotInterval,
      });

      current = nextSlot;
    }

    setSlots(slotsArray);
    formik.setFieldValue("timeSlots", slotsArray)
  };

  function generateSessions(data) {
    const { recurrence, timeSlots } = data;
    const sessions = [];
    const days = recurrence.byDay ? recurrence.byDay.split(',') : [];
    const startDate = new Date(recurrence.startDate);
    const endDate = new Date(recurrence.endDate);
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const dayName = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
      if (recurrence.frequency === "daily") {
        timeSlots.forEach((slot) => createSession(sessions, currentDate, slot));
      } else if (recurrence.frequency === "weekly" && days.includes(dayName)) {
        timeSlots.forEach((slot) => createSession(sessions, currentDate, slot));
      } else if (recurrence.frequency === "monthly" && recurrence.occurs) {
        if (recurrence.occurs.includes("On the") && recurrence.occurs.includes("Thursday")) {
          const [nth, weekday] = recurrence.occurs.replace("On the ", "").split(" ");
          const nthOccurrence = parseInt(nth);
          if (isNthWeekday(currentDate, weekday, nthOccurrence)) {
            timeSlots.forEach((slot) => createSession(sessions, currentDate, slot));
          }
        } else {
          const occursDate = parseInt(recurrence.occurs.replace(/\D/g, ''));
          if (currentDate.getDate() === occursDate) {
            timeSlots.forEach((slot) => createSession(sessions, currentDate, slot));
          }
        }
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return { sessions };
  }

  function createSession(sessions, date, slot) {
    const sessionStart = new Date(date);
    const [startHour, startMinute] = slot.start.split(':');
    sessionStart.setHours(parseInt(startHour), parseInt(startMinute));

    const sessionEnd = new Date(date);
    const [endHour, endMinute] = slot.end.split(':');
    sessionEnd.setHours(parseInt(endHour), parseInt(endMinute));

    sessions.push({
      id: Date.now() + Math.floor(Math.random() * 1000),
      slotDate: sessionStart.toISOString().slice(0, 10),
      start: sessionStart.toISOString().slice(11, 16),
      end: sessionEnd.toISOString().slice(11, 16),
      tickets: []
    });
  }

  function isNthWeekday(date, weekday, nthOccurrence) {
    const month = date.getMonth();
    const year = date.getFullYear();
    let count = 0;

    for (let day = 1; day <= 31; day++) {
      const tempDate = new Date(year, month, day);
      if (tempDate.getMonth() !== month) break;
      if (tempDate.toLocaleDateString('en-US', { weekday: 'long' }) === weekday) {
        count++;
        if (count === nthOccurrence && tempDate.getDate() === date.getDate()) {
          return true;
        }
      }
    }
    return false;
  }





  const removeSlot = (index) => {
    setSlots(slots.filter((_, i) => i !== index));
  };


  return (
    <Modal
      className="modal-dialog-centered event-modal"
      isOpen={open}
      size='sm'
      toggle={handleCloseModal}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Add dates and times
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardBody className='eventAddCardbody'>
            <div className='eventAddDiv'>
              <h1>Date</h1>
              <Label className="inputfield required-field">Start Date</Label>
              <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                <InputGroupText>
                  <FontAwesomeIcon icon={faCalendar} />
                </InputGroupText>
                <Datetime
                  dateFormat={"DD-MM-YYYY"}
                  timeFormat={false}
                  onChange={(value) => formik.setFieldValue("startDate", value)}
                  value={formik.values.startDate}
                  isValidDate={(currentDate) => currentDate.isAfter(moment().subtract(1, "day"))}
                />
              </InputGroup>

              <Label className='inputfield required-field'>
                Repeats
              </Label>
              <section className="mb-3 input-group-alternative">
                <Select
                  className="custom-select"
                  options={categories}
                  value={categories.find(option => option.value === formik.values.categoryName) || null}
                  isSearchable={false}
                  styles={selectDropdownStyle}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("categoryName", selectedOption ? selectedOption.value : null);
                    formik.setFieldValue("retailerId", "");
                    setSelectRepeat(selectedOption ? selectedOption.value : null)
                  }}
                />
                {formik.touched?.categoryName && formik.errors.categoryName && (
                  <span className="error-message input-group-alternative">
                    {formik.errors.categoryName}
                  </span>
                )}
              </section>

              {selectRepeat == 'custom' && <div>
                <div>
                  <Label className="inputfield required-field">Repeat every</Label>
                  <Input type="text" name="repeatMin" value={formik.values.repeatMin} onChange={(e) => { formik.handleChange(e); setRepeatEvery(e.target.value) }} />
                </div>
                <div>
                  <Label className="inputfield required-field">Cadence</Label>
                  <Input
                    className="custom-select"
                    type="select"
                    name="cadence"
                    value={formik.values.cadence}
                    onChange={(e) => {
                      formik.handleChange(e);
                      setCadence(e.target.value)
                    }}
                  >
                    <option value="">Select</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                  </Input>
                </div>
              </div>}

              {(selectRepeat == 'weekly' || cadence == 'week') && <div>
                <Label className='inputfield required-field'>
                  Repeat on
                </Label>
                <div className="d-flex gap-2">
                  {days.map((day) => (
                    <Button
                      key={day.id}
                      onClick={() => toggleDay(day.value)}
                      color={selectedDays.includes(day.value) ? "primary" : "light"}
                      className="rounded-circle"
                      style={{
                        width: "40px",
                        height: "40px",
                        padding: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      {day.label}
                    </Button>
                  ))}
                </div>
              </div>}

              {(selectRepeat == 'monthly' || cadence == 'month') && <div>
                <Label className="inputfield required-field">Occurs</Label>
                <Select
                  options={options}
                  value={options.find((option) => option.value === formik.values.occurs) || null}
                  onChange={(selectedOption) => formik.setFieldValue("occurs", selectedOption?.value)}
                  placeholder="Select occurrence"
                />
              </div>}



              {selectRepeat != 'once' && <div>
                <Label className='inputfield required-field'>
                  End Date
                </Label>
                <InputGroup className="input-group-alternative mb-3 date-time-input-group">
                  <InputGroupText>
                    <FontAwesomeIcon icon={faCalendar} />
                  </InputGroupText>
                  <Datetime
                    dateFormat={'DD-MM-YYYY'}
                    timeFormat={false}
                    onChange={(value) => {
                      if (moment(value).isSame(moment(formik.values.endDate)) || moment(value).isAfter(moment(formik.values.endDate))) {
                        formik.setFieldValue("endDate", moment(value).add(1, 'hour'))
                      }
                      formik.setFieldValue("endDate", value)
                    }}
                    value={formik.values.endDate}
                    isValidDate={(currentDate) => currentDate.isAfter(moment().subtract(1, 'day').format())}
                  />
                  {formik.touched?.endDate && formik.errors.endDate && (
                    <span className="error-message input-group-alternative">
                      {formik.errors.endDate}
                    </span>
                  )}
                </InputGroup>
              </div>}
            </div>
            <div className='eventAddDiv'>
              <h1>Time</h1>
              <Nav tabs className='border-0'>
                <NavItem className='mr-3'>
                  <NavLink className={`custom-tab ${activeTab === "1" ? "active-tab" : ""}`} onClick={() => toggleTab("1")}>
                    Single time
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className={`custom-tab ${activeTab === "2" ? "active-tab" : ""}`} onClick={() => toggleTab("2")}>
                    Multiple times
                  </NavLink>
                </NavItem>
              </Nav>


              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className='flex justify-content-between'>
                    <div className="flex-grow-1">
                      <Label for="startTime" className="text-muted small">Start time <span className="text-danger">*</span></Label>
                      <Input
                        type="time"
                        id="startTime"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                      />
                    </div>

                    {/* End Time Field with Difference */}
                    <div className="flex-grow-1">
                      <Label for="endTime" className="text-muted small">End time <span className="text-danger">*</span></Label>
                      <Input
                        type="time"
                        id="endTime"
                        value={endTime}
                        onChange={handleEndTimeChange}
                      />
                      {timeDiff && <div className="text-muted small mt-1">{endTime} ({timeDiff})</div>}
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Card className="border rounded shadow p-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5>Generate time slots between</h5>
                      <Button color="link" onClick={() => setIsOpen(!isOpen)}>
                        {isOpen ? "▲" : "▼"}
                      </Button>
                    </div>
                    <Collapse isOpen={isOpen}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <div>
                              <Label className="small text-muted">Start time *</Label>
                              <Input
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <Label className="small text-muted">End time *</Label>
                              <Input type="time" value={endTime} onChange={handleEndTimeChange} />
                              {timeDiff && <div className="small text-muted mt-1">{endTime} ({timeDiff})</div>}
                            </div>
                          </Col>
                        </Row>


                        <h5>Time slots repeat every</h5>
                        <div className="d-flex gap-2 flex-wrap">
                          {slotIntervals.map((interval) => (
                            <Button
                              key={interval}
                              color={slotInterval === interval ? "primary" : "light"}
                              onClick={() => setSlotInterval(interval)}
                              className="slotBTN"
                            >
                              {interval < 60 ? `${interval} min` : `${interval / 60} hours`}
                            </Button>
                          ))}
                        </div>


                        <div className="mt-3">
                          <Button color="primary" onClick={generateTimeSlots}>
                            Generate Time Slot
                          </Button>
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>


                  {slots.length > 0 && (
                    <div className="mt-4">
                      {slots.map((slot, index) => (
                        <Row key={index} className="align-items-center border rounded p-2 mb-2">
                          <Col>
                            <div>
                              <Label className="small text-muted">Start time *</Label>
                              <Input type="text" value={slot.start} readOnly />
                            </div>
                          </Col>
                          <Col>
                            <div>
                              <Label className="small text-muted">End time *</Label>
                              <Input type="text" value={slot.end} readOnly />
                              <div className="small text-muted">{slot.end} ({slot.duration} min)</div>
                            </div>
                          </Col>
                          <Col xs="auto">
                            <Button color="danger" onClick={() => removeSlot(index)}>
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </div>
                  )}
                </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" onClick={formik.handleSubmit} >
          Submit
        </Button>
      </div>

      <style>
        {`
          .custom-tab {
            background: #f8f9fa;
            color: #333;
            padding: 12px 15px;
            font-size: 16px;
            border: none;
            width: 100%;
            text-align: left;
            transition: 0.3s;
            cursor: pointer;
            border-radius: 6px;
          }
          .custom-tab:hover {
            background: #e9ecef;
          }
          .active-tab {
            background: #e63950;
            color: white !important;
          }
          .slotBTN{
            padding: 5px 10px;
            margin-top: 5px;
          }
        `}
      </style>
    </Modal>
  )
}
