import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, Media, Table, Row, Button, Badge } from "reactstrap";
import { addCategories, deleteCategory, editCategories, getCategories } from '../../utils/middleware/categories';
import NoDataLabel from '../../../../components/NoDataLabel';
import ConfirmationModal from "../../../../components/ConfirmationModal";
import CategoryModal from '../../components/CategoryModal';
import { dispatch, dispatchNoPayload } from '../../../../utils/store';
import { Actions } from '../../../../redux/actions';
import CustomPagination from '../../../../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { handleSortColumn, setSelectedDropdownForSameValue, sortIcon } from '../../../../services/middleware';
import SearchInput from '../../../../components/SearchInput';
import { RoutesActions } from '../../../../constants/General';
import ViewSubCategoriesModal from '../../components/ViewSubCategoriesModal';

export default function Categories() {
  // Store
  const { categories, search, activeSort, sortOrder, totalCount, page } = useSelector(state => state.restaurantAdminCategories)
  const { pageSize } = useSelector(state => state.user)

  // State
  const [showAddModal, setShowAddModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showViewSubcategoriesModal, setShowViewCategoriesModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [parentCategory, setParentCategory] = useState(null)
  const [isParentCategory, setIsParentCategory] = useState(true);
  const [subCategories, setSubCategories] = useState([]);

  // Mount
  useEffect(() => {
    getCategories(search, activeSort, sortOrder, page)
    let pageCount = Math.ceil(totalCount / pageSize)
    if (totalCount && page > pageCount) {
      dispatch(Actions.RestaurantAdminCategories.SetPage, pageCount)
    }
  }, [search, activeSort, sortOrder, totalCount, page, pageSize])

  const handleCloseAddModal = () => {
    setShowAddModal(false)
    setSelectedCategory(null)
  }

  const handleCloseEditModal = () => {
    setShowEditModal(false)
    setSelectedCategory(null)
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false)
    setSelectedCategory(null)
  }

  // Unmount
  useEffect(() => () => dispatchNoPayload(Actions.RestaurantAdminCategories.Reset), [])

  return (
    <div>
      <div className="custom-container">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 space-between-div table-header-div">
                <h3 className="mb-0">Categories List</h3>
                <div className='right-div-wrap'>
                  <SearchInput action={RoutesActions.categories} />
                  <Button color='primary' onClick={() => setShowAddModal(true)}>
                    Add
                  </Button>
                </div>
              </CardHeader>
              {categories.length !== 0 ? <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    {/* <th scope='col' className='serial-number cursor-pointer' onClick={() => handleSortColumn('', '', '', 'categories')}>No.</th> */}
                    <th scope='col' className={`${activeSort === 'name' ? 'active-sort-column' : 'cursor-pointer'}`} onClick={() => handleSortColumn('name', activeSort, sortOrder, 'categories')}>Name <FontAwesomeIcon icon={sortIcon(activeSort, 'name', sortOrder)} /></th>
                    <th scope='col' >Type</th>
                    <th scope='col' className='text-right'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category, index) => {
                    return <tr key={index}>
                      {/* <td className='serial-number'>{index + 1 + (page - 1) * pageSize}</td> */}
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {category.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <th><Badge className="mr-2 badge-info">
                        {category.isParentCategory ? 'Parent' : ''}
                      </Badge></th>
                      <td className="text-right">
                        {!!category.subCategories?.length && category.isParentCategory && <Button color="success" className='action-icon-btn' title='Show Sub Categories' onClick={() => {
                          setSelectedCategory(category)
                          setIsParentCategory(category.isParentCategory)
                          setParentCategory(setSelectedDropdownForSameValue(category.name))
                          setSubCategories(category.subCategories)
                          setShowViewCategoriesModal(true)
                        }}>
                          <FontAwesomeIcon icon={faEye} />
                        </Button>}
                        <Button color="success" className='action-icon-btn' title='Edit' onClick={() => {
                          setSelectedCategory(category)
                          setIsParentCategory(category.isParentCategory)
                          setParentCategory(setSelectedDropdownForSameValue(category?.parentCategory?.name || ''))
                          setShowEditModal(true)
                        }}>
                          <FontAwesomeIcon icon={faPen} />
                        </Button>
                        <Button color="danger" className='action-icon-btn' title='Delete' onClick={() => {
                          setSelectedCategory(category)
                          setShowDeleteModal(true)
                        }}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  })}
                </tbody >
              </Table> : <NoDataLabel />}
              <CustomPagination totalCount={totalCount} page={page} handlePageClick={({ selected }) => dispatch(Actions.RestaurantAdminCategories.SetPage, selected + 1)} />
            </Card>
          </div>
        </Row>
      </div>

      {showDeleteModal && <ConfirmationModal open={showDeleteModal} handleCloseModal={handleCloseDeleteModal} handleSuccess={() => deleteCategory(selectedCategory?.id, search, activeSort, sortOrder, page, handleCloseDeleteModal)} />}
      {showAddModal && <CategoryModal open={showAddModal} handleCloseModal={handleCloseAddModal} handleSuccess={(data) => addCategories(data, search, activeSort, sortOrder, page, handleCloseAddModal)} />}
      {showEditModal && <CategoryModal open={showEditModal} edit isParentCategory={isParentCategory} parentCategory={parentCategory} category={selectedCategory} handleCloseModal={handleCloseEditModal} handleSuccess={(data) => editCategories(selectedCategory?.id, data, search, activeSort, sortOrder, page, handleCloseEditModal)} />}
      {showViewSubcategoriesModal && <ViewSubCategoriesModal open={showViewSubcategoriesModal} selectedCategory={selectedCategory} setIsParentCategory={setIsParentCategory} setSelectedCategory={setSelectedCategory} setShowEditModal={setShowEditModal} setShowDeleteModal={setShowDeleteModal} categories={subCategories} handleCloseModal={() => setShowViewCategoriesModal(false)} />}
    </div >
  )
}
