export function ticketsListDTO(data) {
  let tickets = [];

  if (data?.length) {
    tickets = data.map((ticket) => ({
      id: ticket?.id || '',
      isPaidEvent: ticket?.isPaidEvent || '',
      isDonationEvent: ticket?.isDonationEvent || '',
      event: ticket?.event || '',
      eventId: ticket?.eventId || '',
      ticketName: ticket?.ticketName || '',
      ticketQuantity: ticket?.ticketQuantity || '',
      ticketPrice: ticket?.ticketPrice || '',
      ticketSolds: ticket?.ticketSolds || '',
      salesStartDate: ticket?.salesStartDate || '',
      salesStartTime: ticket?.salesStartTime || '',
      salesEndDate: ticket?.salesEndDate || '',
      salesEndTime: ticket?.salesEndTime || '',
      ticketDescription: ticket?.ticketDescription || '',
      ticketMaximun: ticket?.ticketMaximun || '',
      ticketMinimum: ticket?.ticketMinimum || '',
      hideSaleDates: ticket?.hideSaleDates || '',
      salesChannel: ticket?.salesChannel || '',
      iseTicket: ticket?.iseTicket || '',
      deliveryMethods: ticket?.deliveryMethods || '',
      restaurantId: ticket?.restaurantId || '',
      ticketStatus: ticket?.ticketStatus || '',
    }))
  }

  return tickets;
}

export function ticketsSelectListDTO(data) {
  let tickets = [];

  if (data?.length) {
    tickets = data.map((ticket) => ({
      value: ticket?.id || '',
      label: ticket?.ticketName || '',
    }))
  }

  return tickets;
}