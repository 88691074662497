import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Modal, Collapse, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import InputField from '../../../../components/InputField';
import Select from 'react-select';
import { getEvent, addTicket, updateTicket } from '../../utils/middleware/events';
import { removeEmptyKeysFromObject, trimObjectValues } from '../../../../services/middleware';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "./event.css"
export default function EventTicket(props) {
  // Props

  const { open, ticketsData, handleCloseModal, eventData, event } = props
  const { eventList } = useSelector(state => state.restaurantAdminEvents)
  // Store
  const [buttonText, setButtonText] = useState('Generate campaign content through AI');

  //State
  const [isPriceDisable, setIsPriceDisable] = useState(false);
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [isPaidEvent, setIsPaidEvent] = useState(true);
  const [isdonationEvent, setIsdonationEvent] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [buttonType, setButtonType] = useState('paid');
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");
  const [isContinue, setIsContinue] = useState(false); // Track isOpen state

  useEffect(() => {
    getEvent({ search: {}, sortBy: {}, orderBy: {}, pageSize: 500 })
  }, []);

  const toggleAdvancedSettings = (e) => {
    setIsAdvancedOpen(!isAdvancedOpen)
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const searchEvent = debounce((e) => {
    getEvent({ search: { eventName: e.target.value }, sortBy: {}, orderBy: {}, page: 100 });
  }, 500); // Adjust the delay as needed (e.g., 500ms)


  const handleChange = (selected) => {
    console.log(selected)
    setSelectedOption(selected);
  };

  const handlePaidEvent = (e) => {
    const value = e.target.value;
    if (value === 'paid') {
      setIsPaidEvent(true)
      setIsPriceDisable(false)
    } else if (value === 'donation') {
      setIsdonationEvent(true)
      setIsPriceDisable(true)
    } else {
      setIsPriceDisable(true)
      setIsdonationEvent(false)
      setIsPaidEvent(false)
    }
    setButtonType(value)
  };

  function validateDate(selectedDate) {
    let eventDate;
    if (eventData != null) {
      eventDate = new Date(eventData?.eventDate);
    } else {
      eventDate = new Date(selectedOption?.eventDate);
    }

    let userSelectedDate = new Date(selectedDate);

    if (userSelectedDate > eventDate) {
      return false;
    } else {
      return true;
    }
  }

  const handleStartDateChange = (e) => {
    console.log('startDate', e.target.value)
    console.log('eventDate', eventData?.eventDate)
    if (validateDate(e.target.value)) {
      setStartDate(e.target.value);
      setStartDateError("");

    } else {
      setStartDateError("Ticket sales start date must be before the event start date.");
      setStartDate(null)
    }
  }

  const extracTime = (timestamp) => {
    const date = new Date(timestamp);

    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours}:${minutes}`;
  }


  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    if (validateDate(e.target.value)) {
      setEndTime(e.target.value);
      setEndDateError("");
    } else {
      setEndDateError("Ticket sales start date must be before the event start date.");
      setEndTime(null);
    }
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  // Button Click Handlers
  const handleSave = () => {
    setIsContinue(false); // Set isOpen to false for Save
    formik.handleSubmit();
  };

  const handleSaveAndContinue = () => {
    setIsContinue(true); // Set isOpen to true for Save and Continue
    formik.handleSubmit();
  };

  useEffect(() => {
    if (ticketsData) {
      setIsPaidEvent(ticketsData?.isPaidEvent);
      setIsPriceDisable(!ticketsData?.isPaidEvent ? true : false);
      setIsdonationEvent(ticketsData?.isDonationEvent === "yes" ? true : false);
      setEndDate(ticketsData?.salesEndDate);
      setEndTime(ticketsData?.salesEndTime);
      setStartDate(ticketsData?.salesStartDate);
      setStartTime(ticketsData?.salesStartTime);
      const filterSelect = eventList.filter(e => e.value == ticketsData?.eventId)
      setSelectedOption(filterSelect[0]);
    } else if (eventData) {
      const filterSelect = eventList.filter(e => e.value == eventData?.id)
      setSelectedOption(filterSelect[0]);
    } else if (event) {
      const filterSelect = eventList.filter(e => e.value == event?.value)
      setSelectedOption(filterSelect[0]);
    }
  }, [ticketsData, eventList]);

  function validateForm(data) {
    let eventId = eventData ? eventData.id : selectedOption.value;
    let ticketName = data.ticketName.trim();
    let ticketQuantity = data.ticketQuantity;
    let ticketPrice = data.ticketPrice;
    let salesStartDate = data.salesStartDate;
    let salesStartTime = data.salesStartTime;
    let salesEndDate = data.salesEndDate;
    let salesEndTime = data.salesEndTime;
    let isdonationEventFlag = data.isDonationEvent;

    let errors = [];

    if (!eventId) errors.push("Please select an Event.");
    if (!ticketName) errors.push("Ticket Name is required.");
    if (!ticketQuantity) errors.push("Ticket Quantity is required.");
    if (buttonType != 'free' && isdonationEventFlag != "yes" && !ticketPrice) errors.push("Ticket Price is required.");
    if (!salesStartDate) errors.push("Sales Start Date is required.");
    if (!salesStartTime) errors.push("Sales Start Time is required.");
    if (!salesEndDate) errors.push("Sales End Time is required.");
    if (!salesEndTime) errors.push("Sales End Date is required.");

    if (errors.length > 0) {
      toast.info(errors[0], {
        position: "bottom-right",
        theme: "colored",
      });
      return false; // Prevent form submission
    }

    return true; // Proceed with form submission
  }


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: ticketsData?.id || null,
      isPaidEvent: ticketsData?.isPaidEvent || "",
      isDonationEvent: ticketsData?.isDonationEvent || "no",
      eventId: ticketsData?.eventId || "",
      ticketName: ticketsData?.ticketName || "",
      ticketQuantity: ticketsData?.ticketQuantity || "",
      ticketPrice: ticketsData?.ticketPrice || "",
      ticketSolds: ticketsData?.ticketPrice || "",
      salesStartDate: ticketsData?.salesStartDate || "",
      salesStartTime: ticketsData?.salesStartTime || "",
      salesEndDate: ticketsData?.salesEndDate || "",
      salesEndTime: ticketsData?.salesEndTime || "",
      ticketDescription: ticketsData?.ticketDescription || "",
      ticketMinimum: ticketsData?.ticketMinimum || "",
      ticketMaximun: ticketsData?.ticketMaximun || "",
      hideSaleDates: ticketsData?.hideSaleDates || "",
      salesChannel: ticketsData?.salesChannel || "",
      iseTicket: ticketsData?.iseTicket || "",
      deliveryMethods: ticketsData?.deliveryMethods || "",
      restaurantId: ticketsData?.restaurantId || "",
      ticketStatus: ticketsData?.ticketStatus || false,
    },

    onSubmit: async (values) => {
      trimObjectValues(values, "media");
      values.message = values.message ? values.message.replace(/\n/g, " ") : "";
      const filterData = removeEmptyKeysFromObject(values);
      filterData.id = values.id ? values.id : null;
      filterData.eventId = eventData ? eventData.id : selectedOption.value;
      filterData.isPaidEvent = isPaidEvent;
      filterData.isDonationEvent = isdonationEvent ? "yes" : "no";
      filterData.ticketName = values.ticketName;
      filterData.ticketQuantity = values.ticketQuantity;
      filterData.ticketPrice = values.ticketPrice || 0;
      filterData.ticketSolds = values.ticketSolds;
      filterData.salesStartDate = startDate;
      filterData.salesStartTime = extracTime(startTime);
      filterData.salesEndDate = extracTime(endDate);
      filterData.salesEndTime = endTime;
      filterData.ticketStatus = values.ticketStatus;
      filterData.ticketDescription = values.ticketDescription;
      filterData.ticketMinimum = values.ticketMinimum;
      filterData.ticketMaximun = values.ticketMaximun;
      filterData.hideSaleDates = values.hideSaleDates;
      filterData.salesChannel = values.salesChannel;
      filterData.iseTicket = values.iseTicket;
      filterData.deliveryMethods = values.deliveryMethods;
      const restaurant = JSON.parse(localStorage.getItem("userData"));
      const userRestaurantId = restaurant.restaurantId;
      filterData.restaurantId = userRestaurantId || 0;
      if (filterData.id && filterData.id != null && filterData.id != "") {
        if (validateForm(filterData)) {
          updateTicket(
            ticketsData.id,
            filterData,
            handleCloseModal
          );
        }
      } else {
        if (validateForm(removeEmptyKeysFromObject(filterData))) {
          addTicket(
            filterData,
            handleCloseModal
          );
        }
      }
    },
  });
  return (
    <Modal
      className="modal-dialog-centered "
      isOpen={open}
      toggle={handleCloseModal}
      size='md'
      scrollable={true}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Add Ticket
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body" >
        <Card className="border-0 edit-card">
          <CardBody>
            <FormGroup>
              <div className="d-flex">
                <Button color={buttonType === 'paid' ? "primary" : "secondary"} value="paid" onClick={handlePaidEvent} className="me-2">
                  Paid
                </Button>
                <Button color={buttonType === 'free' ? "primary" : "secondary"} value="free" onClick={handlePaidEvent} className="me-2">
                  Free
                </Button>
                <Button color={buttonType === 'donation' ? "primary" : "secondary"} value="donation" onClick={handlePaidEvent} >Donation</Button>
              </div>
            </FormGroup>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="ticketName">Select Event</Label>
                  {/* <Select
                    options={eventList}
                    value={selectedOption}
                    onChange={setSelectedOption}
                    placeholder="Select a fruit..."
                  /> */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select an Event..."
                    value={selectedOption}
                    onChange={handleChange}
                    // onKeyDown={(e) => { searchEvent(e) }}
                    isLoading={true}
                    isClearable={true}
                    isSearchable={true}

                    name="eventName"
                    options={eventList}
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <InputField placeholder="Ticket name" required fieldName="ticketName" inputType="text" formik={formik} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <InputField inputType="number" required fieldName="ticketQuantity" placeholder="Available Quantity" formik={formik} />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="price">Price (£)</Label>
                  <InputField inputType="number" disabled={isPriceDisable} required fieldName="ticketPrice" placeholder={isdonationEvent ? "Attendees can donate what they wish" : "0.00"} formik={formik} />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="dateTime">When are tickets available?</Label>
                  <Input type="select" className='event-select' id="dateTime">
                    <option>Date & time</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Sales Start</Label>
                  <div className="d-flex">
                    <Input type="datetime-local" required className="me-2" value={startDate} onChange={handleStartDateChange} placeholder="Select Sale Start Date" />
                    {/* <Input type="time" required className="me-2" value={startTime} onChange={handleStartTimeChange} placeholder="Select Sale Start Time" /> */}
                  </div>
                </FormGroup>
                {startDateError !== "" ? <span className='text-danger'>{startDateError}</span> : null}
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Sales End</Label>
                  <div className="d-flex">
                    <Input type="datetime-local" required className="me-2" value={endTime} onChange={handleEndTimeChange} placeholder="Select Sale End Date" />
                    {/* <Input type="time" required className="me-2" value={endDate} onChange={handleEndDateChange} placeholder="Select Sale End Time" /> */}
                  </div>
                </FormGroup>
                {endDateError !== "" ? <span className='text-danger'>{endDateError}</span> : null}
              </Col>
            </Row>


            <div className="text-muted">Event time zone is GMT</div>
            <FormGroup>
              <Button
                color="link"
                onClick={toggleAdvancedSettings}
                style={{ paddingLeft: "0" }}
              >
                Advanced settings
              </Button>
              <Collapse isOpen={isAdvancedOpen}>
                <div className="mt-2">
                  <FormGroup>
                    <Collapse isOpen={isAdvancedOpen}>
                      <div className="mt-3">
                        <Row>
                          <Col md={12}>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox" className='event-check-input' /> Show ticket sale end dates and
                                sale status at checkout
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="description">Description</Label>
                              <InputField
                                type="textarea"
                                name="description"
                                id="description"
                                placeholder="Tell attendees more about this ticket."
                                maxLength="2500"
                              />
                              <small className="text-muted">0/2500</small>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <FormGroup>
                              <Label for="visibility">Visibility</Label>
                              <Input type="select" className='event-select' id="visibility">
                                <option value="visible" data-spec="select-option">Visible</option>
                                <option value="hidden" data-spec="select-option">Hidden</option>
                                <option value="hiddenWhenNotAvailable" data-spec="select-option">Hidden when not on sale</option>
                                <option value="customSchedule" data-spec="select-option">Custom schedule</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup>
                              <Label>Minimum Tickets Quantity per order</Label>
                              <InputField
                                type="number"
                                name="minQuantity"
                                placeholder="Minimum quantity"
                                className="me-2"
                                value="1"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={12}>
                            <FormGroup>
                              <Label>Maximum Tickets Quantity per order</Label>
                              <InputField
                                type="number"
                                name="maxQuantity"
                                placeholder="Maximum quantity"
                                value='10'
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label for="salesChannel">Sales channel</Label>
                          <Input type="select" className='event-select' id="salesChannel">
                            <option>Everywhere</option>
                            <option>Online only</option>
                            <option>Offline only</option>
                          </Input>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" className='event-check-input' /> eTicket
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input type="checkbox" className='event-check-input' /> Will call
                          </Label>
                        </FormGroup>
                      </div>
                    </Collapse>
                  </FormGroup>
                </div>
              </Collapse>
            </FormGroup>
          </CardBody>
        </Card>
      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>

        {event !== undefined ?
          <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
            Submit
          </Button>
          :
          <>
            <Button className='d-none' color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
              Submit and Add Ticket
            </Button>
            <Button color='primary' type="button" disabled={!!Object.values(formik.errors)?.length} onClick={formik.handleSubmit}>
              Submit
            </Button>
          </>
        }
      </div>
    </Modal>
  )
}
