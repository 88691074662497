// Register Page
export const registrationInputFields = [
  { placeholderLeft: "Email", requiredLeft: true, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Password", requiredLeft: true, fieldNameLeft: "password", inputTypeLeft: "password", passwordIconLeft: true, requiredRight: true, placeholderRight: "Confirm password", fieldNameRight: "confirmPassword", inputTypeRight: "password", passwordIconRight: true },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Add Restaurant
export const addRestaurantInputFields = [
  { placeholderLeft: "Email", requiredLeft: true, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Password", requiredLeft: true, fieldNameLeft: "password", inputTypeLeft: "password", passwordIconLeft: true, placeholderRight: "Confirm password", requiredRight: true, fieldNameRight: "confirmPassword", inputTypeRight: "password", passwordIconRight: true },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Edit Restaurant
export const editRestaurantInputFields = [
  { placeholderLeft: "Email", requiredLeft: false, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Edit Profile
export const editProfileInputFields = [
  { placeholderLeft: "Email", requiredLeft: false, fieldNameLeft: "email", placeholderRight: "Phone", requiredRight: true, inputTypeRight: 'number', fieldNameRight: "phone" },
  { placeholderLeft: "Address line 1", requiredLeft: true, fieldNameLeft: "addressLine1", placeholderRight: "Address line 2", requiredRight: false, fieldNameRight: "addressLine2" },
]

// Settings
export const settingsInputFields = [
  { placeholderLeft: "App ID", fieldNameLeft: "appId", requiredLeft: true, placeholderRight: "App Name", fieldNameRight: "appName", requiredRight: true, },
  { placeholderLeft: "Access Token", fieldNameLeft: "accessToken", requiredLeft: true, placeholderRight: "Waba ID", fieldNameRight: "wabaId", requiredRight: true, },
  { placeholderLeft: "FB Catelog ID", fieldNameLeft: "fbCatalogId", requiredLeft: true, placeholderRight: "Phone ID", fieldNameRight: "phoneNumberId", requiredRight: true, },
  { placeholderLeft: "Callback URL", fieldNameLeft: "callbackUrl", requiredLeft: true, placeholderRight: "Verify Token", fieldNameRight: "verifyToken", requiredRight: true },
  { placeholderLeft: "Visit Count", requiredLeft: false, fieldNameLeft: "visitCount", placeholderRight: "Reservation Time Interval", requiredRight: false, fieldNameRight: "reservationTimeInterval" },
  { placeholderLeft: "Google Feedback URL", requiredLeft: false, fieldNameLeft: "googleFeedbackUrl" },
]

// Admin Settings
export const adminSettingsInputFields = [
  { placeholderLeft: "Currency", fieldNameLeft: "currency", placeholderRight: "Currency Sign", fieldNameRight: "currencySign" },
  { placeholderLeft: "VAT", fieldNameLeft: "vat" },
]

// Loyalty Program Fields
export const loyaltyProgramInputFields = [
  { placeholderLeft: "Price per coin", fieldNameLeft: "rewardCoinsPrice", placeholderRight: "Reward coins per order", fieldNameRight: "rewardCoinsPerOrder" },
  { placeholderLeft: "Minimum coins required to become eligible to redeem", fieldNameLeft: "minimumRewardedCoins", placeholderRight: "Minimum order value to earn coins", fieldNameRight: "minimumOrderPrice" },
  { placeholderLeft: "Maximum applicable coins per order", fieldNameLeft: "maxRedeemableCoins" },
]

export const eventTypeInputFields = [
  {
    "id": "1",
    "label": "Conference",
    "value": "Conference",
    "short_name": "Conference",
    "short_name_localized": "Conference"
  },
  {
    "id": "2",
    "label": "Seminar or Talk",
    "value": "Seminar or Talk",
    "short_name": "Seminar",
    "short_name_localized": "Seminar"
  },
  {
    "id": "3",
    "label": "Tradeshow, Consumer Show, or Expo",
    "value": "Tradeshow, Consumer Show, or Expo",
    "short_name": "Expo",
    "short_name_localized": "Expo"
  },
  {
    "id": "4",
    "label": "Convention",
    "value": "Convention",
    "short_name": "Convention",
    "short_name_localized": "Convention"
  },
  {
    "id": "5",
    "label": "Festival or Fair",
    "value": "Festival or Fair",
    "short_name": "Festival",
    "short_name_localized": "Festival"
  },
  {
    "id": "6",
    "label": "Concert or Performance",
    "value": "Concert or Performance",
    "short_name": "Performance",
    "short_name_localized": "Performance"
  },
  {
    "id": "7",
    "label": "Screening",
    "value": "Screening",
    "short_name": "Screening",
    "short_name_localized": "Screening"
  },
  {
    "id": "8",
    "label": "Dinner or Gala",
    "value": "Dinner or Gala",
    "short_name": "Gala",
    "short_name_localized": "Gala"
  },
  {
    "id": "9",
    "label": "Class, Training, or Workshop",
    "value": "Class, Training, or Workshop",
    "short_name": "Class",
    "short_name_localized": "Class"
  },
  {
    "id": "10",
    "label": "Meeting or Networking Event",
    "value": "Meeting or Networking Event",
    "short_name": "Networking",
    "short_name_localized": "Networking"
  },
  {
    "id": "11",
    "label": "Party or Social Gathering",
    "value": "Party or Social Gathering",
    "short_name": "Party",
    "short_name_localized": "Party"
  },
  {
    "id": "12",
    "label": "Rally",
    "value": "Rally",
    "short_name": "Rally",
    "short_name_localized": "Rally"
  },
  {
    "id": "13",
    "label": "Tournament",
    "value": "Tournament",
    "short_name": "Tournament",
    "short_name_localized": "Tournament"
  },
  {
    "id": "14",
    "label": "Game or Competition",
    "value": "Game or Competition",
    "short_name": "Game",
    "short_name_localized": "Game"
  },
  {
    "id": "15",
    "label": "Race or Endurance Event",
    "value": "Race or Endurance Event",
    "short_name": "Race",
    "short_name_localized": "Race"
  },
  {
    "id": "16",
    "label": "Tour",
    "value": "Tour",
    "short_name": "Tour",
    "short_name_localized": "Tour"
  },
  {
    "id": "17",
    "label": "Attraction",
    "value": "Attraction",
    "short_name": "Attraction",
    "short_name_localized": "Attraction"
  },
  {
    "id": "18",
    "label": "Camp, Trip, or Retreat",
    "value": "Camp, Trip, or Retreat",
    "short_name": "Retreat",
    "short_name_localized": "Retreat"
  },
  {
    "id": "19",
    "label": "Appearance or Signing",
    "value": "Appearance or Signing",
    "short_name": "Appearance",
    "short_name_localized": "Appearance"
  },
  {
    "id": "100",
    "label": "Other",
    "value": "Other",
    "short_name": "Other",
    "short_name_localized": "Other"
  }
]

export const eventCategoriesInputFields = [
  {
    "id": "103",
    "label": "Music",
    "value": "Music",
    "short_name": "Music",
    "short_name_localized": "Music"
  },
  {
    "id": "101",
    "label": "Business & Professional",
    "value": "Business & Professional",
    "short_name": "Business",
    "short_name_localized": "Business"
  },
  {
    "id": "110",
    "label": "Food & Drink",
    "value": "Food & Drink",
    "short_name": "Food & Drink",
    "short_name_localized": "Food & Drink"
  },
  {
    "id": "113",
    "label": "Community & Culture",
    "value": "Community & Culture",
    "short_name": "Community",
    "short_name_localized": "Community"
  },
  {
    "id": "105",
    "label": "Performing & Visual Arts",
    "value": "Performing & Visual Arts",
    "short_name": "Arts",
    "short_name_localized": "Arts"
  },
  {
    "id": "104",
    "label": "Film, Media & Entertainment",
    "value": "Film, Media & Entertainment",
    "short_name": "Film & Media",
    "short_name_localized": "Film & Media"
  },
  {
    "id": "108",
    "label": "Sports & Fitness",
    "value": "Sports & Fitness",
    "short_name": "Sports & Fitness",
    "short_name_localized": "Sports & Fitness"
  },
  {
    "id": "107",
    "label": "Health & Wellness",
    "value": "Health & Wellness",
    "short_name": "Health",
    "short_name_localized": "Health"
  },
  {
    "id": "102",
    "label": "Science & Technology",
    "value": "Science & Technology",
    "short_name": "Science & Tech",
    "short_name_localized": "Science & Tech"
  },
  {
    "id": "109",
    "label": "Travel & Outdoor",
    "value": "Travel & Outdoor",
    "short_name": "Travel & Outdoor",
    "short_name_localized": "Travel & Outdoor"
  },
  {
    "id": "111",
    "label": "Charity & Causes",
    "value": "Charity & Causes",
    "short_name": "Charity & Causes",
    "short_name_localized": "Charity & Causes"
  },
  {
    "id": "114",
    "label": "Religion & Spirituality",
    "value": "Religion & Spirituality",
    "short_name": "Spirituality",
    "short_name_localized": "Spirituality"
  },
  {
    "id": "115",
    "label": "Family & Education",
    "value": "Family & Education",
    "short_name": "Family & Education",
    "short_name_localized": "Family & Education"
  },
  {
    "id": "116",
    "label": "Seasonal & Holiday",
    "value": "Seasonal & Holiday",
    "short_name": "Holiday",
    "short_name_localized": "Holiday"
  },
  {
    "id": "112",
    "label": "Government & Politics",
    "value": "Government & Politics",
    "short_name": "Government",
    "short_name_localized": "Government"
  },
  {
    "id": "106",
    "label": "Fashion & Beauty",
    "value": "Fashion & Beauty",
    "short_name": "Fashion",
    "short_name_localized": "Fashion"
  },
  {
    "id": "117",
    "label": "Home & Lifestyle",
    "value": "Home & Lifestyle",
    "short_name": "Home & Lifestyle",
    "short_name_localized": "Home & Lifestyle"
  },
  {
    "id": "118",
    "label": "Auto, Boat & Air",
    "value": "Auto, Boat & Air",
    "short_name": "Auto, Boat & Air",
    "short_name_localized": "Auto, Boat & Air"
  },
  {
    "id": "119",
    "label": "Hobbies & Special Interest",
    "value": "Hobbies & Special Interest",
    "short_name": "Hobbies",
    "short_name_localized": "Hobbies"
  },
  {
    "id": "199",
    "label": "Other",
    "value": "Other",
    "short_name": "Other",
    "short_name_localized": "Other"
  },
  {
    "id": "120",
    "label": "School Activities",
    "value": "School Activities",
    "short_name": "School Activities",
    "short_name_localized": "School Activities"
  }
]

export const eventsubCategoriesInputFields = [
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1001/",
      "id": "1001",
      "name": "Startups & Small Business",
      "label": "Startups & Small Business",
      "value": "1001",
      "name_localized": "Startups & Small Business",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1002/",
      "id": "1002",
      "name": "Finance",
      "label": "Finance",
      "value": "1002",
      "name_localized": "Finance",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1003/",
      "id": "1003",
      "name": "Environment & Sustainability",
      "label": "Environment & Sustainability",
      "value": "1003",
      "name_localized": "Environment & Sustainability",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1004/",
      "id": "1004",
      "name": "Educators",
      "label": "Educators",
      "value": "1004",
      "name_localized": "Educators",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1005/",
      "id": "1005",
      "name": "Real Estate",
      "label": "Real Estate",
      "value": "1005",
      "name_localized": "Real Estate",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1006/",
      "id": "1006",
      "name": "Non Profit & NGOs",
      "label": "Non Profit & NGOs",
      "value": "1006",
      "name_localized": "Non Profit & NGOs",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1007/",
      "id": "1007",
      "name": "Sales & Marketing",
      "label": "Sales & Marketing",
      "value": "1007",
      "name_localized": "Sales & Marketing",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1008/",
      "id": "1008",
      "name": "Media",
      "label": "Media",
      "value": "1008",
      "name_localized": "Media",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1009/",
      "id": "1009",
      "name": "Design",
      "label": "Design",
      "value": "1009",
      "name_localized": "Design",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1010/",
      "id": "1010",
      "name": "Career",
      "label": "Career",
      "value": "1010",
      "name_localized": "Career",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1011/",
      "id": "1011",
      "name": "Investment",
      "label": "Investment",
      "value": "1011",
      "name_localized": "Investment",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/1999/",
      "id": "1999",
      "name": "Other",
      "label": "Other",
      "value": "1999",
      "name_localized": "Other",
      "parent_category": "101"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2001/",
      "id": "2001",
      "name": "Medicine",
      "label": "Medicine",
      "value": "2001",
      "name_localized": "Medicine",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2002/",
      "id": "2002",
      "name": "Science",
      "label": "Science",
      "value": "2002",
      "name_localized": "Science",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2003/",
      "id": "2003",
      "name": "Biotech",
      "label": "Biotech",
      "value": "2003",
      "name_localized": "Biotech",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2004/",
      "id": "2004",
      "name": "High Tech",
      "label": "High Tech",
      "value": "2004",
      "name_localized": "High Tech",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2005/",
      "id": "2005",
      "name": "Mobile",
      "label": "Mobile",
      "value": "2005",
      "name_localized": "Mobile",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2006/",
      "id": "2006",
      "name": "Social Media",
      "label": "Social Media",
      "value": "2006",
      "name_localized": "Social Media",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2007/",
      "id": "2007",
      "name": "Robotics",
      "label": "Robotics",
      "value": "2007",
      "name_localized": "Robotics",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/2999/",
      "id": "2999",
      "name": "Other",
      "label": "Other",
      "value": "2999",
      "name_localized": "Other",
      "parent_category": "102"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3001/",
      "id": "3001",
      "name": "Alternative",
      "label": "Alternative",
      "value": "3001",
      "name_localized": "Alternative",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3002/",
      "id": "3002",
      "name": "Blues & Jazz",
      "label": "Blues & Jazz",
      "value": "3002",
      "name_localized": "Blues & Jazz",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3003/",
      "id": "3003",
      "name": "Classical",
      "label": "Classical",
      "value": "3003",
      "name_localized": "Classical",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3004/",
      "id": "3004",
      "name": "Country",
      "label": "Country",
      "value": "3004",
      "name_localized": "Country",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3005/",
      "id": "3005",
      "name": "Cultural",
      "label": "Cultural",
      "value": "3005",
      "name_localized": "Cultural",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3006/",
      "id": "3006",
      "name": "EDM / Electronic",
      "label": "EDM / Electronic",
      "value": "3006",
      "name_localized": "EDM / Electronic",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3007/",
      "id": "3007",
      "name": "Folk",
      "label": "Folk",
      "value": "3007",
      "name_localized": "Folk",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3008/",
      "id": "3008",
      "name": "Hip Hop / Rap",
      "label": "Hip Hop / Rap",
      "value": "3008",
      "name_localized": "Hip Hop / Rap",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3009/",
      "id": "3009",
      "name": "Indie",
      "label": "Indie",
      "value": "3009",
      "name_localized": "Indie",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3010/",
      "id": "3010",
      "name": "Latin",
      "label": "Latin",
      "value": "3010",
      "name_localized": "Latin",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3011/",
      "id": "3011",
      "name": "Metal",
      "label": "Metal",
      "value": "3011",
      "name_localized": "Metal",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3012/",
      "id": "3012",
      "name": "Opera",
      "label": "Opera",
      "value": "3012",
      "name_localized": "Opera",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3013/",
      "id": "3013",
      "name": "Pop",
      "label": "Pop",
      "value": "3013",
      "name_localized": "Pop",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3014/",
      "id": "3014",
      "name": "R&B",
      "label": "R&B",
      "value": "3014",
      "name_localized": "R&B",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3015/",
      "id": "3015",
      "name": "Reggae",
      "label": "Reggae",
      "value": "3015",
      "name_localized": "Reggae",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3016/",
      "id": "3016",
      "name": "Religious/Spiritual",
      "label": "Religious/Spiritual",
      "value": "3016",
      "name_localized": "Religious/Spiritual",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3017/",
      "id": "3017",
      "name": "Rock",
      "label": "Rock",
      "value": "3017",
      "name_localized": "Rock",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3018/",
      "id": "3018",
      "name": "Top 40",
      "label": "Top 40",
      "value": "3018",
      "name_localized": "Top 40",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3019/",
      "id": "3019",
      "name": "Acoustic",
      "label": "Acoustic",
      "value": "3019",
      "name_localized": "Acoustic",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3020/",
      "id": "3020",
      "name": "Americana",
      "label": "Americana",
      "value": "3020",
      "name_localized": "Americana",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3021/",
      "id": "3021",
      "name": "Bluegrass",
      "label": "Bluegrass",
      "value": "3021",
      "name_localized": "Bluegrass",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3022/",
      "id": "3022",
      "name": "Blues",
      "label": "Blues",
      "value": "3022",
      "name_localized": "Blues",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3023/",
      "id": "3023",
      "name": "DJ/Dance",
      "label": "DJ/Dance",
      "value": "3023",
      "name_localized": "DJ/Dance",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3024/",
      "id": "3024",
      "name": "EDM",
      "label": "EDM",
      "value": "3024",
      "name_localized": "EDM",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3025/",
      "id": "3025",
      "name": "Electronic",
      "label": "Electronic",
      "value": "3025",
      "name_localized": "Electronic",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3026/",
      "id": "3026",
      "name": "Experimental",
      "label": "Experimental",
      "value": "3026",
      "name_localized": "Experimental",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3027/",
      "id": "3027",
      "name": "Jazz",
      "label": "Jazz",
      "value": "3027",
      "name_localized": "Jazz",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3028/",
      "id": "3028",
      "name": "Psychedelic",
      "label": "Psychedelic",
      "value": "3028",
      "name_localized": "Psychedelic",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3029/",
      "id": "3029",
      "name": "Punk/Hardcore",
      "label": "Punk/Hardcore",
      "value": "3029",
      "name_localized": "Punk/Hardcore",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3030/",
      "id": "3030",
      "name": "Singer/Songwriter",
      "label": "Singer/Songwriter",
      "value": "3030",
      "name_localized": "Singer/Songwriter",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3031/",
      "id": "3031",
      "name": "World",
      "label": "World",
      "value": "3031",
      "name_localized": "World",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/3999/",
      "id": "3999",
      "name": "Other",
      "label": "Other",
      "value": "3999",
      "name_localized": "Other",
      "parent_category": "103"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4001/",
      "id": "4001",
      "name": "TV",
      "label": "TV",
      "value": "4001",
      "name_localized": "TV",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4002/",
      "id": "4002",
      "name": "Film",
      "label": "Film",
      "value": "4002",
      "name_localized": "Film",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4003/",
      "id": "4003",
      "name": "Anime",
      "label": "Anime",
      "value": "4003",
      "name_localized": "Anime",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4004/",
      "id": "4004",
      "name": "Gaming",
      "label": "Gaming",
      "value": "4004",
      "name_localized": "Gaming",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4005/",
      "id": "4005",
      "name": "Comics",
      "label": "Comics",
      "value": "4005",
      "name_localized": "Comics",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4006/",
      "id": "4006",
      "name": "Adult",
      "label": "Adult",
      "value": "4006",
      "name_localized": "Adult",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4007/",
      "id": "4007",
      "name": "Comedy",
      "label": "Comedy",
      "value": "4007",
      "name_localized": "Comedy",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/4999/",
      "id": "4999",
      "name": "Other",
      "label": "Other",
      "value": "4999",
      "name_localized": "Other",
      "parent_category": "104"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5001/",
      "id": "5001",
      "name": "Theatre",
      "label": "Theatre",
      "value": "5001",
      "name_localized": "Theatre",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5002/",
      "id": "5002",
      "name": "Musical",
      "label": "Musical",
      "value": "5002",
      "name_localized": "Musical",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5003/",
      "id": "5003",
      "name": "Ballet",
      "label": "Ballet",
      "value": "5003",
      "name_localized": "Ballet",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5004/",
      "id": "5004",
      "name": "Dance",
      "label": "Dance",
      "value": "5004",
      "name_localized": "Dance",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5005/",
      "id": "5005",
      "name": "Opera",
      "label": "Opera",
      "value": "5005",
      "name_localized": "Opera",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5006/",
      "id": "5006",
      "name": "Orchestra",
      "label": "Orchestra",
      "value": "5006",
      "name_localized": "Orchestra",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5007/",
      "id": "5007",
      "name": "Craft",
      "label": "Craft",
      "value": "5007",
      "name_localized": "Craft",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5008/",
      "id": "5008",
      "name": "Fine Art",
      "label": "Fine Art",
      "value": "5008",
      "name_localized": "Fine Art",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5009/",
      "id": "5009",
      "name": "Literary Arts",
      "label": "Literary Arts",
      "value": "5009",
      "name_localized": "Literary Arts",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5010/",
      "id": "5010",
      "name": "Comedy",
      "label": "Comedy",
      "value": "5010",
      "name_localized": "Comedy",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5011/",
      "id": "5011",
      "name": "Sculpture",
      "label": "Sculpture",
      "value": "5011",
      "name_localized": "Sculpture",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5012/",
      "id": "5012",
      "name": "Painting",
      "label": "Painting",
      "value": "5012",
      "name_localized": "Painting",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5013/",
      "id": "5013",
      "name": "Design",
      "label": "Design",
      "value": "5013",
      "name_localized": "Design",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5014/",
      "id": "5014",
      "name": "Jewelry",
      "label": "Jewelry",
      "value": "5014",
      "name_localized": "Jewelry",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/5999/",
      "id": "5999",
      "name": "Other",
      "label": "Other",
      "value": "5999",
      "name_localized": "Other",
      "parent_category": "105"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/6001/",
      "id": "6001",
      "name": "Fashion",
      "label": "Fashion",
      "value": "6001",
      "name_localized": "Fashion",
      "parent_category": "106"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/6002/",
      "id": "6002",
      "name": "Accessories",
      "label": "Accessories",
      "value": "6002",
      "name_localized": "Accessories",
      "parent_category": "106"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/6003/",
      "id": "6003",
      "name": "Bridal",
      "label": "Bridal",
      "value": "6003",
      "name_localized": "Bridal",
      "parent_category": "106"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/6004/",
      "id": "6004",
      "name": "Beauty",
      "label": "Beauty",
      "value": "6004",
      "name_localized": "Beauty",
      "parent_category": "106"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/6999/",
      "id": "6999",
      "name": "Other",
      "label": "Other",
      "value": "6999",
      "name_localized": "Other",
      "parent_category": "106"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/7001/",
      "id": "7001",
      "name": "Personal health",
      "label": "Personal health",
      "value": "7001",
      "name_localized": "Personal health",
      "parent_category": "107"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/7002/",
      "id": "7002",
      "name": "Mental health",
      "label": "Mental health",
      "value": "7002",
      "name_localized": "Mental health",
      "parent_category": "107"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/7003/",
      "id": "7003",
      "name": "Medical",
      "label": "Medical",
      "value": "7003",
      "name_localized": "Medical",
      "parent_category": "107"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/7004/",
      "id": "7004",
      "name": "Spa",
      "label": "Spa",
      "value": "7004",
      "name_localized": "Spa",
      "parent_category": "107"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/7005/",
      "id": "7005",
      "name": "Yoga",
      "label": "Yoga",
      "value": "7005",
      "name_localized": "Yoga",
      "parent_category": "107"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/7999/",
      "id": "7999",
      "name": "Other",
      "label": "Other",
      "value": "7999",
      "name_localized": "Other",
      "parent_category": "107"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8001/",
      "id": "8001",
      "name": "Running",
      "label": "Running",
      "value": "8001",
      "name_localized": "Running",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8002/",
      "id": "8002",
      "name": "Walking",
      "label": "Walking",
      "value": "8002",
      "name_localized": "Walking",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8003/",
      "id": "8003",
      "name": "Cycling",
      "label": "Cycling",
      "value": "8003",
      "name_localized": "Cycling",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8004/",
      "id": "8004",
      "name": "Mountain Biking",
      "label": "Mountain Biking",
      "value": "8004",
      "name_localized": "Mountain Biking",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8005/",
      "id": "8005",
      "name": "Obstacles",
      "label": "Obstacles",
      "value": "8005",
      "name_localized": "Obstacles",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8006/",
      "id": "8006",
      "name": "Basketball",
      "label": "Basketball",
      "value": "8006",
      "name_localized": "Basketball",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8007/",
      "id": "8007",
      "name": "Football",
      "label": "Football",
      "value": "8007",
      "name_localized": "Football",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8008/",
      "id": "8008",
      "name": "Baseball",
      "label": "Baseball",
      "value": "8008",
      "name_localized": "Baseball",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8009/",
      "id": "8009",
      "name": "Soccer",
      "label": "Soccer",
      "value": "8009",
      "name_localized": "Soccer",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8010/",
      "id": "8010",
      "name": "Golf",
      "label": "Golf",
      "value": "8010",
      "name_localized": "Golf",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8011/",
      "id": "8011",
      "name": "Volleyball",
      "label": "Volleyball",
      "value": "8011",
      "name_localized": "Volleyball",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8012/",
      "id": "8012",
      "name": "Tennis",
      "label": "Tennis",
      "value": "8012",
      "name_localized": "Tennis",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8013/",
      "id": "8013",
      "name": "Swimming & Water Sports",
      "label": "Swimming & Water Sports",
      "value": "8013",
      "name_localized": "Swimming & Water Sports",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8014/",
      "id": "8014",
      "name": "Hockey",
      "label": "Hockey",
      "value": "8014",
      "name_localized": "Hockey",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8015/",
      "id": "8015",
      "name": "Motorsports",
      "label": "Motorsports",
      "value": "8015",
      "name_localized": "Motorsports",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8016/",
      "id": "8016",
      "name": "Fighting & Martial Arts",
      "label": "Fighting & Martial Arts",
      "value": "8016",
      "name_localized": "Fighting & Martial Arts",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8017/",
      "id": "8017",
      "name": "Snow Sports",
      "label": "Snow Sports",
      "value": "8017",
      "name_localized": "Snow Sports",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8018/",
      "id": "8018",
      "name": "Rugby",
      "label": "Rugby",
      "value": "8018",
      "name_localized": "Rugby",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8019/",
      "id": "8019",
      "name": "Yoga",
      "label": "Yoga",
      "value": "8019",
      "name_localized": "Yoga",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8020/",
      "id": "8020",
      "name": "Exercise",
      "label": "Exercise",
      "value": "8020",
      "name_localized": "Exercise",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8021/",
      "id": "8021",
      "name": "Softball",
      "label": "Softball",
      "value": "8021",
      "name_localized": "Softball",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8022/",
      "id": "8022",
      "name": "Wrestling",
      "label": "Wrestling",
      "value": "8022",
      "name_localized": "Wrestling",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8023/",
      "id": "8023",
      "name": "Lacrosse",
      "label": "Lacrosse",
      "value": "8023",
      "name_localized": "Lacrosse",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8024/",
      "id": "8024",
      "name": "Cheer",
      "label": "Cheer",
      "value": "8024",
      "name_localized": "Cheer",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8025/",
      "id": "8025",
      "name": "Camps",
      "label": "Camps",
      "value": "8025",
      "name_localized": "Camps",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8026/",
      "id": "8026",
      "name": "Weightlifting",
      "label": "Weightlifting",
      "value": "8026",
      "name_localized": "Weightlifting",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8027/",
      "id": "8027",
      "name": "Track & Field",
      "label": "Track & Field",
      "value": "8027",
      "name_localized": "Track & Field",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/8999/",
      "id": "8999",
      "name": "Other",
      "label": "Other",
      "value": "8999",
      "name_localized": "Other",
      "parent_category": "108"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9001/",
      "id": "9001",
      "name": "Hiking",
      "label": "Hiking",
      "value": "9001",
      "name_localized": "Hiking",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9002/",
      "id": "9002",
      "name": "Rafting",
      "label": "Rafting",
      "value": "9002",
      "name_localized": "Rafting",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9003/",
      "id": "9003",
      "name": "Kayaking",
      "label": "Kayaking",
      "value": "9003",
      "name_localized": "Kayaking",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9004/",
      "id": "9004",
      "name": "Canoeing",
      "label": "Canoeing",
      "value": "9004",
      "name_localized": "Canoeing",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9005/",
      "id": "9005",
      "name": "Climbing",
      "label": "Climbing",
      "value": "9005",
      "name_localized": "Climbing",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9006/",
      "id": "9006",
      "name": "Travel",
      "label": "Travel",
      "value": "9006",
      "name_localized": "Travel",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/9999/",
      "id": "9999",
      "name": "Other",
      "label": "Other",
      "value": "9999",
      "name_localized": "Other",
      "parent_category": "109"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/10001/",
      "id": "10001",
      "name": "Beer",
      "label": "Beer",
      "value": "10001",
      "name_localized": "Beer",
      "parent_category": "110"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/10002/",
      "id": "10002",
      "name": "Wine",
      "label": "Wine",
      "value": "10002",
      "name_localized": "Wine",
      "parent_category": "110"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/10003/",
      "id": "10003",
      "name": "Food",
      "label": "Food",
      "value": "10003",
      "name_localized": "Food",
      "parent_category": "110"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/10004/",
      "id": "10004",
      "name": "Spirits",
      "label": "Spirits",
      "value": "10004",
      "name_localized": "Spirits",
      "parent_category": "110"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/10999/",
      "id": "10999",
      "name": "Other",
      "label": "Other",
      "value": "10999",
      "name_localized": "Other",
      "parent_category": "110"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11001/",
      "id": "11001",
      "name": "Animal Welfare",
      "label": "Animal Welfare",
      "value": "11001",
      "name_localized": "Animal Welfare",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11002/",
      "id": "11002",
      "name": "Environment",
      "label": "Environment",
      "value": "11002",
      "name_localized": "Environment",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11003/",
      "id": "11003",
      "name": "Healthcare",
      "label": "Healthcare",
      "value": "11003",
      "name_localized": "Healthcare",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11004/",
      "id": "11004",
      "name": "Human Rights",
      "label": "Human Rights",
      "value": "11004",
      "name_localized": "Human Rights",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11005/",
      "id": "11005",
      "name": "International Aid",
      "label": "International Aid",
      "value": "11005",
      "name_localized": "International Aid",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11006/",
      "id": "11006",
      "name": "Poverty",
      "label": "Poverty",
      "value": "11006",
      "name_localized": "Poverty",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11007/",
      "id": "11007",
      "name": "Disaster Relief",
      "label": "Disaster Relief",
      "value": "11007",
      "name_localized": "Disaster Relief",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11008/",
      "id": "11008",
      "name": "Education",
      "label": "Education",
      "value": "11008",
      "name_localized": "Education",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/11999/",
      "id": "11999",
      "name": "Other",
      "label": "Other",
      "value": "11999",
      "name_localized": "Other",
      "parent_category": "111"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12001/",
      "id": "12001",
      "name": "Republican Party",
      "label": "Republican Party",
      "value": "12001",
      "name_localized": "Republican Party",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12002/",
      "id": "12002",
      "name": "Democratic Party",
      "label": "Democratic Party",
      "value": "12002",
      "name_localized": "Democratic Party",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12003/",
      "id": "12003",
      "name": "Other Party",
      "label": "Other Party",
      "value": "12003",
      "name_localized": "Other Party",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12004/",
      "id": "12004",
      "name": "Non-partisan",
      "label": "Non-partisan",
      "value": "12004",
      "name_localized": "Non-partisan",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12005/",
      "id": "12005",
      "name": "Federal Government",
      "label": "Federal Government",
      "value": "12005",
      "name_localized": "Federal Government",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12006/",
      "id": "12006",
      "name": "State Government",
      "label": "State Government",
      "value": "12006",
      "name_localized": "State Government",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12007/",
      "id": "12007",
      "name": "County/Municipal Government ",
      "label": "County/Municipal Government ",
      "value": "12007",
      "name_localized": "County/Municipal Government ",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12008/",
      "id": "12008",
      "name": "Military",
      "label": "Military",
      "value": "12008",
      "name_localized": "Military",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12009/",
      "id": "12009",
      "name": "International Affairs",
      "label": "International Affairs",
      "value": "12009",
      "name_localized": "International Affairs",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12010/",
      "id": "12010",
      "name": "National Security",
      "label": "National Security",
      "value": "12010",
      "name_localized": "National Security",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/12999/",
      "id": "12999",
      "name": "Other",
      "label": "Other",
      "value": "12999",
      "name_localized": "Other",
      "parent_category": "112"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13001/",
      "id": "13001",
      "name": "State",
      "label": "State",
      "value": "13001",
      "name_localized": "State",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13002/",
      "id": "13002",
      "name": "County",
      "label": "County",
      "value": "13002",
      "name_localized": "County",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13003/",
      "id": "13003",
      "name": "City/Town",
      "label": "City/Town",
      "value": "13003",
      "name_localized": "City/Town",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13004/",
      "id": "13004",
      "name": "LGBT",
      "label": "LGBT",
      "value": "13004",
      "name_localized": "LGBT",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13005/",
      "id": "13005",
      "name": "Medieval",
      "label": "Medieval",
      "value": "13005",
      "name_localized": "Medieval",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13006/",
      "id": "13006",
      "name": "Renaissance",
      "label": "Renaissance",
      "value": "13006",
      "name_localized": "Renaissance",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13007/",
      "id": "13007",
      "name": "Heritage",
      "label": "Heritage",
      "value": "13007",
      "name_localized": "Heritage",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13008/",
      "id": "13008",
      "name": "Nationality",
      "label": "Nationality",
      "value": "13008",
      "name_localized": "Nationality",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13009/",
      "id": "13009",
      "name": "Language",
      "label": "Language",
      "value": "13009",
      "name_localized": "Language",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13010/",
      "id": "13010",
      "name": "Historic",
      "label": "Historic",
      "value": "13010",
      "name_localized": "Historic",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/13999/",
      "id": "13999",
      "name": "Other",
      "label": "Other",
      "value": "13999",
      "name_localized": "Other",
      "parent_category": "113"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14001/",
      "id": "14001",
      "name": "Christianity",
      "label": "Christianity",
      "value": "14001",
      "name_localized": "Christianity",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14002/",
      "id": "14002",
      "name": "Judaism",
      "label": "Judaism",
      "value": "14002",
      "name_localized": "Judaism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14003/",
      "id": "14003",
      "name": "Islam",
      "label": "Islam",
      "value": "14003",
      "name_localized": "Islam",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14004/",
      "id": "14004",
      "name": "Mormonism",
      "label": "Mormonism",
      "value": "14004",
      "name_localized": "Mormonism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14005/",
      "id": "14005",
      "name": "Buddhism",
      "label": "Buddhism",
      "value": "14005",
      "name_localized": "Buddhism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14006/",
      "id": "14006",
      "name": "Sikhism",
      "label": "Sikhism",
      "value": "14006",
      "name_localized": "Sikhism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14007/",
      "id": "14007",
      "name": "Eastern Religion",
      "label": "Eastern Religion",
      "value": "14007",
      "name_localized": "Eastern Religion",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14008/",
      "id": "14008",
      "name": "Mysticism and Occult",
      "label": "Mysticism and Occult",
      "value": "14008",
      "name_localized": "Mysticism and Occult",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14009/",
      "id": "14009",
      "name": "New Age",
      "label": "New Age",
      "value": "14009",
      "name_localized": "New Age",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14010/",
      "id": "14010",
      "name": "Atheism",
      "label": "Atheism",
      "value": "14010",
      "name_localized": "Atheism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14011/",
      "id": "14011",
      "name": "Agnosticism",
      "label": "Agnosticism",
      "value": "14011",
      "name_localized": "Agnosticism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14012/",
      "id": "14012",
      "name": "Unaffiliated",
      "label": "Unaffiliated",
      "value": "14012",
      "name_localized": "Unaffiliated",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14013/",
      "id": "14013",
      "name": "Hinduism",
      "label": "Hinduism",
      "value": "14013",
      "name_localized": "Hinduism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14014/",
      "id": "14014",
      "name": "Folk Religions",
      "label": "Folk Religions",
      "value": "14014",
      "name_localized": "Folk Religions",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14015/",
      "id": "14015",
      "name": "Shintoism",
      "label": "Shintoism",
      "value": "14015",
      "name_localized": "Shintoism",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/14099/",
      "id": "14099",
      "name": "Other",
      "label": "Other",
      "value": "14099",
      "name_localized": "Other",
      "parent_category": "114"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15001/",
      "id": "15001",
      "name": "Education",
      "label": "Education",
      "value": "15001",
      "name_localized": "Education",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15002/",
      "id": "15002",
      "name": "Alumni",
      "label": "Alumni",
      "value": "15002",
      "name_localized": "Alumni",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15003/",
      "id": "15003",
      "name": "Parenting",
      "label": "Parenting",
      "value": "15003",
      "name_localized": "Parenting",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15004/",
      "id": "15004",
      "name": "Baby",
      "label": "Baby",
      "value": "15004",
      "name_localized": "Baby",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15005/",
      "id": "15005",
      "name": "Children & Youth ",
      "label": "Children & Youth ",
      "value": "15005",
      "name_localized": "Children & Youth ",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15006/",
      "id": "15006",
      "name": "Parents Association",
      "label": "Parents Association",
      "value": "15006",
      "name_localized": "Parents Association",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15007/",
      "id": "15007",
      "name": "Reunion",
      "label": "Reunion",
      "value": "15007",
      "name_localized": "Reunion",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15008/",
      "id": "15008",
      "name": "Senior Citizen",
      "label": "Senior Citizen",
      "value": "15008",
      "name_localized": "Senior Citizen",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/15999/",
      "id": "15999",
      "name": "Other",
      "label": "Other",
      "value": "15999",
      "name_localized": "Other",
      "parent_category": "115"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16001/",
      "id": "16001",
      "name": "St Patricks Day",
      "label": "St Patricks Day",
      "value": "16001",
      "name_localized": "St Patricks Day",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16002/",
      "id": "16002",
      "name": "Easter",
      "label": "Easter",
      "value": "16002",
      "name_localized": "Easter",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16003/",
      "id": "16003",
      "name": "Independence Day",
      "label": "Independence Day",
      "value": "16003",
      "name_localized": "Independence Day",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16004/",
      "id": "16004",
      "name": "Halloween/Haunt",
      "label": "Halloween/Haunt",
      "value": "16004",
      "name_localized": "Halloween/Haunt",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16005/",
      "id": "16005",
      "name": "Thanksgiving",
      "label": "Thanksgiving",
      "value": "16005",
      "name_localized": "Thanksgiving",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16006/",
      "id": "16006",
      "name": "Christmas",
      "label": "Christmas",
      "value": "16006",
      "name_localized": "Christmas",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16007/",
      "id": "16007",
      "name": "Channukah",
      "label": "Channukah",
      "value": "16007",
      "name_localized": "Channukah",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16008/",
      "id": "16008",
      "name": "Fall events",
      "label": "Fall events",
      "value": "16008",
      "name_localized": "Fall events",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16009/",
      "id": "16009",
      "name": "New Years Eve",
      "label": "New Years Eve",
      "value": "16009",
      "name_localized": "New Years Eve",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/16999/",
      "id": "16999",
      "name": "Other",
      "label": "Other",
      "value": "16999",
      "name_localized": "Other",
      "parent_category": "116"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/17001/",
      "id": "17001",
      "name": "Dating",
      "label": "Dating",
      "value": "17001",
      "name_localized": "Dating",
      "parent_category": "117"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/17002/",
      "id": "17002",
      "name": "Pets & Animals",
      "label": "Pets & Animals",
      "value": "17002",
      "name_localized": "Pets & Animals",
      "parent_category": "117"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/17003/",
      "id": "17003",
      "name": "Home & Garden",
      "label": "Home & Garden",
      "value": "17003",
      "name_localized": "Home & Garden",
      "parent_category": "117"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/17999/",
      "id": "17999",
      "name": "Other",
      "label": "Other",
      "value": "17999",
      "name_localized": "Other",
      "parent_category": "117"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/18001/",
      "id": "18001",
      "name": "Auto",
      "label": "Auto",
      "value": "18001",
      "name_localized": "Auto",
      "parent_category": "118"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/18002/",
      "id": "18002",
      "name": "Motorcycle/ATV",
      "label": "Motorcycle/ATV",
      "value": "18002",
      "name_localized": "Motorcycle/ATV",
      "parent_category": "118"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/18003/",
      "id": "18003",
      "name": "Boat",
      "label": "Boat",
      "value": "18003",
      "name_localized": "Boat",
      "parent_category": "118"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/18004/",
      "id": "18004",
      "name": "Air",
      "label": "Air",
      "value": "18004",
      "name_localized": "Air",
      "parent_category": "118"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/18999/",
      "id": "18999",
      "name": "Other",
      "label": "Other",
      "value": "18999",
      "name_localized": "Other",
      "parent_category": "118"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19001/",
      "id": "19001",
      "name": "Anime/Comics",
      "label": "Anime/Comics",
      "value": "19001",
      "name_localized": "Anime/Comics",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19002/",
      "id": "19002",
      "name": "Gaming",
      "label": "Gaming",
      "value": "19002",
      "name_localized": "Gaming",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19003/",
      "id": "19003",
      "name": "DIY",
      "label": "DIY",
      "value": "19003",
      "name_localized": "DIY",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19004/",
      "id": "19004",
      "name": "Photography",
      "label": "Photography",
      "value": "19004",
      "name_localized": "Photography",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19005/",
      "id": "19005",
      "name": "Knitting",
      "label": "Knitting",
      "value": "19005",
      "name_localized": "Knitting",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19006/",
      "id": "19006",
      "name": "Books",
      "label": "Books",
      "value": "19006",
      "name_localized": "Books",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19007/",
      "id": "19007",
      "name": "Adult",
      "label": "Adult",
      "value": "19007",
      "name_localized": "Adult",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19008/",
      "id": "19008",
      "name": "Drawing & Painting",
      "label": "Drawing & Painting",
      "value": "19008",
      "name_localized": "Drawing & Painting",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/19999/",
      "id": "19999",
      "name": "Other",
      "label": "Other",
      "value": "19999",
      "name_localized": "Other",
      "parent_category": "119"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/20001/",
      "id": "20001",
      "name": "Dinner",
      "label": "Dinner",
      "value": "20001",
      "name_localized": "Dinner",
      "parent_category": "120"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/20002/",
      "id": "20002",
      "name": "Fund Raiser",
      "label": "Fund Raiser",
      "value": "20002",
      "name_localized": "Fund Raiser",
      "parent_category": "120"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/20003/",
      "id": "20003",
      "name": "Raffle",
      "label": "Raffle",
      "value": "20003",
      "name_localized": "Raffle",
      "parent_category": "120"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/20004/",
      "id": "20004",
      "name": "After School Care",
      "label": "After School Care",
      "value": "20004",
      "name_localized": "After School Care",
      "parent_category": "120"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/20005/",
      "id": "20005",
      "name": "Parking",
      "label": "Parking",
      "value": "20005",
      "name_localized": "Parking",
      "parent_category": "120"
  },
  {
      "resource_uri": "https://www.eventbriteapi.com/v3/subcategories/20006/",
      "id": "20006",
      "name": "Public Speaker",
      "label": "Public Speaker",
      "value": "20006",
      "name_localized": "Public Speaker",
      "parent_category": "120"
  }
]

// Role
export const USER_ROLE = {
  SUPER_ADMIN: "superAdmin",
  RESTAURANT_OWNER: "admin",
}

// Message-Types
export const MessageType = {
  Info: 'INFO',
  Error: 'ERROR',
  Warn: 'WARN',
  Success: 'SUCCESS',
};

export const StatusCode = {
  BadRequest: 400,
  UnAuthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  AlreadyExists: 409,
  InternalServerError: 500,
};

// Role
export const API_REQUEST = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
}

// File type
export const FILE_TYPE = ['jpg', 'jpeg', 'png']

// Campaign File type
export const CAMPAIGN_FILE_TYPE = ['jpg', 'jpeg', 'png', 'pdf', 'mp4', 'mov', 'avi']

export const EVENT_FILE_TYPE = ['jpg', 'jpeg', 'png', 'mp4', 'mov', 'avi']

// Customers Import File Type
export const EXCEL_FILE_TYPE = ['csv']

// Brand name
export const BrandName = "WePOS.ai"

export const SampleCSV = 'https://wa-campaign.s3.eu-west-2.amazonaws.com/pdf/customers-sample-file.csv'

// Image type
export const ImageType = {
  LOGO: 'Restaurant Logo',
  PRODUCT_IMAGE: 'Product Image',
  CAMPAIGN_IMAGE: 'Campaign Image',
  EVENT_IMAGE: 'Event Image',
  EVENT_VIDEO: 'Event Video',
  CAMPAIGN_PDF: 'Campaign PDF',
  CAMPAIGN_VIDEO: 'Campaign Video',
  RESTAURANT_MENU_PDF: 'Restaurant Menu PDF',
}

// Brand name
export const ProductFechingTimeout = 3000

// Image Links
export const ImageLinks = ['https', 'http']

// Restaurant service enum
export const serviceThrough = {
  takeaway: 'Takeaway',
  'dine-in': 'Dine-In',
  'no-contact': 'No-Contact',
  delivery: 'Delivery',
  'Kerbside pickup': 'Kerbside Pickup',
}

// Subscription type
export const subscriptionType = {
  monthly: 'MONTHLY',
  yearly: 'YEARLY'
}

export const multiSelectDropdownStyle = {
  control: (styles) => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    minHeight: '43px',
    borderStyle: 'none',
    borderWidth: '0',
    borderColor: '#FFF !important',
    boxShadow: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ED3C5D' : 'transparent',
    '&:hover': {
      backgroundColor: state.isSelected ? '#ED3C5D' : '#F1F5F9',
    }
  }),
}

export const selectDropdownStyle = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: "190px",
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    minHeight: '40px',
    borderStyle: 'none',
    borderWidth: '0',
    borderColor: '#FFF !important',
    boxShadow: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ED3C5D' : '#ffffff',
    '&:hover': {
      backgroundColor: state.isSelected ? '#ED3C5D' : '#F1F5F9',
    }
  }),
}

// Country
export const Country = [
  { label: 'India', value: 'IN' },
  { label: 'UK', value: 'UK' }
]

// Country Code
export const CountryCode = {
  IN: '91',
  UK: '44',
}

// Country
export const CountryCodeDropdownOptions = [
  { label: '91', value: '91' },
  { label: '44', value: '44' }
]

// File size in byte
export const MinimumFileSize = 102400;
export const MaximumFileSize = 5242880;

export const RoutesActions = {
  customers: 'RestaurantAdminCustomers',
  tags: 'RestaurantAdminTags',
  tables: 'RestaurantAdminTables',
  categories: 'RestaurantAdminCategories',
  products: 'RestaurantAdminProducts',
  offers: 'RestaurantAdminOffers',
  coupons: 'RestaurantAdminCoupons',
  marketings: 'RestaurantAdminMarketings',
  orders: 'RestaurantAdminOrders',
  reports: 'RestaurantAdminReports',
  reservations: 'RestaurantAdminReservations',
  events: 'RestaurantAdminEvents',
  transactions: 'RestaurantAdminTransactions',
  feedbacks: 'RestaurantAdminFeedbacks',
  chats: 'RestaurantAdminChats',
  notifications: 'RestaurantNotifications',
  templates: 'RestaurantAdminTemplates',
  templatesAnalytics: 'RestaurantAdminTemplatesAnalytics',
  subscription: 'SuperAdminSubscriptions',
  restaurants: 'SuperAdminRestaurants',
  payments: 'SuperAdminPayments',
  subscriptions: 'SuperAdminSubscriptions',
  cms: "SuperAdminCMS",
}

export const TemplateButtonsDropdown = [
  { label: "None", value: "" },
  { label: "Call to action", value: "action" }
]

export const TemplateButtonsOptions = [
  { label: "Visit Website", value: 'URL' },
  { label: "Call Phone", value: 'PHONE_NUMBER' },
  { label: "Static", value: 'QUICK_REPLY' }
]

export const ProductFetchDelayMessage = "Updating product details, recommended items, and offers may take up to 30 minutes to reflect in the WhatsApp interface, so be patient. If you're not seeing updated data, Try after some time.";
export const TemplateUpdateMessage = "A template can be edited only when it is in a state of Approved, Rejected, or Paused. It can only be edited once within a 24-hour period, up to 10 times per month.";
export const TemplateAnalyticsMessage = "If any template needs to be Re-Run, navigate to the specific campaign and select the 'Re-Run' option to initiate the Re-Run process.";
export const CombinePDFSizeValidationMessage = "The total combined sizes of the PDF files should not be more than 5 MB.";
export const ImportCustomerNote = "Note: Please ensure that the CSV file data is structured with three columns: 'Name' for names, 'WhatsappNumber' for numbers (each phone number must include the country code, e.g., '445551256846'), and 'MarketingObtained' to indicate whether the user wants to receive marketing messages (yes or no). please check sample csv file.";
export const TemplateBodyMessageNote = "Note: {{content}} should represent the message of the campaign. Please add it to the body. The customer will receive a message that is a combination of the body and the campaign message.";

export const PageSizes = [{
  label: 10,
  value: 10,
}, {
  label: 25,
  value: 25,
}, {
  label: 50,
  value: 50,
}, {
  label: 100,
  value: 100,
}];

export const reservationTimeInterval = [{
  label: '15 Mins',
  value: 15,
}, {
  label: '30 Mins',
  value: 30,
}, {
  label: '45 Mins',
  value: 45,
}, {
  label: '60 Mins',
  value: 60,
}];

export const DiscountType = {
  percentage: 'Percentage',
  flat: 'Flat'
}

export const OrderStatus = {
  "ADDED TO CART": 1,
  "ORDER CONFIRMED": 2,
  "ADDED TO CART PARTIALLY": 3,
  "PAYMENT INITIATED": 4,
  "PAYMENT DONE": 5,
  "PAYMENT FAILED": 6,
  "COMPLETE": 7,
  "CANCELLED": 8,
  "PARTIALLY PAID": 9,
}

export const orderTooltipText = [{
  delay: 0,
  placement: "top",
  target: 'sendPaymentLink',
  text: 'Send Payment Link'
}, {
  delay: 0,
  placement: "top",
  target: 'completeOrder',
  text: 'Complete Order'
}, {
  delay: 0,
  placement: "top",
  target: 'edit',
  text: 'Edit'
}]

export const settingTabs = ['App Settings', 'Stripe Account', 'Loyalty Program', 'Chatbot Settings',] //'Notification Settings'

export const superAdminSettingTabs = ['App Settings', 'Chatbot Settings']

export const ReservationStatus = {
  'PENDING': 0,
  'RESERVED': 1,
  'DECLINED': 2,
  'CANCELLED': 3,
  'COMPLETE': 4,
}

export const SubscriptionPaymentStatus = {
  'FAILED': 0,
  'DONE': 1,
  'PENDING': 2
}

export const paymentSuccessMessage = 'Payment successful. Thank you for your purchase!'
export const paymentFailedMessage = 'Payment failed. Please check your payment information and try again.'

// Report Type
export const ReportType = {
  PRODUCT: 'product',
  CATEGORY: 'category',
  HOURLY: 'hourly',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

export const featurePermissions = {
  campaigns: 'Campaign',
  orders: 'Orders',
  tables: 'Tables',
  coupons: 'Coupons',
  transactions: 'Payment & Transactions',
  hourly: 'Hourly Reports',
  daily: 'Daily Reports',
  weekly: 'Weekly Reports',
  categories: 'Categories',
  pos: "POS Integration",
  products: 'Products',
  customers: 'Customers',
  tags: 'Tags',
  reservations: 'Reservations',
  feedbacks: 'Feedbacks',
  chats: 'Chats',
  chatflow: 'No-Code ChatBot',
  sharedteaminbox: 'Shared Team Inbox',
  catalogs: 'Catalogs',
  customnotification: 'Custom Notification',
  customerengagement: 'Customer Engagement',
  wpchatlink: 'WhatsApp Chat Link',
  wpqrcode: 'WhatsApp QR Code',
  wpchatbutton: 'WhatsApp Chat Button',
  clicktowpad: 'Click to WhatsApp Ad',
  chatwidget: 'Chat-Widget',
  event: 'Event',
  loyalty: 'Loyalty Program',
  monthly: 'Monthly Reports',
  yearly: 'Yearly Reports',
  product: 'Products Reports',
  category: 'Category Reports',
}

export const ordersChartTooltips = {
  callbacks: {
    label: function (item, data) {
      var label = data.datasets[item.datasetIndex].label || "";
      var yLabel = item.yLabel;
      var content = "";
      if (data.datasets.length > 1) {
        content += label;
      }
      content += yLabel;
      return content;
    }
  }
}

export const ordersChartscales = {
  yAxes: [
    {
      ticks: {
        callback: function (value) {
          return value;
        }
      }
    }
  ]
}

export const calenderViewMode = {
  day: 'day',
  week: 'week',
  month: 'month',
  year: 'year',
}

// States
export const UK_States = ['Aberconwy and Colwyn',
  'Aberdeen City',
  'Aberdeenshire',
  'Anglesey',
  'Angus',
  'Antrim',
  'Argyll and Bute',
  'Armagh',
  'Avon',
  'Ayrshire',
  'Bath and NE Somerset',
  'Bedfordshire',
  'Belfast',
  'Berkshire',
  'Berwickshire',
  'BFPO',
  'Blaenau Gwent',
  'Buckinghamshire',
  'Caernarfonshire',
  'Caerphilly',
  'Caithness',
  'Cambridgeshire',
  'Cardiff',
  'Cardiganshire',
  'Carmarthenshire',
  'Ceredigion',
  'Channel Islands',
  'Cheshire',
  'City of Bristol',
  'Clackmannanshire',
  'Clwyd',
  'Conwy',
  'Cornwall/Scilly',
  'Cumbria',
  'Denbighshire',
  'Derbyshire',
  'Derry/Londonderry',
  'Devon',
  'Dorset',
  'Down',
  'Dumfries and Galloway',
  'Dunbartonshire',
  'Dundee',
  'Durham',
  'Dyfed',
  'East Ayrshire',
  'East Dunbartonshire',
  'East Lothian',
  'East Renfrewshire',
  'East Riding Yorkshire',
  'East Sussex',
  'Edinburgh',
  'England',
  'Essex',
  'Falkirk',
  'Fermanagh',
  'Fife',
  'Flintshire',
  'Glasgow',
  'Gloucestershire',
  'Greater London',
  'Greater Manchester',
  'Gwent',
  'Gwynedd',
  'Hampshire',
  'Hartlepool',
  'Hereford and Worcester',
  'Hertfordshire',
  'Highlands',
  'Inverclyde',
  'Inverness-Shire',
  'Isle of Man',
  'Isle of Wight',
  'Kent',
  'Kincardinshire',
  'Kingston Upon Hull',
  'Kinross-Shire',
  'Kirklees',
  'Lanarkshire',
  'Lancashire',
  'Leicestershire',
  'Lincolnshire',
  'Londonderry',
  'Merseyside',
  'Merthyr Tydfil',
  'Mid Glamorgan',
  'Mid Lothian',
  'Middlesex',
  'Monmouthshire',
  'Moray',
  'Neath & Port Talbot',
  'Newport',
  'Norfolk',
  'North Ayrshire',
  'North East Lincolnshire',
  'North Lanarkshire',
  'North Lincolnshire',
  'North Somerset',
  'North Yorkshire',
  'Northamptonshire',
  'Northern Ireland',
  'Northumberland',
  'Nottinghamshire',
  'Orkney and Shetland Isles',
  'Oxfordshire',
  'Pembrokeshire',
  'Perth and Kinross',
  'Powys',
  'Redcar and Cleveland',
  'Renfrewshire',
  'Rhonda Cynon Taff',
  'Rutland',
  'Scottish Borders',
  'Shetland',
  'Shropshire',
  'Somerset',
  'South Ayrshire',
  'South Glamorgan',
  'South Gloucesteshire',
  'South Lanarkshire',
  'South Yorkshire',
  'Staffordshire',
  'Stirling',
  'Stockton On Tees',
  'Suffolk',
  'Surrey',
  'Swansea',
  'Torfaen',
  'Tyne and Wear',
  'Tyrone',
  'Vale Of Glamorgan',
  'Wales',
  'Warwickshire',
  'West Berkshire',
  'West Dunbartonshire',
  'West Glamorgan',
  'West Lothian',
  'West Midlands',
  'West Sussex',
  'West Yorkshire',
  'Western Isles',
  'Wiltshire',
  'Wirral',
  'Worcestershire',
  'Wrexham',
  'York']