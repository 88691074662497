import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, CardBody, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, Row, } from "reactstrap";
import InputField from "../../../../components/InputField";
import { setSelectedDropdownForSameValue, trimObjectValues, } from "../../../../services/middleware";
import { templateValidationSchema } from "../../../../constants/Schemas";
import './campaign.css';
import moment from 'moment';

export default function MobileViewCampaign(props) {
  // Props
  const { template, formik } = props;
  const [media, setMedia] = useState([])
  const [mediaVideo, setMediaVideo] = useState("")  

  const image = "https://images.unsplash.com/photo-1724757090342-59922ed19e39?q=80&w=2100&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  
  useEffect(() => {    
    if(typeof template.media !== 'string'){
      if (template.media && template.media?.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setMedia(
                <img
                  src={reader.result}
                  alt="temp "
                  className="img-temp"
                />
              );
            };
            reader.readAsDataURL(template.media);
      }else if(template.media && template.media?.type.startsWith('video/')){
        const reader = new FileReader();
        reader.onloadend = () => {
          setMedia(
            <video
            src={reader.result}
            className="img-temp"
            style={{height: '125px'}}
            controls                    
          />
          );
        };
        reader.readAsDataURL(template.media);
      }else{
        setMedia([])
      }
    }
  }, [template])
  


  const isImage = (url) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  };
  
  const isVideo = (url) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'mkv'];
    const extension = url.split('.').pop().toLowerCase();
    return videoExtensions.includes(extension);
  };

  const formatDate = (dateString) => {
    return moment(dateString).format('DD-MM HH:mm');
  };


  return (
    <>
      <div className="modal-body p-2 d-flex justify-content-center">
        <div class="phone">
          <div class="message-container">
            <div class="cardMobile message-received">
              <div class="img-header">
                {!media.props && (typeof template.media === 'string' && isImage(template.media)) && 
                  <img
                    src={template.media}
                    alt="temp "
                    className="img-temp"
                  />
                }
                {!media.props && (typeof template.media === 'string' && isVideo(template.media)) && 
                  <video
                  src={template.media}
                  className="img-temp"
                  style={{height: '125px'}}
                  controls                    
                />
                }
                {media?.props && template.media && <div>{media}</div>}
                {!template.media && <img
                    src={image}
                    alt="temp "
                    className="img-temp"
                  />}
              </div>
              <div class="content mb-3">
                <h2>{template.draftTemplateId?.label}</h2>
                <h4>{template.name}</h4>
                <p className="content-body truncate-text">{template.message}</p>                
              </div>
              {template.occuranceTime && <p class="timeMobile">{formatDate(template.updatedAt)}</p>}
            </div>
          </div>
        </div>
      </div>
      </>
  );
}
