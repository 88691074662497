import { CAMPAIGNS_API, CUSTOMERS_LIST_API, TAGS_LIST_API } from "../../../../constants/Configs"
import { API_REQUEST, MessageType } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess, showMessage } from "../../../../utils/toast"
import { customersListDTO } from "../dtos/customers"
import { campaignsListDTO } from "../dtos/marketings"
import { tagsListDTO } from "../dtos/tags"

export const getCampaigns = (search, sortBy, orderBy, page = 1) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }

  API(API_REQUEST.get, CAMPAIGNS_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminMarketings.SetCampaigns, campaignsListDTO(res?.data?.data?.rows))
      dispatch(Actions.RestaurantAdminMarketings.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addCampaign = (data, search, activeSort, sortOrder, page, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, CAMPAIGNS_API, data)
    .then((res) => {
      handleSuccess(res)
      handleCloseAddModal()
      getCampaigns(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const editCampaign = (id, data, search, activeSort, sortOrder, page, handleCloseEditModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${CAMPAIGNS_API}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      handleCloseEditModal()
      getCampaigns(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteCampaign = (id, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${CAMPAIGNS_API}/${id}`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal()
      getCampaigns(search, activeSort, sortOrder, page)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const sendCampaign = (id, data, search, activeSort, sortOrder, page, handleCloseSendModal) => {
  showMessage('Campaign sent successfully', MessageType.Success)
  handleCloseSendModal()
  API(API_REQUEST.post, `${CAMPAIGNS_API}/${id}`, { ...data })
    .then(() => {
      handleCloseSendModal()
      getCampaigns(search, activeSort, sortOrder, page)
    }).catch(err => {
      console.error(err)
      handleError(err)
    })
}

export const getTags = async () => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    page: 1, size: 1000
  }

  await API(API_REQUEST.get, TAGS_LIST_API, { params })
    .then(res => {
      const tags = tagsListDTO(res?.data?.data?.rows)?.map((tag) => {
        return {
          label: tag?.tagName,
          value: tag?.id
        }
      })
      dispatch(Actions.RestaurantAdminMarketings.SetTags, tags)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getCustomers = async (searchValue = '') => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    pagination: 0, isSubscribe: 1, search: searchValue
  }

  return await API(API_REQUEST.get, CUSTOMERS_LIST_API, { params })
    .then(res => {
      const customers = customersListDTO(res.data.data.rows)?.map((customer) => {
        return {
          label: `${customer?.customer?.name || ''} - ${customer?.customer?.whatsappNumber}`,
          value: customer?.customer?.id
        }
      })
      dispatch(Actions.RestaurantAdminMarketings.SetCustomers, customers)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return customers
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return []
    })
}