import { useState, useEffect } from "react";
import Select from 'react-select'
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, Col, Row, Label } from "reactstrap";
import { useFormik } from 'formik';
import { RESTAURANT_API, RESTAURANT_EMBEDDED_SETTING_API } from "../../constants/Configs";
import { API_REQUEST, Country, CountryCode, multiSelectDropdownStyle, registrationInputFields, selectDropdownStyle, serviceThrough, UK_States } from "../../constants/General";
import InputField from "../../components/InputField";
import { registerValidationSchema } from "../../constants/Schemas";
import API from "../../services/api";
import { handleError, handleSuccess } from "../../utils/toast";
import Loader from "../../components/Loader";
import { generateDropdownOptionForStates, generateDropdownOptionFromObject, removeEmptyKeysFromObject, trimObjectValues } from "../../services/middleware";
import logo from '../../assets/images/logo-white.svg';

const Register = () => {
  // State
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [sdkResponse, setSdkResponse] = useState('');
  const [restaurantId, setRestaurantId] = useState('');

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      addressLine1: '',
      addressLine2: '',
      zipcode: '',
      state: '',
      tagLine: '',
      phone: '',
      service: [],
      tableCount: '',
      country: ''
    },
    validationSchema: registerValidationSchema,
    onSubmit: (values, { resetForm }) => {
      trimObjectValues(values)
      setLoading(true)
      const payload = {
        name: values.name,
        tableCount: parseInt(values.tableCount),
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        zipCode: values.zipcode,
        state: values.state?.value,
        country: values.country?.value,
        tagLine: values.tagLine,
        phone: `${CountryCode[values.country?.value]}${values.phone}`,
        email: values.email,
        password: values.password,
        serviceThrough: values.service.map(datum => datum.value),
      }
      const filterData = removeEmptyKeysFromObject(payload)
      userRegister(filterData, resetForm, setLoading)
    }
  });

  useEffect(() => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: '994645628631626', // Your Facebook app ID
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v21.0'
      });
    };

    // Load Facebook SDK
    (function(d, s, id) {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      js.async = true;
      js.defer = true;
      js.crossOrigin = 'anonymous';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, [])
  

  // Register api call
  const userRegister = (userData, resetForm, setLoading) => {
    API(API_REQUEST.post, RESTAURANT_API, userData)
      .then((res) => {
        handleSuccess(res)
        setLoading(false)
        resetForm({ values: '' });
        setRestaurantId(res.data.data.rd)
        navigate('/login');
        launchWhatsAppSignup()
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
      })
  }

  const restaurantEmbedded = (data) => {
    API(API_REQUEST.put, RESTAURANT_EMBEDDED_SETTING_API, data)
      .then((res) => {
        handleSuccess(res)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        handleError(error)
        
      })
  }

  useEffect(() => {
    if(sdkResponse){
      const payload = {
        phoneNumberId: sdkResponse.data.phone_number_id,
        wabaId: sdkResponse.data.waba_id,
        rd: restaurantId,
      }
      restaurantEmbedded(payload)
    }
  }, [sdkResponse]);

  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const code = response.authResponse.code;
      
    }
    setSdkResponse(JSON.stringify(response, null, 2));
  };

  const launchWhatsAppSignup = () => {
    window.FB.login(fbLoginCallback, {
      config_id: '1052083649795475', // configuration ID goes here
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      }
    });
  };

  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap large">
        <div className="logo-wrap">
          <span><img src={logo} alt="logo" /></span>
        </div>
        <Card className="shadow">
          {loading && <Loader />}
          <CardBody>
            <div className="mb-4">
              <h1>Sign Up</h1>
            </div>
            <Form role="form">
              <Row>
                <Col md={6}>
                  <InputField required placeholder="Name" fieldName="name" formik={formik} />
                </Col>
                <Col md={6}>
                  <InputField required placeholder="Tagline" fieldName="tagLine" formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Label className='inputfield required-field'>
                    Service
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" isMulti value={formik.values.service} options={generateDropdownOptionFromObject(serviceThrough)} isSearchable={false} styles={multiSelectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("service", selectedOption);
                      }}
                    />
                    {formik.touched?.service && formik.errors.service && <span className="error-message input-group-alternative">{formik.errors.service}</span>}
                  </section>
                </Col>
                <Col md={6}>
                  <InputField required placeholder="Table count" inputType='number' fieldName="tableCount" formik={formik} />
                </Col>
              </Row>
              {registrationInputFields.map((row, index) => {
                return <Row key={index}>
                  <Col md={6}>
                    <InputField required={row?.requiredLeft} passwordField placeholder={row.placeholderLeft} fieldName={row.fieldNameLeft} inputType={row?.passwordIconLeft ? showNewPassword ? "text" : "password" : row?.inputTypeLeft} passwordIcon={row?.passwordIconLeft} formik={formik} showPassword={showNewPassword} setShowPassword={setShowNewPassword} />
                  </Col>
                  <Col md={6}>
                    <InputField required={row?.requiredRight} passwordField placeholder={row.placeholderRight} dropdownChild={generateDropdownOptionFromObject(serviceThrough)} fieldName={row.fieldNameRight} inputType={row?.passwordIconRight ? showConfirmPassword ? "text" : "password" : row?.inputTypeRight} passwordIcon={row?.passwordIconRight} formik={formik} showPassword={showConfirmPassword} setShowPassword={setShowConfirmPassword} />
                  </Col>
                </Row>
              })}
              <Row>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    State
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={generateDropdownOptionForStates(UK_States)} value={formik.values.state} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("state", selectedOption);
                      }}
                    />
                    {formik.touched?.state && formik.errors.state && <span className="error-message input-group-alternative">{formik.errors.state}</span>}
                  </section>
                </Col>
                <Col md={4}>
                  <InputField required placeholder="Zipcode" fieldName="zipcode" formik={formik} />
                </Col>
                <Col md={4}>
                  <Label className='inputfield required-field'>
                    Country
                  </Label>
                  <section className="mb-3 input-group-alternative">
                    <Select className="custom-select" options={Country} value={formik.values.country} isSearchable={false} styles={selectDropdownStyle}
                      onChange={selectedOption => {
                        formik.setFieldValue("country", selectedOption);
                      }}
                    />
                    {formik.touched?.country && formik.errors.country && <span className="error-message input-group-alternative">{formik.errors.country}</span>}
                  </section>
                </Col>
              </Row>
              <div className="btn-wrap">
                <Button color='primary' disabled={!!Object.values(formik.errors)?.length} type="button" onClick={formik.handleSubmit}>
                  Sign up
                </Button>
              </div>
            </Form>
            <div className="links-wrap">
              <Link className="secondary-link" to="/login">
                Already have an account?
              </Link>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Register;
