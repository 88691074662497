export const Actions = {
  User: {
    SetIsAdminUser: 'SET_USER_IS_ADMIN_USER',
    SetImagePreview: 'SET_USER_IMAGE_PREVIEW',
    SetImageLink: 'SET_USER_IMAGE_LINK',
    SetFileType: 'SET_USER_FILE_TYPE',
    SetPageSize: 'SET_USER_PAGE_SIZE',
    UserReset: 'USER_RESET'
  },
  RestaurantAdmin: {
    SetLoading: 'SET_RESTAURANT_ADMIN_LOADING',
    SetRestaurant: 'SET_RESTAURANT_DATA',
    SetSettings: 'SET_RESTAURANT_SETTINGS_FIELDS',
    Reset: 'RESTAURANT_ADMIN_RESET'
  },
  RestaurantAdminTables: {
    SetTables: 'SET_RESTAURANT_ADMIN_TABLES',
    SetSearch: 'SET_RESTAURANT_ADMIN_TABLES_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_TABLES_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_TABLES_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_TABLES_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_TABLES_PAGE',
    Reset: 'RESTAURANT_ADMIN_TABLES_RESET'
  },
  RestaurantAdminCategories: {
    SetCategories: 'SET_RESTAURANT_ADMIN_CATEGORIES',
    SetSearch: 'SET_RESTAURANT_ADMIN_CATEGORIES_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_CATEGORIES_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_CATEGORIES_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_CATEGORIES_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_CATEGORIES_PAGE',
    Reset: 'RESTAURANT_ADMIN_CATEGORIES_RESET'
  },
  RestaurantAdminCustomers: {
    SetCustomers: 'SET_RESTAURANT_ADMIN_CUSTOMERS',
    SetSearch: 'SET_RESTAURANT_ADMIN_CUSTOMERS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_CUSTOMERS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_CUSTOMERS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_CUSTOMERS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_CUSTOMERS_PAGE',
    Reset: 'RESTAURANT_ADMIN_CUSTOMERS_RESET'
  },
  RestaurantAdminTags: {
    SetTags: 'SET_RESTAURANT_ADMIN_TAGS',
    SetCustomers: 'SET_RESTAURANT_ADMIN_TAGS_CUSTOMERS',
    SetSearch: 'SET_RESTAURANT_ADMIN_TAGS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_TAGS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_TAGS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_TAGS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_TAGS_PAGE',
    Reset: 'RESTAURANT_ADMIN_TAGS_RESET'
  },
  RestaurantAdminProducts: {
    SetProducts: 'SET_RESTAURANT_ADMIN_PRODUCTS',
    SetProductsWithPagination: 'SET_RESTAURANT_ADMIN_PRODUCTS_WITH_PAGINATION',
    SetCategories: 'SET_RESTAURANT_ADMIN_PRODUCTS_CATEGORIES',
    SetSelectedCategoy: 'SET_SELECTED_CATEGORY_RESTAURANT_ADMIN_PRODUCTS',
    SetIsRecommended: 'SET_IS_RECOMMENDED_RESTAURANT_ADMIN_PRODUCTS',
    SetSearch: 'SET_RESTAURANT_ADMIN_PRODUCTS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_PRODUCTS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_PRODUCTS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_PRODUCTS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_PRODUCTS_PAGE',
    Reset: 'RESTAURANT_ADMIN_PRODUCTS_RESET'
  },
  RestaurantAdminOffers: {
    SetOffers: 'SET_RESTAURANT_ADMIN_OFFERS',
    SetOffersWithPagination: 'SET_RESTAURANT_ADMIN_OFFERS_WITH_PAGINATION',
    SetProducts: 'SET_RESTAURANT_ADMIN_OFFERS_PRODUCTS',
    SetSearch: 'SET_RESTAURANT_ADMIN_OFFERS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_OFFERS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_OFFERS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_OFFERS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_OFFERS_PAGE',
    Reset: 'RESTAURANT_ADMIN_OFFERS_RESET'
  },
  RestaurantAdminCoupons: {
    SetCoupons: 'SET_RESTAURANT_ADMIN_COUPONS',
    SetSearch: 'SET_RESTAURANT_ADMIN_COUPONS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_COUPONS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_COUPONS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_COUPONS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_COUPONS_PAGE',
    Reset: 'RESTAURANT_ADMIN_COUPONS_RESET'
  },
  RestaurantAdminEvents: {
    SetEvents: 'SET_RESTAURANT_ADMIN_EVENT',
    SetEventList: 'SET_RESTAURANT_ADMIN_EVENT_LIST',
    SetTables: 'SET_RESTAURANT_ADMIN_EVENT_TABLES',
    SetOrders: 'SET_RESTAURANT_ADMIN_EVENT_ORDER_TABLES',
    SetCustomers: 'SET_RESTAURANT_ADMIN_EVENT_CUSTOMER_TABLES',
    SetSlots: 'SET_RESTAURANT_ADMIN_EVENT_SLOT_TABLES',
    SetSearch: 'SET_RESTAURANT_ADMIN_EVENT_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_EVENT_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_EVENT_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_EVENT_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_EVENT_PAGE',
    Reset: 'RESTAURANT_ADMIN_EVENT_RESET'
  },
  RestaurantAdminPayouts: {
    SetPayouts: 'SET_RESTAURANT_ADMIN_PAYOUT',
    SetPayoutList: 'SET_RESTAURANT_ADMIN_PAYOUT_LIST',
    SetTables: 'SET_RESTAURANT_ADMIN_PAYOUT_TABLES',
    SetOrganization: 'SET_RESTAURANT_ADMIN_ORGANIZATION',
    SetSearch: 'SET_RESTAURANT_ADMIN_PAYOUT_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_PAYOUT_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_PAYOUT_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_PAYOUT_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_PAYOUT_PAGE',
    Reset: 'RESTAURANT_ADMIN_PAYOUT_RESET'
  },
  RestaurantAdminTickets: {
    SetTicket: 'SET_RESTAURANT_ADMIN_TICKET',
    SetTables: 'SET_RESTAURANT_ADMIN_TICKET_TABLES',
    SetSearch: 'SET_RESTAURANT_ADMIN_TICKET_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_TICKET_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_TICKET_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_TICKET_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_TICKET_PAGE',
    Reset: 'RESTAURANT_ADMIN_TICKET_RESET'
  },
  RestaurantAdminMarketings: {
    SetCampaigns: 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS',
    SetTags: 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_TAGS',
    SetCustomers: 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_CUSTOMERS',
    SetSearch: 'SET_RESTAURANT_ADMIN_MARKETINGS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_MARKETINGS_CAMPAIGNS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_MARKETINGS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_MARKETINGS_PAGE',
    Reset: 'RESTAURANT_ADMIN_MARKETINGS_RESET'
  },
  RestaurantAdminOrders: {
    SetOrders: 'SET_RESTAURANT_ADMIN_ORDERS',
    SetProducts: 'SET_RESTAURANT_ADMIN_ORDERS_PRODUCTS',
    SetCustomers: 'SET_RESTAURANT_ADMIN_ORDERS_CUSTOMERS',
    SetCoupons: 'SET_RESTAURANT_ADMIN_ORDERS_COUPONS',
    SetTables: 'SET_RESTAURANT_ADMIN_ORDERS_TABLES',
    SetSearch: 'SET_RESTAURANT_ADMIN_ORDERS_SEARCH',
    SetStartDate: 'SET_RESTAURANT_ADMIN_ORDERS_START_DATE',
    SetEndDate: 'SET_RESTAURANT_ADMIN_ORDERS_END_DATE',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_ORDERS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_ORDERS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_ORDERS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_ORDERS_PAGE',
    Pagination: 'SET_RESTAURANT_ADMIN_ORDERS_PAGINATION',
    Reset: 'RESTAURANT_ADMIN_ORDERS_RESET'
  },
  RestaurantAdminFeedbacks: {
    SetFeedbacks: 'SET_RESTAURANT_ADMIN_FEEDBACKS',
    SetSearch: 'SET_RESTAURANT_ADMIN_FEEDBACKS_SEARCH',
    SetStartDate: 'SET_RESTAURANT_ADMIN_FEEDBACKS_START_DATE',
    SetEndDate: 'SET_RESTAURANT_ADMIN_FEEDBACKS_END_DATE',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_FEEDBACKS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_FEEDBACKS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_FEEDBACKS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_FEEDBACKS_PAGE',
    Reset: 'RESTAURANT_ADMIN_FEEDBACKS_RESET'
  },
  RestaurantAdminTemplates: {
    SetTemplates: 'SET_RESTAURANT_ADMIN_TEMPLATES',
    SetSearch: 'SET_RESTAURANT_ADMIN_TEMPLATES_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_TEMPLATES_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_TEMPLATES_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_TEMPLATES_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_TEMPLATES_PAGE',
    Reset: 'RESTAURANT_ADMIN_TEMPLATES_RESET'
  },
  RestaurantAdminTemplatesAnalytics: {
    SetTemplates: 'SET_RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_TEMPLATES',
    SetSearch: 'SET_RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_SEARCH',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_PAGE',
    Reset: 'RESTAURANT_ADMIN_TEMPLATE_ANALYTICS_RESET'
  },
  RestaurantAdminReports: {
    SetReports: 'SET_RESTAURANT_ADMIN_REPORTS',
    SetReportsWithPagination: 'SET_RESTAURANT_ADMIN_REPORTS_WITH_PAGINATION',
    SetSearch: 'SET_RESTAURANT_ADMIN_REPORTS_SEARCH',
    SetType: 'SET_RESTAURANT_ADMIN_REPORTS_TYPE',
    SetStartDate: 'SET_RESTAURANT_ADMIN_REPORTS_START_DATE',
    SetEndDate: 'SET_RESTAURANT_ADMIN_REPORTS_END_DATE',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_REPORTS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_REPORTS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_REPORTS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_REPORTS_PAGE',
    Reset: 'RESTAURANT_ADMIN_REPORTS_RESET'
  },
  RestaurantAdminReservations: {
    SetReservations: 'SET_RESTAURANT_ADMIN_RESERVATIONS',
    SetTables: 'SET_RESTAURANT_ADMIN_RESERVATIONS_TABLES',
    SetSearch: 'SET_RESTAURANT_ADMIN_RESERVATIONS_SEARCH',
    SetStartDate: 'SET_RESTAURANT_ADMIN_RESERVATIONS_START_DATE',
    SetEndDate: 'SET_RESTAURANT_ADMIN_RESERVATIONS_END_DATE',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_RESERVATIONS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_RESERVATIONS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_RESERVATIONS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_RESERVATIONS_PAGE',
    Reset: 'RESTAURANT_ADMIN_RESERVATIONS_RESET'
  },
  RestaurantAdminTransactions: {
    SetTransactions: 'SET_RESTAURANT_ADMIN_TRANSACTIONS',
    SetSearch: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_SEARCH',
    SetStartDate: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_START_DATE',
    SetEndDate: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_END_DATE',
    SetActiveSort: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_ACTIVE_SORT',
    SetSortOder: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_SORT_ORDER',
    SetTotalCount: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_TOTAL_COUNT',
    SetPage: 'SET_RESTAURANT_ADMIN_TRANSACTIONS_PAGE',
    Reset: 'RESTAURANT_ADMIN_TRANSACTIONS_RESET'
  },
  RestaurantChatAutomation: {
    SetChatAutomation: 'SET_RESTAURANT_CHAT_AUTOMATION',
  },
  RestaurantNotifications: {
    SetNotitfication: 'SET_NOTIFICATION',
  },
  SuperAdmin: {
    SetLoading: 'SET_SUPER_ADMIN_LOADING',
    SetSettings: 'SET_SUPER_ADMIN_SETTINGS_FIELDS',
    SetSettingMessages: 'SET_SUPER_ADMIN_SETTINGS_FIELDS_MESSAGES',
    Reset: 'SUPER_ADMIN_RESET'
  },
  SuperAdminRestaurants: {
    SetRestaurants: 'SET_SUPER_ADMIN_RESTAURANTS',
    SetSearch: 'SET_SUPER_ADMIN_RESTAURANTS_SEARCH',
    SetActiveSort: 'SET_SUPER_ADMIN_RESTAURANTS_ACTIVE_SORT',
    SetSortOder: 'SET_SUPER_ADMIN_RESTAURANTS_SORT_ORDER',
    SetTotalCount: 'SET_SUPER_ADMIN_RESTAURANTS_TOTAL_COUNT',
    SetPage: 'SET_SUPER_ADMIN_RESTAURANTS_PAGE',    
    Reset: 'SUPER_ADMIN_RESTAURANTS_RESET'
  },
  SuperAdminCMS: {
    SetCMS: 'SET_SUPER_ADMIN_CMS',
    SetSearch: 'SET_SUPER_ADMIN_CMS_SEARCH',
    SetActiveSort: 'SET_SUPER_ADMIN_CMS_ACTIVE_SORT',
    SetSortOder: 'SET_SUPER_ADMIN_CMS_SORT_ORDER',
    SetTotalCount: 'SET_SUPER_ADMIN_CMS_TOTAL_COUNT',
    SetPage: 'SET_SUPER_ADMIN_CMS_PAGE',
    SetCMSCategories: 'SET_SUPER_ADMIN_CMSCATEGORIES',
    Reset: 'SUPER_ADMIN_CMS_RESET'
  },
  SuperAdminSubscriptions: {
    SetSubscriptions: 'SET_SUPER_ADMIN_SUBSCRIPTIONS',
    SetSearch: 'SET_SUPER_ADMIN_SUBSCRIPTIONS_SEARCH',
    SetActiveSort: 'SET_SUPER_ADMIN_SUBSCRIPTIONS_ACTIVE_SORT',
    SetSortOder: 'SET_SUPER_ADMIN_SUBSCRIPTIONS_SORT_ORDER',
    SetTotalCount: 'SET_SUPER_ADMIN_SUBSCRIPTIONS_TOTAL_COUNT',
    SetPage: 'SET_SUPER_ADMIN_SUBSCRIPTIONS_PAGE',
    Reset: 'SUPER_ADMIN_SUBSCRIPTIONS_RESET'
  },
  SuperAdminPayments: {
    SetPayments: 'SET_SUPER_ADMIN_PAYMENTS',
    SetSearch: 'SET_SUPER_ADMIN_PAYMENTS_SEARCH',
    SetActiveSort: 'SET_SUPER_ADMIN_PAYMENTS_ACTIVE_SORT',
    SetSortOder: 'SET_SUPER_ADMIN_PAYMENTS_SORT_ORDER',
    SetTotalCount: 'SET_SUPER_ADMIN_PAYMENTS_TOTAL_COUNT',
    SetPage: 'SET_SUPER_ADMIN_PAYMENTS_PAGE',
    Reset: 'SUPER_ADMIN_PAYMENTS_RESET'
  },
};
