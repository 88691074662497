import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';
import { API_REQUEST, multiSelectDropdownStyle } from '../../../../constants/General';
import API from "../../../../services/api"
import {GET_AICONTENT} from "../../../../constants/Configs"
import { handleError } from '../../../../utils/toast';

const EventOverview = ( { eventTitle, setEventTitle, eventSummary, setEventSummary } ) => {
  const maxSummaryLength = 140;
  const [buttonText, setButtonText] = useState('⚡Generate campaign content through AI');

  const handleAIContentCall = (bodyText) =>{
      const payload = {
        "content": buttonText == '⚡Generate campaign content through AI' ? bodyText + " with character limit 140" : 'Re Generate below content ' + bodyText + " with character limit 140",
      }
      dispatch(Actions.RestaurantAdmin.SetLoading, true)
      API(API_REQUEST.post, GET_AICONTENT,payload)
      .then(res => {
        if(res.status == 200){
          if(res.data.code == 200){
            setEventSummary(res.data.data);
            setButtonText('Re-Generate campaign content through AI');
          }else{
            handleError('Something went wrong.');
          }
        }else{
          handleError('Something went wrong.');
        }  
          dispatch(Actions.RestaurantAdmin.SetLoading, false)
        })
        .catch(err => {
          handleError("err")
          dispatch(Actions.RestaurantAdmin.SetLoading, false)
        })
    }

  return (
    <div className="border p-4 rounded">
      <h5 className="mb-3">Event Overview</h5>

      {/* Event Title */}
      <FormGroup>
        <Label for="eventTitle" className="fw-bold">Event title</Label>
        <p className="text-muted">Be clear and descriptive with a title that tells people what your event is about.</p>
        <Input
          id="eventTitle"
          type="text"
          placeholder="Event title*"
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
        />
      </FormGroup>

      {/* Summary */}
      <FormGroup>
        <Label for="summary" className="fw-bold">Summary</Label>
        <p className="text-muted">
          Grab people's attention with a short description about your event. Attendees will see this at the top of your event page. (140 characters max) 
        </p>
        <Input
          id="summary"
          type="textarea"
          placeholder="Summary*"
          maxLength={maxSummaryLength}
          value={eventSummary}
          row={5}
          onChange={(e) => setEventSummary(e.target.value)}
        />
        <div className="text-end text-muted">{eventSummary?.length} / {maxSummaryLength}</div>
      </FormGroup>

      {/* Suggest Summary Button */}
      <Button color="primary" outline className="mt-2" onClick={() => handleAIContentCall(eventSummary)}>
         {buttonText}
      </Button>
    </div>
  );
};

export default EventOverview;
