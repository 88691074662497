import React, { useState } from "react";
import { QRCode } from "react-qrcode-logo";
import { Container, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";

const QRCodeBadge = () => {
  const [title, setTitle] = useState("Scan QR Code");
  const [selectedType, setSelectedType] = useState("bitcoin");
  const [qrColor, setQrColor] = useState("#000000");
  const [logo, setLogo] = useState(null);

  const qrOptions = [
    { value: "bitcoin", label: "Pay in Bitcoin" },
    { value: "video", label: "Play Video" },
    { value: "wifi", label: "Connect WiFi" },
    { value: "location", label: "Find Us" },
    { value: "app", label: "Download App" },
    { value: "review", label: "Write a Review" },
    { value: "messenger", label: "Facebook Messenger" },
    { value: "playlist", label: "Listen Playlist" },
  ];

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col md={6} className="mx-auto">
          <Form>
            <FormGroup>
              <Label>Top Title</Label>
              <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label>Select QR Type</Label>
              <Input type="select" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                {qrOptions.map((option) => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>QR Code Color</Label>
              <Input type="color" value={qrColor} onChange={(e) => setQrColor(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label>Upload Logo</Label>
              <Input type="file" accept="image/*" onChange={handleLogoUpload} />
            </FormGroup>
          </Form>
          <div className="qr-container text-center p-3 border rounded">
            <h5>{title}</h5>
            <QRCode
              value="https://github.com/gcoro/react-qrcode-logo"
              size={200}
              fgColor={qrColor}
              logoImage={logo}
              logoWidth={50}
              logoHeight={50}
              logoOpacity={1}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default QRCodeBadge;