import React, { useState, useRef, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import { Camera, CameraOff, RotateCcw, FlipHorizontal, BadgeCheck } from 'lucide-react';
import { Container, Row, Col, Card, CardBody, CardTitle, Button, Spinner, Alert, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';
import API from "../../../../services/api";
import { API_REQUEST } from "../../../../constants/General";
import { EVENT_API } from "../../../../constants/Configs";
import { verifyTicket } from "../../utils/middleware/events";
import { useSelector } from 'react-redux';
import { getEvent } from '../../utils/middleware/events';
import Select from 'react-select';

const QRCodeScanner = () => {
  const [scannedData, setScannedData] = useState('No QR code scanned');
  const [scanning, setScanning] = useState(false);
  const [facingMode, setFacingMode] = useState('environment');
  const [loading, setLoading] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isValid, setIsValid] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [selectedOption, setSelectedOption] = useState(null)

  const qrReaderRef = useRef(null);
  const { eventList } = useSelector(state => state.restaurantAdminEvents)

  const startScanner = (decodedText) => {
    if (decodedText) {
      setScannedData(decodedText.text);
      fetchTicketDetails(decodedText.text);
    }
  };

  const fetchTicketDetails = async (data) => {
    try {
      const response = await API(API_REQUEST.get, `${EVENT_API}/verify/ticket/${data}`);
      setTicketDetails(response?.data?.data);
      setIsValid(response?.data?.message);
    } catch (err) {
      setError("Invalid Ticket or API Error");
    }
  };

  const handleChange = (selected) => {
    setSelectedOption(selected);
  };

  const handleError = (error) => {
    console.error(error);
    setError(`Camera Error: ${error?.message || 'Failed to access camera'}`);
    setScanning(false);
  };

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === 'environment' ? 'user' : 'environment'));
  };

  const startNewScan = () => {
    setScannedData('No QR code scanned');
    setTicketDetails(null);
    setIsValid("");
    setError(null);
    setScanning(true);
  };

  const toggleScanning = () => {
    if (scanning) {
      setScanning(false);
    } else {
      startNewScan();
    }
  };

  useEffect(() => {
    return () => {
      setScanning(false);
    };
  }, []);

  useEffect(() => {
    getEvent({ pageSize: 1000 })
  }, [])

  const openModal = () => setModalOpen(true);
  const closeModal = () => {
    setModalOpen(false);
    setCustomerName("");
    setCustomerNumber("");
  };

  const handleMarkAsAttendWithDetails = () => {
    if (!customerName || !customerNumber) {
      setError("Please enter Name and Number");
      return;
    }

    try {
      const payload = {
        customers: [{ name: customerName, whatsapp: customerNumber }],
        restaurantId: ticketDetails?.restaurantId,
        eventId: ticketDetails?.eventId,
        eventOrderId: ticketDetails?.eventOrderId,
        ticketUniqueId: ticketDetails?.ticketUniqueId,
        customerCount: 1,
      };
      verifyTicket(payload);
      closeModal();
    } catch (err) {
      setError("Error updating attendance");
    }
  };
  const handleMarkAsAttend = () => {
    try {
      const name = `${ticketDetails?.eventOrder?.firstName} ${ticketDetails?.eventOrder?.lastName}`;
      const phone = ticketDetails?.eventOrder?.phone;
      const email = ticketDetails?.eventOrder?.email;
      const payload = {
        customers: [{ name: name, whatsapp: phone, email }],
        restaurantId: ticketDetails?.restaurantId,
        eventId: ticketDetails?.eventId,
        eventOrderId: ticketDetails?.eventOrderId,
        ticketUniqueId: ticketDetails?.ticketUniqueId,
        customerCount: 1,
      };
      verifyTicket(payload);
    } catch (err) {
      setError("Error updating attendance");
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-100">
      <Card className="w-100" style={{ maxWidth: '400px' }}>
        <CardBody>
          <CardTitle tag="h4" className="text-center">QR Code Scanner</CardTitle>
          <Row className='mb-3'>
            <Col >
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select an Event..."
                value={selectedOption}
                onChange={handleChange}
                isClearable={true}
                isSearchable={true}
                name="eventName"
                options={eventList}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {scanning ? (
                <>
                  <div className="position-relative">
                    <QrReader
                      ref={qrReaderRef}
                      constraints={{ facingMode }}
                      scanDelay={500}
                      onResult={startScanner}
                      onError={handleError}
                      className="w-100"
                    />
                    {/* <Button color="light" className="position-absolute top-0 end-0 m-2" onClick={toggleCamera}>
                      <FlipHorizontal />
                    </Button> */}
                  </div>
                </>
              ) : (
                <div className="text-center p-5 bg-light rounded">
                  {error ? <Alert color="danger">{error}</Alert> : 'Camera is off'}
                </div>
              )}
            </Col>
          </Row>

          <div className="d-flex gap-2 mt-3">
            <Button color={scanning ? "danger" : "success"} className="w-100" onClick={toggleScanning}>
              {scanning ? <CameraOff style={{ marginBottom: "-23px" }} /> : <Camera style={{ marginBottom: "-23px" }} />} {scanning ? ' Stop Scan' : ' Start Scan'}
            </Button>
            <Button color="primary" className="w-100" onClick={startNewScan}>
              <RotateCcw style={{ marginBottom: "-23px" }} /> New Scan
            </Button>
          </div>
          {console.log(new Date(selectedOption?.eventDate) < new Date())}
          {console.log(new Date(selectedOption?.eventDate) > new Date())}
          <div className="mt-3">
            <h3>Scan Result:</h3>
            <div className="p-3 bg-light rounded">
              {loading ? (
                <div className="text-center">
                  <Spinner /> Verifying ticket...
                </div>
              ) : error ? (
                <Alert color="danger">{error}</Alert>
              ) : ticketDetails ? (
                <>
                  {ticketDetails.eventId !== selectedOption?.value ? (
                    <Card className="text-center">
                      <h3 className='text-danger mt-2'>Ticket is not valid for the selected event.</h3>
                    </Card>

                  ) : new Date(selectedOption?.eventDate) > new Date() ? (
                    <Card className="text-center">
                      <h3 className='text-success mt-2'>Event has not started yet.</h3>
                    </Card>
                  ) : new Date() > new Date(selectedOption?.eventDate) ? (
                    <Card className="text-center">
                      <h3 className='text-success mt-2'>Event is completed.</h3>
                    </Card>
                  ) : (
                    <>
                      <h3 className="text-center">
                        Ticket Status: {isValid === "Success" ? "Valid ✅" : "Invalid ❌"}
                      </h3>
                      {ticketDetails?.ticketVerified === 1 ? (
                        <h3 className="text-center text-success">Already Attended</h3>
                      ) : (
                        <div className="d-flex gap-2">
                          <Button color="primary" className="w-100" onClick={openModal}>
                            Fill Details & Confirm
                          </Button>
                          <Button color="primary" className="w-100" onClick={handleMarkAsAttend}>
                            Confirm
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <h4 className="text-center text-info">Start Scan</h4>
              )}
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Modal for Name and Number */}
      <Modal isOpen={modalOpen} toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Enter Customer Details</ModalHeader>
        <ModalBody>
          <Label for="customerName">Customer Name</Label>
          <Input
            id="customerName"
            type="text"
            placeholder="Enter Name"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />
          <Label for="customerNumber" className="mt-2">Customer Number</Label>
          <Input
            id="customerNumber"
            type="number"
            placeholder="Enter Number"
            value={customerNumber}
            onChange={(e) => setCustomerNumber(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleMarkAsAttendWithDetails}>
            <BadgeCheck style={{ marginBottom: "-23px" }} /> Confirm Attendance
          </Button>
          <Button color="secondary" onClick={closeModal}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default QRCodeScanner;
