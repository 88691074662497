import React, { useState } from "react";
import { Button, ButtonGroup, Container, Row, Col, Input, Label, FormGroup } from "reactstrap";

const AgeRestrictionComponent = ({ eventEntryTime, setEventEntryTime, parkingOption, setParkingOption, selectedAge, setSelectedAge, isHours, setIsHours, ageRestriction, setAgeRestriction, isOnline, isParentNeed, setIsParentNeed }) => {

  const parkingOptions = [{
    name: "Free parking",
    value: 'free'
  }, {
    name: "Paid parking",
    value: 'paid'
  }, {
    name: "No parking options",
    value: 'na'
  }]

  return (
    <div className="p-4 border rounded ">
      <h4 className="mb-4">Add highlights about your event</h4>

      {/* Check-in Time */}
      <FormGroup>
        <Label className="fw-bold">What time can attendees check in before the event?</Label>
        <Row>
          <Col xs="4">
            <Input type="number" value={eventEntryTime} onChange={(e) => setEventEntryTime(e.target.value)} defaultValue="30" min="1" />
          </Col>
          <Col xs="8">
            <ButtonGroup>
              <Button color={isHours === "no" ? "primary" : "light"} onClick={() => setIsHours('no')}>Minutes</Button>
              <Button color={isHours === "yes" ? "primary" : "light"} onClick={() => setIsHours('yes')} >Hours</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </FormGroup>

      {/* Age Restriction */}
      <FormGroup>
        <Label className="fw-bold">Is there an age restriction?</Label>
        <ButtonGroup className="d-flex">
          <Button
            color={ageRestriction === "no" ? "primary" : "light"}
            onClick={() => setAgeRestriction("no")}
          >
            All ages allowed
          </Button>
          <Button
            color={ageRestriction === "yes" ? "primary" : "light"}
            onClick={() => setAgeRestriction("yes")}
          >
            There's an age restriction
          </Button>
          <Button
            color={ageRestriction === "parent" ? "primary" : "light"}
            onClick={() => setAgeRestriction("parent")}
          >
            Parent or guardian needed
          </Button>
        </ButtonGroup>
      </FormGroup>

      {/* Age Selection */}
      {ageRestriction === "yes" && (
        <FormGroup>
          <Label className="fw-bold">What ages are allowed?</Label>
          <ButtonGroup className="d-flex flex-wrap">
            {[12, 13, 14, 15, 16, 17, 18, 19, 21].map((age) => (
              <Button
                key={age}
                color={parseInt(selectedAge) === age ? "primary" : "light"}
                className="m-1"
                onClick={() => setSelectedAge(age)}
              >
                {age}+
              </Button>
            ))}
          </ButtonGroup>
        </FormGroup>
      )}

      {/* Parking Options */}
      <FormGroup>
        <Label className="fw-bold">Is there parking at your venue?</Label>
        <ButtonGroup className="d-flex">
          {parkingOptions.map((option) => (
            <Button
              key={option.value}
              color={parkingOption === option.value ? "primary" : "light"}
              onClick={() => setParkingOption(option.value)}
            >
              {option.name}
            </Button>
          ))}
        </ButtonGroup>
      </FormGroup>
    </div>
  );
};

export default AgeRestrictionComponent;
