import React, { useEffect, useState } from "react";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardBody, Form, Spinner } from "reactstrap";
import { EMAIL_VERIFICATION_API } from "../../constants/Configs";
import { API_REQUEST, BrandName } from "../../constants/General";
import { handleError } from "../../utils/toast";
import API from "../../services/api";

export default function EmailVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location?.search.split("=");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  // Mount
  useEffect(() => emailVerification(), []); // eslint-disable-line react-hooks/exhaustive-deps

  const emailVerification = () => {
    setLoading(true);
    API(
      API_REQUEST.post,
      `${EMAIL_VERIFICATION_API}?emailVerifyString=${pathName[1]}`,
      {}
    )
      .then(() => {
        setVerified(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        handleError(error);
        console.error(error);
      });
  };

  return (
    <div className="pre-login-section">
      <div className="pre-login-wrap">
        <div className="logo-wrap">
          <span>{BrandName}</span>
        </div>
        <Card className="shadow">
          <CardBody>
            <Form role="form">
              {loading ? (
                <div className="center-div email-verification-loader">
                  <Spinner
                    className="loader-color"
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="text-center mb-4">
                    {verified ? (
                      <FontAwesomeIcon
                        color={"#28a745"}
                        icon={faCircleCheck}
                        className="email-verification-check-icon"
                      />
                    ) : (
                      <FontAwesomeIcon
                        color={"#dc3545"}
                        icon={faCircleXmark}
                        className="email-verification-check-icon"
                      />
                    )}
                  </div>
                  <div className="text-center mb-4">
                    <h1>{verified ? "Verified!" : "Unverified!"}</h1>
                  </div>
                  <div className="text-center center-div">
                    Thank you for your support. we have successfully verified
                    your email.
                  </div>
                  <div className="btn-wrap">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => navigate("/login")}
                    >
                      Log in
                    </Button>
                  </div>
                </>
              )}
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
