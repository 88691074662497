import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { CHAT_NOTIFICATION_REGISTER_API, CHAT_NOTIFICATION_API, UPDATE_CHAT_NOTIFICATION_API } from "../../../../constants/Configs/index";
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"


export const registerDevice = (data) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  
  const promiseArray = [];

  promiseArray.push(API(API_REQUEST.post, CHAT_NOTIFICATION_REGISTER_API, data));

  Promise.all(promiseArray)
    .then((res) => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const notification = async(id) => {
  // dispatch(Actions.RestaurantAdmin.SetLoading, true)
  
  // const promiseArray = [];

  // promiseArray.push(API(API_REQUEST.get, `${CHAT_NOTIFICATION_API}/${id}`));

  // Promise.all(promiseArray)
  //   .then((res) => {
  //     handleSuccess(res)
  //     dispatch(Actions.RestaurantAdmin.SetLoading, false)
  //   })
  //   .catch((error) => {
  //     handleError(error);
  //     dispatch(Actions.RestaurantAdmin.SetLoading, false)
  //   })
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  return await API(API_REQUEST.get, `${CHAT_NOTIFICATION_API}/${id}`)
    .then((res) => {
      dispatch(Actions.RestaurantNotifications.SetNotitfication, res.data)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return res || []
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      return []
    })
}

export const editNotification = (data,id) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, UPDATE_CHAT_NOTIFICATION_API, data)
    .then(res => {
      handleSuccess(res)      
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}