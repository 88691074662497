import React, { useState, useEffect, useRef } from "react";
import { registerDevice } from "./../../utils/middleware/notification";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { initializeApp } from "firebase/app";
import mqtt from 'mqtt';
import {
  MessagePayload,
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import { firebaseConfig, vapidKey } from "./config";

initializeApp(firebaseConfig);

const publicVapidKey =
  "BLFSMjjyPNdWgdkvCP9EckVyPJYtwUYnnGs5HqTh3msgPVJYfw8YK-z_JgoL5kJQKXwUnNYsHoXnXR41bKZk4jo";
const safariPushId = "web.wepos.ai";
const safariWebsitePushURL = "https://wepos.ai";


const messaging = getMessaging();


const NotificationComponent = () => {
  const [showPrompt, setShowPrompt] = useState(true);
  const isSubscribedRef = useRef(false);

  const updatePromptVisibility = () => {
    const isNotificationDenied = JSON.parse(localStorage.getItem("isNotificationDenied"));

    if (!isNotificationDenied) {
      if (isSafari()) {
        if (window.safari && window.safari.pushNotification) {
          const permission = window.safari.pushNotification.permission(safariPushId);
          setShowPrompt(permission.permission === "default");
        }
      } else if ("Notification" in window) {
        setShowPrompt(Notification.permission === "default");
      }
    }
  };

  useEffect(() => {
    // Initial check on load
    updatePromptVisibility();

    // Re-check when page gains focus (e.g., after visiting site settings)
    window.addEventListener("focus", updatePromptVisibility);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("focus", updatePromptVisibility);
    };
  }, []);

  function requestPermission() {
    
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        registerServiceWorkerAndSubscribe()
      } else {
      }
    });
  }

  const isSafari = () => {
    return (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent) &&
      !/CriOS/.test(navigator.userAgent)
    );
  };

  const registerServiceWorkerAndSubscribe = async () => {
    const isNotificationDenied = JSON.parse(
      localStorage.getItem("isNotificationDenied")
    );
    if (!isNotificationDenied) {
      try {
        getToken(messaging, { vapidKey })
          .then((currentToken) => {
            if (currentToken) {
              const restaurant = JSON.parse(localStorage.getItem("userData"));
              const { id, restaurantId } = restaurant;
              const payload = {
                restaurantId: restaurantId,
                customerId: id,
                subscription: currentToken,
              };
              registerDevice(payload);
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token: ", err);
          });
      } catch (error) {
        console.error("Error in service worker or push subscription:", error);
      }
    }
  };


  const handleNotificationClick = () => {
    requestPermission();
    setShowPrompt(false);
  };

  const handleNotificationClose = () => {
    localStorage.setItem("isNotificationDenied", true); // Mark as denied
    setShowPrompt(false);
  };

  return (
    <>
      {showPrompt && (
        <div className="setting-configure-error-container">
          <span className="setting-configure-error-text">
            Stay in the know. Turn on desktop notifications. :{" "}
          </span>
          <Button
            color="primary"
            className="ml-2"
            size="sm"
            onClick={handleNotificationClick}
          >
            Turn On
          </Button>
          <span style={{ cursor: "pointer" }} onClick={handleNotificationClose}>
            <FontAwesomeIcon icon={faXmark} size="sm" />
          </span>
        </div>
      )}
    </>
  );
};

export default NotificationComponent;
