import { GET_ARTICALS_API } from "../../../../constants/Configs"
import {GET_CATEGORIES_API} from "../../../../constants/Configs"
import { CREATE_ARTICALS } from "../../../../constants/Configs"
import { GET_ARTICALS_BY_ID_API} from "../../../../constants/Configs"
import { UPDATE_ARTICALS } from "../../../../constants/Configs"
import { DELETE_ARTICALS } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import API from "../../../../services/api"
import { Actions } from "../../../../redux/actions"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { cmsDTO } from "../dtos/cms"
import { categoriesDTO } from "../dtos/categories"
import { getPageSize } from "../../../../services/middleware"

export const getCMS = (search, sortBy, orderBy, page = 1) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }
  API(API_REQUEST.get, GET_ARTICALS_API, { params })
    .then(res => {
      dispatch(Actions.SuperAdminCMS.SetCMS, cmsDTO(res))
      dispatch(Actions.SuperAdminCMS.SetTotalCount, res.data.count)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const getCategory = () => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.get, GET_CATEGORIES_API)
  .then(res => {
      dispatch(Actions.SuperAdminCMS.SetCMSCategories, categoriesDTO(res))
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const addCMS = (cmsData, search, activeSort, sortOrder, page, resetForm) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.post, CREATE_ARTICALS, cmsData)
    .then((res) => {
      handleSuccess(res)
      getCMS(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}


export const deleteCMS = (id, search, activeSort, sortOrder, page, setShowDeleteModal) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${DELETE_ARTICALS}/${id}`)
    .then(res => {
      handleSuccess(res)
      setShowDeleteModal(false)
      getCMS(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}

export const editCMS = (id, data, search, activeSort, sortOrder, page) => {
  dispatch(Actions.SuperAdmin.SetLoading, true)
  API(API_REQUEST.put, `${UPDATE_ARTICALS}/${id}`, data)
    .then(res => {
      handleSuccess(res)
      getCMS(search, activeSort, sortOrder, page)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.SuperAdmin.SetLoading, false)
    })
}