import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Table
} from "reactstrap";
import { useSelector } from 'react-redux'
import CreateEvent from "./../../components/EventCreateForm";
import EventTimeModal from "../../components/EventTimeModal";
import EventsTicketModal from "../../components/EventCreateForm/stepTwo.jsx";
import PublishEvent from "../../components/EventCreateForm/PublishEvent.jsx";
import moment from "moment";
import { deleteTimeSlots, getEventById } from "../../utils/middleware/events.js";
import { getTags } from '../../utils/middleware/marketings';

const EventCreate = () => {

  const [selectedPage, setSelectedPage] = useState("Build event page");
  const [showPage, setShowPage] = useState("");
  const [eventData, setEventData] = useState({});

  const changePage = (newMessage) => {
    setSelectedPage(newMessage);
    setShowPage(newMessage);
    getTags();
  };

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("/edit/")) {
      const parts = url.split("/");
      const id = parts[parts.length - 1];
      getEventById(id, setEventData); // Fetch the event data using the ID
    } else {
      console.log("The path does not contain 'edit'.");
    }
  }, []);

  const pages = {
    "Build event page": <CreateEvent event={eventData} setEventData={setEventData} changePage={changePage} />,
    "Manage dates and times": <ManageDatesAndTimes eventData={eventData} changePage={changePage} />,
    "Add tickets": <AddTickets eventData={eventData} changePage={changePage} />,
    "Publish": <PublishPage eventData={eventData} />,
  };

  return (
    <Container fluid className="event-schedule">
      <Row style={{ height: "80vh" }}>
        {/* Sidebar */}
        <Col md={3} className="sidebar p-3 d-flex flex-column sticky-top" style={{ background: "#e8c4cb", height: "100%" }}>
          <h4 className="text-center mb-3">Create Event</h4>
          <ListGroup>
            {Object.keys(pages).map((page) => (
              (eventData.eventType == 'recurring' || showPage == "Manage dates and times" || page !== "Manage dates and times") && (
                <ListGroupItem
                  key={page}
                  active={selectedPage === page}
                  style={{ backgroundColor: selectedPage === page ? "#ed3c5d" : "", cursor: "pointer" }}
                  onClick={() => setSelectedPage(page)}
                  action
                >
                  {page}
                </ListGroupItem>
              )))}
          </ListGroup>
        </Col>

        {/* Main Content */}
        <Col md={9} className="event-main-content overflow-auto" style={{ height: "100%" }}>
          {pages[selectedPage]}
        </Col>
      </Row>
    </Container>
  );
};

const ManageDatesAndTimes = ({ eventData, changePage }) => {
  const [showAddEvenModal, setShowAddEvenModal] = useState(false)

  const handleCloseDeleteModal = () => {
    setShowAddEvenModal(false)
  }
  const { events, eventSlots } = useSelector(state => state.restaurantAdminEvents);
  const groupedSlots = eventSlots.reduce((acc, slot) => {
    if (!acc[slot.slotDate]) {
      acc[slot.slotDate] = [];
    }
    acc[slot.slotDate].push(slot);
    return acc;
  }, {});

  const [visibleDate, setVisibleDate] = useState(null);

  // Toggle function
  const toggleTable = (date) => {
    setVisibleDate(visibleDate === date ? null : date);
  };

  const handleSlotDelete = (id) => {
    deleteTimeSlots(id);
  }

  const onNextAddTicket = () => {
    changePage("Add tickets");
  }

  const onBackAddTicket = () => {
    changePage("Build event page");
  }

  return (
    <div>
      <h3>Manage Dates and Times</h3>
      <p>Start by adding the dates and time slots for your recurring event.</p>
      <Button color="primary" className="mt-3" onClick={() => setShowAddEvenModal(true)}>+ Add a date</Button>
      <div className="timeslots mt-3">
        <div className="slot-container">
          {Object.keys(groupedSlots).map((date, index) => (
            <div key={index} className="slot-group" style={{ border: "1px solid #ed3c5d", padding: "5px", borderRadius: "8px", marginTop: "10px" }}>
              {/* Date Header - Clickable */}
              <div className="slot-header" onClick={() => toggleTable(date)} style={{ cursor: "pointer" }}>
                <h5 className="text-danger mb-0">
                  {moment(date).format("ddd")} {moment(date).format("DD")}
                </h5>
                <span className="text-dark">{moment(date).format("MMMM")}</span>
                <span className="ml-2 text-muted">{groupedSlots[date].length} time slots</span>
                {visibleDate === date && (
                  <div className="slot-actions">
                    <Button color="danger" size="sm" className="ml-2" onClick={() => handleSlotDelete(groupedSlots[date].map(slot => slot.eventId))}>Delete date</Button>
                  </div>
                )}
                {visibleDate !== date && (
                  <div className="slot-actions">
                    {groupedSlots[date].map((slot) => {
                      const startTime = moment(slot.startTime, "HH:mm").format("h:mm A");
                      return (
                        <Button color="danger" size="sm" className="ml-2">{startTime}</Button>
                      );

                    })}
                  </div>
                )}
              </div>

              {/* Slot Table (Toggled) */}
              {visibleDate === date && (
                <Table bordered striped className="mt-2">
                  <thead>
                    <tr>
                      <th>Time Slot</th>
                      <th>Duration</th>
                      <th>Visible Tickets</th>
                      <th>Ticket Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groupedSlots[date].map((slot) => {
                      const startTime = moment(slot.startTime, "HH:mm").format("h:mm A");
                      const endTime = moment(slot.endTime, "HH:mm").format("h:mm A");
                      const duration = moment(slot.endTime, "HH:mm").diff(moment(slot.startTime, "HH:mm"), "minutes");

                      return (
                        <tr key={slot.id}>
                          <td>{startTime}</td>
                          <td>{duration / 60}h</td>
                          <td>No tickets</td>
                          <td>No tickets</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}


            </div>
          ))}
        </div>
        <div style={{ textAlign: "right", marginTop: "10px" }}>
          <Button color='primary' type="button" onClick={onBackAddTicket} >
            Back
          </Button>
          <Button color='primary' type="button" onClick={onNextAddTicket} >
            Next
          </Button>
        </div>
      </div>
      {showAddEvenModal && <EventTimeModal open={showAddEvenModal} eventData={eventData} handleCloseModal={handleCloseDeleteModal} />}
    </div>
  );
};

const AddTickets = ({ eventData, changePage }) => (
  <div>
    <EventsTicketModal eventData={eventData} changePage={changePage} />
  </div>
);

const PublishPage = ({ eventData }) => (
  <div>
    <PublishEvent eventData={eventData} />
  </div>
);

export default EventCreate;