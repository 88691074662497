import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Card, CardBody, Col, Form, InputGroup, InputGroupText, Label, Modal, Row, FormGroup, Input, TabContent, TabPane, Nav, NavItem, NavLink,Collapse} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faTrash } from '@fortawesome/free-solid-svg-icons';
import "react-quill/dist/quill.snow.css";
import { addEvent, deleteTimeSlots, updateEvent } from '../../utils/middleware/events';
import { removeEmptyKeysFromObject, trimObjectValues } from '../../../../services/middleware';
import { toast } from "react-toastify";
import ImageUpload from "./ImageUpload";
import EventForm from "./EventForm";
import EventOverview from "./EventOverview";
import OverviewComponent from "./OverviewComponent";
import AgeRestriction from "./AgeRestriction";
import "./event.css"
export default function EventModal(props) {

  // Props
  const { open, event, handleCloseModal, search, activeSort, sortOrder, page, setIsTicketModalOpen, setSelectedEvent } = props
  //State
  const [activeTab, setActiveTab] = useState("1");
  const [eventTitle, setEventTitle] = useState('')
  const [eventSummary, setEventSummary] = useState('')
  const [eventLocation, setEventLocation] = useState('')
  const [eventStartDate, setEventStartDate] = useState('')
  const [eventStartTime, setEventStartTime] = useState('')
  const [eventEndTime, setEventEndTime] = useState('')
  const [eventEntryTime, setEventEntryTime] = useState('')
  const [selectedAge, setSelectedAge] = useState(null);
  const [eventOverView, setEventOverView] = useState([])
  const [parkingOption, setParkingOption] = useState('paid')
  const [isOnline, setIsOnline] = useState('no');
  const [isHours, setIsHours] = useState('no');
  const [eventType, setEventType] = useState('single');
  const [ageRestriction, setAgeRestriction] = useState('no');
  const [isParentNeed, setIsParentNeed] = useState('no');
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isContinue, setIsContinue] = useState(false); // Track isOpen state
  const [slotsTime, setSlotsTime] = useState(event.timeSlots ? event.timeSlots : [])
    
  function validateEventData(eventData) {
    let errors = [];

    if (!eventData.eventTitle) {
      errors.push("Event title is required");
    }
    if (!eventData.eventSummery) {
      errors.push("Event summery is required");
    }
    if (!eventData.eventDate) {
      errors.push("Event date is required");
    }
    if (!eventData.eventStartTime) {
      errors.push("Event start time is required");
    }
    if (!eventData.eventEndTime) {
      errors.push("Event end time is required");
    }
    if (eventData.isOnlineEvent === 'no' && !eventData.eventLocation) {
      errors.push("Event location is required");
    }
    if (!eventData.eventEntryTime) {
      errors.push("Event entry time is required");
    }

    if (errors.length > 0) {
      toast.info(errors[0], {
        position: "bottom-right",
        theme: "colored",
      });
      return false; // Prevent form submission
    } else {
      return true;
    }
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // Button Click Handlers
const handleSave = () => {
  setIsContinue(false); // Set isOpen to false for Save
  formik.handleSubmit();
};

const handleSaveAndContinue = () => {
  setIsContinue(true); // Set isOpen to true for Save and Continue
  formik.handleSubmit();
};

  useEffect(() => {
    if (event) {
      setEventTitle(event?.eventTitle);
      setSelectedFile(event?.eventMedias);
      setEventSummary(event?.eventSummery);
      setEventLocation(event?.eventLocation);
      setEventStartDate(event?.eventDate);
      setEventStartTime(event?.eventStartTime);
      setEventEndTime(event?.eventEndTime);
      setSelectedAge(event?.allowAges);
      setEventOverView(event?.eventOverview);
      setSelectedVideo(event?.eventMedia);
      setIsParentNeed(event?.isParentNeed);
      setAgeRestriction(event?.isAgeRestircted);
      setParkingOption(event?.isParkingAvailable);
      setIsOnline(event?.isOnlineEvent);
      setIsHours(event?.isHours);
      setEventType(event?.eventType);
      setEventEntryTime(event?.eventEntryTime);
    }
  }, [event]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: event?.id || null,
      eventMedia: event?.eventMedia || "",
      mediaType: event?.mediaType || "",
      eventTitle: event?.eventTitle || "",
      eventSummery: event?.eventSummery || "",
      eventName: event?.eventName || "",
      eventType: event?.eventType || "",
      eventDate: event?.eventDate || "",
      eventStartTime: event?.eventStartTime || "",
      eventEndTime: event?.eventEndTime || "",
      isOnlineEvent: event?.isOnlineEvent || "",
      eventLocation: event?.eventLocation || "",
      isReservation: event?.isReservation || "",
      eventOverview: event?.eventOverview || "",
      eventEntryTime: event?.eventEntryTime || "",
      isHours: event?.isHours || "",
      isAgeRestircted: event?.isAgeRestircted || "",
      isParentNeed: event?.isParentNeed || "",
      allowAges: event?.allowAges || "",
      isParkingAvailable: event?.isParkingAvailable || "",
      isPaidParking: event?.isPaidParking || "",
      parkingCharges: event?.parkingCharges || "",
      restaurantId: event?.restaurantId || "",
      eventstatus: event?.eventstatus || 'draft',
    },

    onSubmit: async (values) => {
      trimObjectValues(values, "media");
      values.message = values.message ? values.message.replace(/\n/g, " ") : "";
      const filterData = removeEmptyKeysFromObject(values);
      filterData.eventMedia = selectedFile;
      filterData.mediaType = selectedFile;
      filterData.eventTitle = eventTitle;
      filterData.eventSummery = eventSummary;
      filterData.eventName = eventTitle;
      filterData.eventType = eventType;
      filterData.eventDate = eventStartDate;
      filterData.eventStartTime = eventStartTime;
      filterData.eventEndTime = eventEndTime;
      filterData.isOnlineEvent = isOnline;
      filterData.eventLocation = eventLocation;
      filterData.isReservation = values.isReservation;
      filterData.eventOverview = eventOverView;
      filterData.isHours = isHours;
      filterData.eventEntryTime = eventEntryTime;
      filterData.isAgeRestircted = ageRestriction;
      filterData.isParentNeed = ageRestriction === "parent" ? "yes" : "no";
      filterData.allowAges = selectedAge;
      filterData.isParkingAvailable = parkingOption;
      filterData.isPaidParking = parkingOption === "paid" ? "yes" : "no";
      filterData.parkingCharges = values.parkingCharges || 0;
      filterData.eventstatus = values.eventstatus || 'draft';
      const restaurant = JSON.parse(localStorage.getItem("userData"));
      const userRestaurantId = restaurant.restaurantId;
      filterData.restaurantId = userRestaurantId || 0;
      if (event?.id && event?.id !== null && event?.id !== "") {
        if (validateEventData(filterData)) {
          updateEvent(
            event.id,
            filterData,
            search,
            activeSort,
            sortOrder,
            page,
            handleCloseModal
          );
        }
      } else {
        if (validateEventData(filterData)) {
          addEvent(
            filterData,
            search,
            activeSort,
            sortOrder,
            page,
            handleCloseModal,
            setIsTicketModalOpen,
            setSelectedEvent,
            isContinue
          );
        }
      }
    },
  });  

const removeSlot = (id) => {
  setSlotsTime((prevSlots) => prevSlots.filter((slot) => slot.id !== id));
  deleteTimeSlots(id)
};

  return (
    <Modal
      className="modal-dialog-centered edit-modal"
      isOpen={open}
      toggle={handleCloseModal}
      size='lg'
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Event
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}
        >
          <span aria-hidden={true}><FontAwesomeIcon icon={faXmark} size="sm" /></span>
        </button>
      </div>
      <div className="modal-body" >
        <Nav tabs className='border-0'>
          <NavItem className='mr-3'>
            <NavLink className={`custom-tab ${activeTab === "1" ? "active-tab" : ""}`} onClick={() => toggleTab("1")}>
              Event Details
            </NavLink>
          </NavItem>
          {event.eventType == "recurring" &&
          <NavItem>
            <NavLink className={`custom-tab ${activeTab === "2" ? "active-tab" : ""}`} onClick={() => toggleTab("2")}>
              Time Slots
            </NavLink>
          </NavItem>
          }
            
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
          <Card className="border-0 edit-card">
            <CardBody>
              <Row className="file-upload-preview">
                <Col md={12}>
                  <ImageUpload
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    selectedVideo={selectedVideo}
                    setSelectedVideo={setSelectedVideo}
                    event={event}
                  />
                </Col>
              </Row>
              <Row className="file-upload-preview">
                <Col md={12}>
                  <EventOverview
                    eventTitle={eventTitle}
                    setEventTitle={setEventTitle}
                    eventSummary={eventSummary}
                    setEventSummary={setEventSummary}
                  />
                </Col>
              </Row>
              <Row className="file-upload-preview">
                <Col md={12}>
                  <EventForm
                    eventLocation={eventLocation}
                    setEventLocation={setEventLocation}
                    eventType={eventType}
                    setEventType={setEventType}
                    eventStartDate={eventStartDate}
                    setEventStartDate={setEventStartDate}
                    eventStartTime={eventStartTime}
                    setEventStartTime={setEventStartTime}
                    eventEndTime={eventEndTime}
                    setEventEndTime={setEventEndTime}
                    isOnline={isOnline}
                    setIsOnline={setIsOnline}
                  />
                </Col>
              </Row>
              <Row className="file-upload-preview">
                <Col md={12}>
                  <OverviewComponent
                    eventOverView={eventOverView}
                    setEventOverView={setEventOverView}
                  />
                </Col>
              </Row>

              <Row className="file-upload-preview">
                <Col md={12}>
                  <AgeRestriction
                    isHours={isHours}
                    setIsHours={setIsHours}
                    ageRestriction={ageRestriction}
                    setAgeRestriction={setAgeRestriction}
                    parkingOption={parkingOption}
                    selectedAge={selectedAge}
                    setSelectedAge={setSelectedAge}
                    setParkingOption={setParkingOption}
                    isParentNeed={isParentNeed}
                    setIsParentNeed={setIsParentNeed}
                    eventEntryTime={eventEntryTime} 
                    setEventEntryTime={setEventEntryTime}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          </TabPane>
          <TabPane tabId="2">
            <Card className="border-0 edit-card">
            {slotsTime.length > 0 && (
              <div className="mt-4">
                {slotsTime.map((slot, index) => (
                  <Row key={index} className="align-items-center border rounded p-2 mb-2">
                    <Col>
                      <div>
                        <Label className="small text-muted">Start time *</Label>
                        <Input type="text" value={slot.startTime} readOnly />
                      </div>
                    </Col>
                    <Col>
                      <div>
                        <Label className="small text-muted">End time *</Label>
                        <Input type="text" value={slot.endTime} readOnly />
                        <div className="small text-muted">{slot.endTime} ({slot.durationInMinutes} min)</div>
                      </div>
                    </Col>
                    <Col xs="auto">
                      <Button color="danger" onClick={() => removeSlot(slot.id)}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </Col>
                  </Row>
                ))}
              </div>
            )}
            </Card>
          </TabPane>
        </TabContent>

      </div>
      <div className="modal-footer pt-2">
        <Button
          color='outline-primary'
          data-dismiss="modal"
          type="button"
          onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button color='primary' type="button" value='exit' disabled={!!Object.values(formik.errors)?.length} onClick={handleSave}>
          Submit
        </Button>
        {!event ? 
        <Button color='primary' type="button" value="continue" disabled={!!Object.values(formik.errors)?.length} onClick={handleSaveAndContinue}>
          Save and Continue
        </Button> : null }
      </div>
      <style>
        {`
          .custom-tab {
            background: #f8f9fa;
            color: #333;
            padding: 12px 15px;
            font-size: 16px;
            border: none;
            width: 100%;
            text-align: left;
            transition: 0.3s;
            cursor: pointer;
            border-radius: 6px;
          }
          .custom-tab:hover {
            background: #e9ecef;
          }
          .active-tab {
            background: #e63950;
            color: white !important;
          }
          .slotBTN{
            padding: 5px 10px;
            margin-top: 5px;
          }
        `}
      </style>
    </Modal>
  )
}
