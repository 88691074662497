import React, { useState, useEffect } from 'react'
import { useFormik } from 'formik';
import { Button, Card, CardBody, CardHeader, Badge, Label, Input, Container } from "reactstrap";
import Select from 'react-select';
import { selectDropdownStyle, eventTypeInputFields, eventCategoriesInputFields, eventsubCategoriesInputFields } from '../../../../constants/General';
import { useSelector } from 'react-redux';
import { updateEventStatus } from '../../utils/middleware/events';
import { Link, useNavigate, } from "react-router-dom";

export default function PublishEvent(props) {
  const { eventData } = props
  const navigate = useNavigate();
  const [selectType, setSelectType] = useState('once')
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { tags, customers } = useSelector(state => state.restaurantAdminMarketings)
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState(null);
  const [tag, setTags] = useState([]);



  const handleSave = () => {
    var tags = [];
    const payload = {
      typeId: formik?.values?.typeName,
      categorieId: formik?.values?.categoryName,
      subcategorieId: formik?.values?.subcategoryName,
      tags: tag.join(',')
    }
    formik?.values?.tags?.map(item => tags.push(item.value));
    updateEventStatus(eventData.id, 'draft', "", "", "", "", redirect, payload);

  }

  const redirect = () => {
    navigate('/admin/event/event')
  }


  const formik = useFormik({
    initialValues: {
      typeName: null,
      categoryName: null,
      subcategoryName: null,
    },
    onSubmit: (values) => {
    }
  });

  const addTag = (event) => {
    if (event.key === "Enter" && event.target.value.trim() !== "") {
      event.preventDefault();
      const newTag = event.target.value.trim();
      if (!tag.includes(newTag) && tag.length < 10) {
        setTags([...tag, newTag]);
      }
      event.target.value = "";
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tag.filter((tag) => tag !== tagToRemove));
  };

  const handlePreview = () => {
    if (!eventData?.id) {
      return;
    }

    const restaurant = JSON.parse(localStorage.getItem("userData"));
    const name = restaurant.name;
    const previewURL = `${window.location.origin}/event/${name}/${encodeURIComponent(eventData?.id)}`;

    // Open the URL in a new tab
    window.open(previewURL, "_blank");
  };

  const onSelectCategorie = (value) => {
    setSelectedCategory(value)
    const filteredSubcategoriesData = eventsubCategoriesInputFields.filter(sub => sub.parent_category === value);
    setFilteredSubcategories(filteredSubcategoriesData)
  }

  return (
    <Container fluid className="event-page" style={{ height: "100%" }}>
      <Card className="border-0 edit-card p-2">
        <CardHeader>
          <h3 className="page-title">Build Event Page</h3>
        </CardHeader>
        <CardBody style={{ height: "80%", overflow: 'auto' }}>
          <div>
            <h4>Event type and category</h4>
            <p>Your type and category help your event appear in more searches.</p>

            <div>
              <Label className='inputfield required-field'>
                Type
              </Label>
              <section className="mb-3 input-group-alternative">
                <Select
                  className="custom-select"
                  options={eventTypeInputFields}
                  value={eventTypeInputFields.find(option => option.value === formik.values.typeName) || null}
                  isSearchable={false}
                  styles={selectDropdownStyle}
                  onChange={(selectedOption) => {
                    formik.setFieldValue("typeName", selectedOption ? selectedOption.id : null);
                    setSelectType(selectedOption ? selectedOption.id : null)
                  }}
                />
                {formik.touched?.typeName && formik.errors.typeName && (
                  <span className="error-message input-group-alternative">
                    {formik.errors.typeName}
                  </span>
                )}
              </section>
              <div style={{ display: "flex", justifyContent: "space-between", gap: "4%" }}>
                <div style={{ width: "48%" }}>
                  <Label className="inputfield required-field">Category</Label>
                  <section className="mb-3 input-group-alternative">
                    <Select
                      className="custom-select"
                      options={eventCategoriesInputFields}
                      value={eventCategoriesInputFields.find(option => option.id === formik.values.categoryName) || null}
                      isSearchable={false}
                      styles={selectDropdownStyle}
                      onChange={(selectedOption) => {
                        const categoryValue = selectedOption ? selectedOption.id : null;
                        formik.setFieldValue("categoryName", categoryValue);
                        onSelectCategorie(categoryValue);
                        formik.setFieldValue("subcategoryName", null); // Reset subcategory when category changes
                      }}
                    />
                    {formik.touched?.categoryName && formik.errors.categoryName && (
                      <span className="error-message input-group-alternative">
                        {formik.errors.categoryName}
                      </span>
                    )}
                  </section>
                </div>

                {/* Subcategory Selection */}
                <div style={{ width: "48%" }}>
                  <Label className="inputfield required-field">Subcategory</Label>
                  <section className="mb-3 input-group-alternative">
                    <Select
                      className="custom-select"
                      options={filteredSubcategories}
                      value={filteredSubcategories.find(option => option.id === formik.values.subcategoryName) || null}
                      isSearchable={false}
                      styles={selectDropdownStyle}
                      isDisabled={!selectedCategory} // Disable if no category selected
                      onChange={(selectedOption) => {
                        formik.setFieldValue("subcategoryName", selectedOption ? selectedOption.id : null);
                      }}
                    />
                    {formik.touched?.subcategoryName && formik.errors.subcategoryName && (
                      <span className="error-message input-group-alternative">
                        {formik.errors.subcategoryName}
                      </span>
                    )}
                  </section>
                </div>
              </div>
            </div>
            <Label for="tags">Tags</Label>
            <p className="text-muted">Help people discover your event by adding tags related to your event’s theme, topic, vibe, location, and more.</p>
            <div className="border rounded p-2">
              {tag.map((tag, index) => (
                <Badge key={index} color="theme" className="m-1 text-dark" pill>
                  {tag} <span role="button" className="ml-1" onClick={() => removeTag(tag)}>✖</span>
                </Badge>
              ))}
              <Input
                type="text"
                placeholder="Add search keywords to your event"
                onKeyUp={addTag}
                className="border-0 shadow-none d-inline w-auto"
              />
              <p className="text-muted small">{tag.length}/10 tags</p>
            </div>
          </div>
        </CardBody>
      </Card>
      <div className="page-footer p-4">
        <Button
          color='outline-primary'
          type="button"
          onClick={() => window.history.back()}
        >
          Cancel
        </Button>
        <Button color='primary' type="button" value='exit' disabled={!!Object.values(formik.errors)?.length} onClick={handleSave}>
          Publish
        </Button>
        <Button color="primary" onClick={handlePreview} disabled={!eventData?.id}>
          Preview
        </Button>
      </div>
    </Container>
  )
}

