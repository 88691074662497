import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import moment from "moment-timezone";
import API from "../../services/api";
import { API_REQUEST } from "../../constants/General";
import { EVENT_API } from "../../constants/Configs";
import "./custom.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faMapMarkerAlt,
  faCalendarAlt,
  faBirthdayCake,
} from "@fortawesome/free-solid-svg-icons";



const HeroSection = ({ data }) => (
  <header className="org-wp-relative">
    <div
      className="org-wp-flow-hero-overlay"
      style={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1518609878373-06d740f60d8b?auto=format&fit=crop&q=80')",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    ></div>
    <div className="org-wp-flow-hero-content">
      <h1 className="org-wp-flow-hero-title">{data?.name}</h1>
      <p className="org-wp-flow-hero-subtitle" style={{ fontWeight: "600" }}>
        {data?.description}
      </p>
      <div className="flex justify-center gap-4 mt-4">
        <button className="org-wp-flow-button-primary org-wp-mx-auto" onClick={() => {
          window.open(`${data?.facebookLink}`, '_blank');
        }}>
          Facebook
        </button>
        <button className="org-wp-flow-button-primary org-wp-mx-auto" onClick={() => {
          window.open(`${data?.instagramLink}`, '_blank');
        }}>
          Instagram
        </button>
        <button className="org-wp-flow-button-primary org-wp-mx-auto" onClick={() => {
          window.open(`${data?.websiteLink}`, '_blank');
        }}>
          Checkout our Website
        </button>
      </div>
    </div>
  </header>
);

function formatTimeRange(timeRange) {
  const [startTime, endTime] = timeRange.split(' - ');

  // Convert times to 12-hour format with AM/PM
  const formattedStart = moment(startTime, 'HH:mm:ss').format('h:mm A');
  const formattedEnd = moment(endTime, 'HH:mm:ss').format('h:mm A');

  return `${formattedStart} - ${formattedEnd}`;
}

const EventCard = ({ eventData, data }) => (
  <section className="org-wp-max-w-7xl org-wp-mx-auto org-wp-px-4 sm:px-6 lg:px-8 org-wp-py-16">
    <div className="org-wp-flow-event-card">
      <div className=" md:flex">
        <div className="md:w-1/2">
          <img
            src={eventData?.eventMedia || 'https://wa-campaign.s3.eu-west-2.amazonaws.com/image/side-view-cheesecake-with-chocolate-syrup-strawberry-mint-whipped-cream-cherry-black-tea.jpg'}
            alt={eventData?.eventTitle}
            className="org-wp-h-full w-full object-cover"
          />
        </div>
        <div className="org-wp-flow-event-content">
          <h2 className="org-wp-flow-event-title">{eventData?.eventTitle}</h2>
          <div className="org-wp-space-y-4 org-wp-mb-8">
            <EventDetail icon={faCalendarAlt} text={moment(eventData?.eventDate).format('dddd, MMMM D, YYYY')} />
            <EventDetail icon={faClock} text={`${eventData?.eventDate} - ${eventData?.eventDate} GMT`} />
            <EventDetail icon={faMapMarkerAlt} text={eventData?.eventLocation} />
          </div>
          <p className="org-wp-text-gray-600 org-wp-mb-8 wi" style={{ fontWeight: '600' }}>
            {eventData?.eventSummery}
          </p>
          <div className="org-wp-space-y-4">
            <Button className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-blue-700 transition-colors duration-200" color="primary" onClick={() => {
              window.open(`${window.location.origin}/event/${data?.name}/${eventData?.id}`, '_blank');
            }}>Register Now</Button>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const EventDetail = ({ icon, text }) => (
  <div className="org-wp-flow-event-detail">
    <FontAwesomeIcon icon={icon} className="org-wp-flow-event-icon" />
    <span>{text}</span>
  </div>
);

const BenefitsSection = ({ data }) => (
  <section className="org-wp-flow-benefits-section org-wp-max-w-7xl org-wp-mx-auto org-wp-px-4 sm:org-wp-px-6 lg:org-wp-px-8">
    <h2 className="org-wp-text-3xl org-wp-font-bold org-wp-text-center org-wp-text-blue-900 org-wp-mb-12">
      Why Choose {data?.name}
    </h2>
    <div className="org-wp-grid md:grid-cols-3 org-wp-gap-8">
      <BenefitCard title="Expert Guidance" text="Learn from certified professionals with years of experience." />
      <BenefitCard title="Intimate Settings" text="Small group sizes ensure personalized attention." />
      <BenefitCard title="Transformative Results" text="Walk away with practical tools you can use daily." />
    </div>
  </section>
);

const BenefitCard = ({ title, text }) => (
  <div className="org-wp-flow-benefit-card">
    <h3 className="org-wp-text-xl org-wp-font-semibold org-wp-text-blue-900 org-wp-mb-3">{title}</h3>
    <p className="org-wp-text-gray-600">{text}</p>
  </div>
);

function formatPhoneNumber(number) {
  // Convert to string and remove non-digit characters
  let cleaned = number.toString().replace(/\D/g, '');

  // Remove leading zero if present
  if (cleaned.startsWith('0')) {
    cleaned = cleaned.slice(1);
  }

  // Ensure it's a 10-digit number
  if (cleaned.length !== 10) {
    throw new Error("Invalid phone number. Must be 10 digits.");
  }

  // Format the number
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
}

function EventNotAvailable() {
  return (
    <div className="bg-gray-100 min-h-screen text-center ">
      <h1 className="text-3xl font-bold text-red-600">
        Organization is not available
      </h1>
      <p className="text-gray-600 mt-2">
        Please check back later or visit our homepage.
      </p>
      <button
        onClick={() => (window.location.href = "/")}
        className="mt-4 px-6 py-3 text-white rounded-lg btn-primary"
      >
        Go to Home
      </button>
    </div>
  );
}

const Footer = ({ data }) => (
  <footer className="org-wp-flow-footer">
    <div className="org-wp-flow-footer-content">
      <div className="org-wp-grid md:grid-cols-2 org-wp-gap-8">
        <div>
          <h3 className="org-wp-text-2xl org-wp-font-bold org-wp-mb-4 text-white">{data?.name}</h3>
          <p className="org-wp-text-blue-200 org-wp-max-w-md text-white">
            Transformative experiences that combine mindfulness, movement, and
            modern well-being practices.
          </p>
        </div>
        <div className="md:text-right">
          <h4 className="org-wp-font-semibold org-wp-mb-4 text-white">Connect With Us</h4>
          <p className="text-white">{data?.email}</p>
          <p className="text-white">{formatPhoneNumber(data?.phone)}</p>
        </div>
      </div>
      <div className="org-wp-border-t org-wp-border-blue-800 org-wp-mt-8 org-wp-pt-8 org-wp-text-center org-wp-text-blue-200 text-white">
        <p className="text-white">© 2024 {data.name}. All rights reserved.</p>
      </div>
    </div>
  </footer>
);

const Organization = () => {
  const [organization, setOrganization] = useState([]);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [liveEvent, setLiveEvent] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (ticket) => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        setLoading(true); // Set loading before making the request
        const parts = new URL(window.location.href).pathname.split("/");
        const organizationName = decodeURIComponent(parts[2]);
        const _c = parts[3] || null;

        const [orgResponse, eventResponse] = await Promise.all([
          API(API_REQUEST.get, `${EVENT_API}/organization/${_c}`),
          API(API_REQUEST.get, `${EVENT_API}/public?restaurantId=${_c}`)
        ]);
        setOrganization(orgResponse.data.data);
        setUpcomingEvent(eventResponse.data.data.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, []);


  if (loading) {
    return <div className="text-center py-10">Loading event details...</div>;
  }

  if (error) {
    return <div className="text-center text-red-600 py-10">Error: {error}</div>;
  }

  if (!organization || Object.keys(organization).length === 0) {
    return <EventNotAvailable />;
  }

  return (
    <div>
      <HeroSection data={organization} />
      {upcomingEvent.length > 0 ? (
        upcomingEvent.map((event, index) => <EventCard key={index} data={organization} eventData={event} />)
      ) : (
        <div className="text-center py-10">No upcoming events found.</div>
      )}
      <BenefitsSection data={organization} />
      <Footer data={organization} />
    </div>
  );


};

export default Organization;