import React, { useEffect, useRef, useCallback, useState } from 'react';
import { Form, Card, Input, Button, ListGroup, ListGroupItem, Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faFaceSmile, faEllipsisVertical, faUser, faArrowLeft, faLink } from '@fortawesome/free-solid-svg-icons';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import './chat.css';
import FileUpload from './upload';
import moment from 'moment';
import Loader from "../../../../components/Loader";
import NotificationComponent from '../../components/Notification';
import { getRestaurant } from '../../utils/middleware/restaurant';
import { getLocalStorageUserData } from '../../../../utils/session';

const URL = 'wss://ymheyjjw2e.execute-api.eu-west-2.amazonaws.com/production/';
let toName = "";
let userType = "";
let base64Token = "";

export default function Chat() {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false); // State to control emoji picker visibility
  const socket = useRef(null);

  const [users, setusers] = useState([]);
  const [isName, setIsName] = useState(null);
  const [isCustName, setIsCustName] = useState(null);
  const [searchUser, setSearchUser] = useState([]);
  const [recordUser, setRecordUser] = useState([]);
  const [searchUserInput, setSearchUserInput] = useState("");
  const messagesEndRef = useRef(null);
  const [reactionMenuPosition, setReactionMenuPosition] = useState({ top: 0, left: 0 });

  const [selectedMessageId, setSelectedMessageId] = useState("");
  const [showEmojiPickerMsg, setShowEmojiPickerMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const emojiPickerRef = useRef(null);
  const emojiPickerMsgRef = useRef(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isChatWindowVisible, setIsChatWindowVisible] = useState(false);
  const [loadingMain, setLoadingMain] = useState(true);
  const [activeUser, setActiveUser] = useState(null);
  const heartbeatInterval = useRef(null);  // Keep track of the heartbeat interval

  const onSocketOpen = () => {
    const restaurant = JSON.parse(localStorage.getItem('userData'));
    const tokenString = JSON.stringify({ id: restaurant.id, restaurantId: restaurant.restaurantId, phone: restaurant.phone });
    base64Token = btoa(tokenString);
    const restaurantId = restaurant.restaurantId
    const name = restaurant.name;
    const customerId = restaurant.id
    const phone = restaurant.phone;
    socket.current?.send(JSON.stringify({ action: 'setName', name, customerId, restaurantId, phone }));
  };

  const onConnect = useCallback(() => {
    if (socket.current?.readyState !== WebSocket.OPEN) {
      socket.current = new WebSocket(URL);
      socket.current.addEventListener('open', JoinChat);
      socket.current.addEventListener('message', (event) => {
        onSocketMessage(event.data);
      });
      heartbeatInterval.current = setInterval(() => {
        if (socket.current?.readyState === WebSocket.OPEN) {
          socket.current.send(JSON.stringify({ action: 'keepAlive' }));
        }
      }, 30000); // Send a ping every 30 seconds
    }
  }, []);

  const formatDate = (dateString) => {
    return moment(dateString).format('DD-MM h:mm A');
  };

  const isToday = (date) => moment(date).isSame(moment(), 'day');

  // Group messages by date
  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = moment(message.sentAt).startOf('day').format('YYYY-MM-DD');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const handleDropdownToggle = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  useEffect(() => {
    onConnect();
    return () => {
      socket.current?.close();
      clearInterval(heartbeatInterval.current); // Clear interval on cleanup
    };
  }, [onConnect]);

  const onSocketMessage = (dataStr) => {
    const data = JSON.parse(dataStr);
    setLoadingMain(false)
    if (data.members) {
      const restaurant = JSON.parse(localStorage.getItem('userData'));
      const result = data.members.filter(member => member.name != restaurant.name).map(member => {
        let unreadCount = 0;
        if (data.messageUnreadCount && data.messageUnreadCount.length > 0) {
          unreadCount = data.messageUnreadCount.find(item => item.phone === member.name);
        }
        if (member.name == toName.name) {
          unreadCount = 0;
        }
        return {
          name: member.name,
          customerName: member.customerName,
          type: member.type,
          readcount: unreadCount ? unreadCount.unread_messages : 0
        };
      });
      setusers(result);
      setRecordUser(result)
    } else if (data.history) {
      if (typeof data.history === 'string') {
        const mesgHistory = JSON.parse(data.history);

        if (data.phone == toName) {
          onSendUpdateReadCount(toName);
          setLoading(false)
          setMessages(mesgHistory.reverse());
        }

      } else {
        const mesgHistory = data.history;
        if (data.phone == toName) {
          onSendUpdateReadCount(toName);
          setLoading(false)
          setMessages(mesgHistory);
        }
      }


    } else if (data.publicMessage) {
      handleRecivedMessage(data.publicMessage);
    } else if (data.privateMessage) {
      const messageData = data;
      handleRecivedMessage(messageData);
    } else if (data.systemMessage) {
      handleRecivedMessage(data.systemMessage);
    }
  }

  const onSelectUser = (e) => {
    setIsName(e.name);
    userType = e.type;
    setIsCustName(e.customerName);
    toName = e.name;
    let filterUser = [];
    let hasUnreadMessages = false;
    users.map(r => {
      if (r.name == e.name) {
        r.readcount = 0;
      }
      getRestaurant(getLocalStorageUserData()?.restaurantId)
      filterUser.push(r);
    });
    setusers(filterUser);
    onSendUpdateReadCount(e.name);
    onSendGetHistory(e.name);
    setMessages([]);

  }

  const JoinChat = () => {
    onSocketOpen();
  }

  const handleSendMessage = (inmessage) => {
    if (message.trim()) {
      setMessages([...messages, { id: messages.length, message: message, senderType: 2, sentAt: new Date().toISOString() }]);
      onSendPrivateMessage(message, isName);
      setMessage('');
    }
  };

  const handleRecivedMessage = (inmessage) => {
    if (inmessage && inmessage.name && inmessage.name != null) {
      if (inmessage.name != toName) {
        setMessages([...messages, { id: messages.length, message: inmessage.privateMessage, senderType: 1, sentAt: new Date().toISOString() }]);
      }
    }
  };

  const onSendPrivateMessage = useCallback((message, to) => {
    const restaurant = JSON.parse(localStorage.getItem('userData'));
    const restaurantId = restaurant.restaurantId
    // type : r
    socket.current?.send(JSON.stringify({
      action: userType === 'widget' ? 'sendPrivateWidget' : 'sendPrivate',
      message,
      to,
      token: base64Token,
      restaurantId: restaurantId,
      senderType: 2,
    }));
  }, []);

  const onSendUpdateReadCount = useCallback((phone) => {
    const restaurant = JSON.parse(localStorage.getItem('userData'));
    socket.current?.send(JSON.stringify({
      action: 'updateReadStauts',
      phone,
      restaurantId: restaurant.restaurantId,
    }));
  }, []);

  const onSendGetHistory = useCallback((phone) => {
    const restaurant = JSON.parse(localStorage.getItem('userData'));
    socket.current?.send(JSON.stringify({
      action: 'getHistory',
      phone,
      restaurantId: restaurant.restaurantId,
      offset: 0
    }));
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const filterUser = () => {
    // if(searchUserInput != "") {
    //   const filtered = users.filter(user =>
    //     user.customerName.toLowerCase().includes(searchUserInput.toLowerCase()) || user.name.toLowerCase().includes(searchUserInput.toLowerCase())
    //   );
    //   if(filtered.length > 0 && searchUserInput != ''){
    //     setusers(filtered)
    //   }else{
    //     setusers(recordUser)
    //   }
    // }else{
    //   setusers(recordUser)
    // }
    const filterData = recordUser
    const filtered = filterData.filter(user =>
      user.customerName.toLowerCase().includes(searchUserInput.toLowerCase()) || user.name.toLowerCase().includes(searchUserInput.toLowerCase())
    );
    if (searchUserInput) {
      setusers(filtered)
    } else {
      setusers(recordUser)
    }
  }

  const testemoji = () => {
    setShowEmojiPicker(false);
  }

  const handleEmojiClick = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.native);
    // setShowEmojiPicker(false);
  };

  const handleClickOutside = (event) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
      setShowEmojiPicker(false);
    }
    if (emojiPickerMsgRef.current && !emojiPickerMsgRef.current.contains(event.target)) {
      setShowEmojiPickerMsg(false);
    }

  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Clean up event listener on unmount
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);
  useEffect(() => {
    if (window.innerWidth >= 765) {
      setIsChatWindowVisible(true);
    }
  }, []);


  const handleMouseDown = (id, e) => {
    setSelectedMessageId(id);
    setShowEmojiPickerMsg(true);
  };


  const handleEmojiSelect = (emoji) => {
    if (selectedMessageId) {
      setShowEmojiPickerMsg(false);
      handleReact(selectedMessageId, emoji.native);
    }
  };

  const handleReact = (id, emoji) => {
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg.id === id ? { ...msg, reaction: emoji } : msg
      )
    );
  };

  const toggleChatWindow = () => {
    setIsChatWindowVisible(!isChatWindowVisible);
  };


  useEffect(() => {
    const handleResize = () => {
      if (showEmojiPickerMsg) {
        // Recalculate position if the window is resized
        handleMouseDown(selectedMessageId, { clientX: reactionMenuPosition.left, clientY: reactionMenuPosition.top });
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showEmojiPickerMsg, reactionMenuPosition, selectedMessageId]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMessage(file.name); // Set the message input value to the file name
    }
  };

  return (
    <>
      {loadingMain && <Loader />}
      <Container fluid className="p-3" style={{ height: `calc(100vh - 190px)` }}>
        <Row className='h-100'>
          <Col md={4} className={`p-0 h-100 ${isChatWindowVisible ? 'd-none d-md-block' : 'd-md-block'}`}>
            <div className="chat-window bg-white rounded p-3 mb-3 h-100">
              <Input
                type="text"
                name="message"
                id="messageInput"
                placeholder="Type to search..."
                value={searchUserInput}
                onChange={(e) => { setSearchUserInput(e.target.value) }}
                onKeyUp={filterUser}
                className="w-100"
              />
              <h3 className='mt-2'>Chat List</h3>
              <ListGroup>
                {searchUser.length === 0 ? users.map((user) => (
                  <ListGroupItem
                    key={user.name}
                    onClick={() => {
                      onSelectUser(user);
                      setActiveUser(user);
                      setLoading(true)
                      if (window.innerWidth <= 765) {
                        toggleChatWindow();
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: user === activeUser ? '#212121' : 'transparent',
                      color: user === activeUser ? 'white' : '#212121',
                    }}
                    className="d-flex align-items-center"
                  >
                    <FontAwesomeIcon icon={faUser} className="user-profile mr-3" />
                    <div className='w-100'>
                      <div className='d-flex justify-content-between'>
                        <p className='m-0 p-0 d-flex' style={{ color: user === activeUser ? 'white' : '#212121' }}>{user.customerName} </p>
                        {user.readcount !== 0 ? <span className='chat-message-count'>{user.readcount}</span> : ''}
                      </div>

                      <small>{user.name}</small>
                    </div>
                  </ListGroupItem>
                )) : searchUser.map((user) => (
                  <ListGroupItem
                    key={user.name}
                    onClick={() => {
                      onSelectUser(user);
                      if (window.innerWidth <= 765) {
                        toggleChatWindow();
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FontAwesomeIcon icon={faUser} className="user-profile mr-3" />
                    <div>
                      <p className='m-0 p-0'>{user.customerName}</p>
                      <small>{user.name}</small>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>
          <Col md={8} className={`p-0 h-100 ${isChatWindowVisible ? 'd-block' : 'd-none d-md-block'}`}>
            <Card className="chat-window border rounded mb-3 h-100">
              {loading &&
                <div className="loader sub-loader">
                  <Spinner
                    className="loader-color"
                    style={{
                      height: "3rem",
                      width: "3rem",
                    }}
                  />
                </div>
              }
              {isChatWindowVisible && isName ? (
                <>
                  <div className='chat-header'>
                    <FontAwesomeIcon icon={faUser} className="user-profile mr-3" />
                    <h5>{isCustName}</h5>
                    <Button color="link" onClick={toggleChatWindow} className="btn-back d-sm-block d-md-none">
                      <FontAwesomeIcon icon={faArrowLeft} /> Back to Users
                    </Button>
                  </div>
                  <div className="chat-messages">
                    {Object.keys(groupMessagesByDate(messages)).map((dateKey) => {
                      const isTodayFlag = isToday(moment(dateKey).toDate());
                      const dateLabel = isTodayFlag ? 'Today' : moment(dateKey).format('DD-MM-YYYY');

                      return (
                        <div key={dateKey}>
                          <div className="date-separator"><span>{dateLabel}</span></div>
                          {groupMessagesByDate(messages)[dateKey].map((msg) => (
                            <div className={`chat-message ${msg.senderType === 2 ? 'right' : 'left'}`} key={msg.id}>
                              <div className={`message-content ${msg.senderType === 2 ? 'message-right' : 'message-left'}`}>
                                <span className={`time ${msg.senderType === 2 ? 'time-right' : 'time-left'}`}>
                                  {formatDate(msg.sentAt)}
                                </span>
                                <div className={`d-flex justify-content-center align-items-start ${msg.senderType === 2 ? 'flex-row-reverse right-drop' : 'flex-row left-drop'}`}>
                                  <p className='w-100'>{msg.message}</p>
                                  <Dropdown isOpen={openDropdownId === msg.id} toggle={() => handleDropdownToggle(msg.id)} className="msg-dropdown">
                                    <DropdownToggle>
                                      <FontAwesomeIcon icon={faEllipsisVertical} className="msg-option" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem onClick={(e) => handleMouseDown(msg.id, e)}>React to message</DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                  {showEmojiPickerMsg && selectedMessageId === msg.id && (
                                    <div
                                      ref={emojiPickerMsgRef}
                                    >
                                      <Picker data={data} onEmojiSelect={handleEmojiSelect} />
                                    </div>
                                  )}
                                </div>
                                {msg.reaction && <span className={`chat-emoji ${msg.senderType === 2 ? 'time-right' : 'time-left'}`}>{msg.reaction}</span>}
                              </div>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                    <div ref={messagesEndRef} />
                  </div>
                  <div className="element mt-0">
                    <Form inline onSubmit={(e) => e.preventDefault()}>
                      <Row>
                        <Col sm={11} xs={10}>
                          <Input
                            type="text"
                            name="message"
                            id="messageInput"
                            placeholder="Type a message"
                            value={message}
                            onClick={testemoji}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                            className="w-100 message-input"
                          />
                          <FontAwesomeIcon
                            className='emoji-pikker'
                            icon={faFaceSmile}
                            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                          />
                          {/* <FormGroup>
                          <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            onChange={handleFileChange} // Handle file selection
                            style={{ display: 'none' }} // Hide the file input
                          />
                          <FontAwesomeIcon
                            className='file-pikker'
                            icon={faLink}
                            onClick={() => document.getElementById('exampleFile').click()}
                          />
                        </FormGroup> */}
                          {showEmojiPicker && (
                            <div className="chat-emoji-list" ref={emojiPickerRef}>
                              <Picker data={data} onEmojiSelect={handleEmojiClick} />
                            </div>
                          )}
                        </Col>
                        <Col xs={1} className='fu-btn'>
                          <Button color="primary" onClick={handleSendMessage}>
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </>
              ) : (
                <div className='d-flex justify-content-center align-items-center h-100'>
                  Start Messaging
                </div>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
