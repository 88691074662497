import React, { useState, useEffect } from "react";
import "./event.css";
import TicketModal from "./TicketModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faParking,
  faMapMarkerAlt,
  faCalendarAlt,
  faBirthdayCake,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import API from "../../services/api";
import { API_REQUEST } from "../../constants/General";
import { EVENT_API } from "../../constants/Configs";

const Event = () => {
  const [event, setEvent] = useState([]);
  const [organization, setOrganization] = useState([]);
  const [upcomingEvent, setUpcomingEvent] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAPICalled, setIsAPICalled] = useState(false);

  const openModal = (ticket) => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const groupSlotsByDate = (slots) => {
    return slots.reduce((acc, slot) => {
      if (!acc[slot.slotDate]) {
        acc[slot.slotDate] = [];
      }
      acc[slot.slotDate].push(slot);
      return acc;
    }, {});
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const options = { weekday: "short", day: "2-digit", month: "long" };
    return date.toLocaleDateString("en-US", options);
  };


  const [selectedSlot, setSelectedSlot] = useState(null);
  const [groupedSlots, setGroupedSlots] = useState([]);


  const handleSelect = (slotId) => {
    setSelectedSlot(slotId);
  };


  // useEffect(, []);

  const fetchEventData = async () => {
    setIsAPICalled(true);
    try {
      const parts = new URL(window.location.href).pathname.split("/");
      const organizationName = decodeURIComponent(parts[2]);
      const _c = parts[3] || null;
      const response = await API(API_REQUEST.get, `${EVENT_API}/${_c}`);
      setEvent(response.data.data.eventData); // Assuming the API response has a `data` field
      fetchOrganizationData(response.data.data.eventData);
      setGroupedSlots(groupSlotsByDate(response.data.data.eventData.timeSlots))
      setUpcomingEvent(response.data.data.upcomingEvents); // Assuming the API response has a `data` field
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  if (isAPICalled === false) {
    fetchEventData();
  }

  const fetchOrganizationData = async (event) => {
    try {
      if (event) {
        const response = await API(API_REQUEST.get, `${EVENT_API}/organization/${event.restaurantId}`);
        setOrganization(response.data.data); // Assuming the API response has a `data` field
      }

    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };



  const EventDetails = ({ eventDate, eventStartTime, eventEndTime }) => {
    const userTimeZone = moment.tz.guess();

    // Format start and end times in one line
    const formattedStart = moment.tz(`${eventDate} ${eventStartTime}`, "YYYY-MM-DD HH:mm", userTimeZone).format("MMMM D, YYYY, h:mm A");
    const formattedEnd = moment.tz(`${eventDate} ${eventEndTime}`, "YYYY-MM-DD HH:mm", userTimeZone).format("h:mm A");
    return (
      <p className="text-gray-700 font-semibold" >
        {formattedStart} - {formattedEnd}
      </p>
    );
  };

  const EventStartEndDetails = ({ startDate, endDate, startTime, endTime }) => {
    const userTimeZone = moment.tz.guess();

    // Format start and end times in one line
    const formattedStart = moment.tz(`${startDate} ${startTime}`, "YYYY-MM-DD HH:mm", userTimeZone).format("MMMM D, YYYY, h:mm A");
    const formattedEnd = moment.tz(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm", userTimeZone).format("MMMM D, YYYY, h:mm A");
    return (
      <p className="text-gray-700 font-semibold" >
        {formattedStart} to {formattedEnd}
      </p>
    );
  };

  function EventNotAvailable({ event }) {
    return (
      <div className="event-bg-gray-100 event-min-h-screen event-text-center ">
        <h1 className="event-text-3xl event-font-bold event-text-red-600">
          {error || 'Event is not available'}
        </h1>
        <p className="event-text-gray-600 mt-2">
          Please check back later or visit our homepage.
        </p>
        <button
          onClick={() => (window.location.href = "/")}
          className="event-mt-4 event-px-6 event-py-3 event-text-white event-rounded-lg btn-primary"
        >
          Go to Home
        </button>
      </div>
    );
  }

  const increaseQuantity = (id) => {
    setSelectedTickets((prev) => {
      const updatedQuantity = (prev[id] || 0) + 1;
      const grandTotal = prev.total && prev.total !== undefined ? prev.total : 0;
      return {
        ...prev,
        [id]: updatedQuantity,
        total:
          parseFloat(grandTotal) +
          parseFloat(
            event.tickets.find((ticket) => ticket.id === id).ticketPrice
          ),
      };
    });
  };

  const decreaseQuantity = (id) => {
    setSelectedTickets((prev) => {
      if (!prev[id] || prev[id] === 0) return prev;
      const updatedQuantity = prev[id] - 1;
      const updated = {
        ...prev,
        [id]: updatedQuantity,
        total:
          prev.total -
          event.tickets.find((ticket) => ticket.id === id).ticketPrice,
      };
      if (updated[id] === 0) delete updated[id];
      return updated;
    });
  };

  const getStartEndDates = (data) => {
    let startDate = "";
    let startTime = "";
    let endDate = "";
    let endTime = "";
    
    data.forEach(e => {
        // Set start date and time
        if (startDate === "" || e.slotDate < startDate || (e.slotDate === startDate && e.startTime < startTime)) {
            startDate = e.slotDate;
            startTime = e.startTime;
        }
    
        // Set end date and time
        if (endDate === "" || e.slotDate > endDate || (e.slotDate === endDate && e.endTime > endTime)) {
            endDate = e.slotDate;
            endTime = e.endTime;
        }
    });
    return { startDate, startTime, endDate, endTime}
  }

  const getTotalPrice = () => isNaN(selectedTickets.total) ? 0 : selectedTickets.total;

  if (loading) {
    return <div className="text-center py-10">Loading event details...</div>;
  }

  if (error) {
    return (
      <>
        <EventNotAvailable error={error} />
      </>
    );
  }

  if (!event) {
    return (
      <>
        <EventNotAvailable />
      </>
    );
  }

  return (
    <div className="event-bg-gray-50 event-min-h-screen">
      <div className="event-max-w-7xl event-mx-auto event-bg-white event-shadow-lg event-rounded-lg event-overflow-hidden event-mt-10">
        {/* Header Image */}
        <img
          src={
            event.eventMedia ||
            "https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F936453403%2F1874466574633%2F1%2Foriginal.20250115-161341?crop=focalpoint&fit=crop&w=480&auto=format%2Ccompress&q=75&sharp=10&fp-x=0.5&fp-y=0.5&s=f61f73b30aaf52eddd80105b0f70b685"
          }
          alt="Event Image"
          className="event-w-full event-object-cover event-h-96"
        />

        {/* Main Content */}
        <div className="event-p-6 md:event-flex md:event-space-x-6">
          {/* Left Section */}
          <div className="event-flex-1">
            <h1 className="event-text-4xl event-font-bold event-text-gray-900">
              {event.eventTitle}
            </h1>
            <p className="event-text-gray-600 event-mt-1">{event.eventType == "single" ? event.eventDate : ""}</p>

            <div className="event-mt-4">
              <p className="event-text-xl event-font-semibold">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size="lg"
                  className="event-text-gray-200"
                />{" "}
                Date and Time
              </p>
              {event.eventType === "single" ?
                <EventDetails {...event} />
                : <p className="event-text-gray-700 event-font-semibold">
                  <EventStartEndDetails {...getStartEndDates(event?.timeSlots)} />
                </p>
              }
            </div>
            <div className="event-mt-4">
              <p className="event-text-xl event-font-semibold">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="lg"
                  className="event-text-gray-200"
                />{" "}
                Location
              </p>
              <p className="event-text-gray-700 event-font-semibold">
                {event.eventLocation}
              </p>
              {/* Google Maps Embed */}
              <div className="event-mt-4">
                <iframe
                  title="Event Location"
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAOVYRIgupAurZup5y1PRh8Ismb1A3lLao&q=${encodeURIComponent(
                    event.eventLocation
                  )}`}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="event-mt-4">
              <p className="event-text-xl event-font-semibold">Refund Policy</p>
              <p className="event-text-gray-700 event-font-semibold">
                Contact the organizer to request a refund.
                <br />
                Wepos's fee is nonrefundable.
              </p>
            </div>

            <div className="event-mt-6">
              <p className="event-text-xl event-font-semibold">About this Event</p>
              <p className="event-text-gray-700 event-font-semibold">
                <div
                  dangerouslySetInnerHTML={{ __html: event.eventOverview }}
                />
              </p>
              <FontAwesomeIcon
                icon={faBirthdayCake}
                size="lg"
                className="event-text-gray-200"
              />{" "}
              <span className="event-font-semibold">
                {event.isAgeRestircted === "yes"
                  ? "Ages 18+"
                  : event.isParentNeed === "yes"
                    ? "Parents/Guardian Needed"
                    : "All ages welcome"}
              </span>
              {" | "}
              <FontAwesomeIcon
                icon={faParking}
                size="lg"
                className="event-text-gray-200"
              />{" "}
              <span className="event-font-semibold">
                {event.isParkingAvailable === "yes"
                  ? event.isPaidParking === "yes"
                    ? "Paid venue parking available"
                    : "Free parking available"
                  : "No parking available"}
              </span>
            </div>

            <div className="event-mt-6">
              <p className="event-text-xl event-font-semibold">Tags</p>
              <div className="event-flex event-flex-wrap event-gap-2 event-mt-2">
                {event?.tags?.split(',').map((tag) => {
                  return (
                    <>
                      <span className="event-bg-gray-200 event-text-gray-600 event-px-3 event-py-1 event-rounded-lg event-text-sm">
                        {tag}
                      </span>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="event-mt-6">
              <p className="event-text-xl event-font-semibold">Organized by</p>
              <div className="event-flex event-items-center event-space-x-4 event-mt-3">
                <div className="event-bg-gray-300 event-rounded-full event-h-10 event-w-10">
                  <img
                    src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F633612939%2F1874466574633%2F1%2Foriginal.20231102-140524?w=512&auto=format%2Ccompress&q=75&sharp=10&rect=583%2C0%2C1500%2C1500&s=ea3f94a6046b4e5158a1aca4eafae58b"
                    alt="Organized"
                    className="event-w-15 event-h-15 event-rounded-full event-border-4 event-border-blue-500"
                  />
                </div>
                <div>
                  <p className="event-text-gray-800 event-font-semibold">
                    {organization?.name || "Organization"}
                  </p>
                </div>

              </div>
              <p className="event-gap-2 event-mt-2 event-font-semibold">
                {`Contact : ${organization?.phone || "Phone"} | Email : ${organization?.email}`}
              </p>
            </div>
          </div>

          {/* Right Section */}

          <div className="event-w-full md:event-w-1/3 event-bg-gray-100 event-p-4 event-rounded-lg">
            {event.eventType == "recurring" &&
              <div style={{ padding: "20px" }}>
                {Object.entries(groupedSlots).map(([date, slots]) => (
                  <div
                    key={date}
                    style={{
                      border: "2px solid #ed3c5d",
                      borderRadius: "8px",
                      padding: "10px",
                      marginBottom: "10px",
                      backgroundColor: "#f7f9fc",
                    }}
                  >
                    <h4 style={{ color: "#ed3c5d", marginBottom: "5px" }}>
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        size="lg"
                        className="event-text-gray-200"
                      /> {" "}
                      {formatDate(date)}</h4>
                    <p style={{ marginBottom: "5px" }}>{slots.length} time slots</p>
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                      {slots.map((slot) => (
                        <button
                          key={slot.id}
                          onClick={() => handleSelect(slot.id)}
                          style={{
                            backgroundColor: selectedSlot === slot.id ? "#ed3c5d" : "gray",
                            color: "white",
                            padding: "8px 12px",
                            border: "none",
                            borderRadius: "5px",
                            cursor: "pointer",
                            fontSize: "14px",
                          }}
                        >
                          {new Date(`2025-01-01T${slot.startTime}`).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            }
            {event.eventstatus === "publish" && event?.tickets?.map((ticket) => (
              <>
                <div key={ticket.id} className="event-bg-white event-p-4 event-rounded-lg event-shadow">
                  <p className="event-text-lg event-font-bold">{ticket.ticketName}</p>
                  <div className="event-mt-4 event-flex event-items-center event-justify-between">
                    <p className="event-text-gray-600">£{ticket.ticketPrice}</p>
                    <div className="event-flex event-items-center event-space-x-2">
                      <button
                        className="event-px-3 event-py-1 event-rounded-lg"
                        style={{ background: "#ed3c5d", border: "none" }}
                        onClick={() => decreaseQuantity(ticket.id)}
                      >
                        -
                      </button>
                      <p style={{ marginTop: "10px" }}>{selectedTickets[ticket.id] || 0}</p>
                      <button
                        className="event-px-3 event-py-1 event-rounded-lg"
                        style={{ background: "#ed3c5d", border: "none" }}
                        onClick={() => increaseQuantity(ticket.id)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <br></br>
              </>
            ))}
            {event.eventstatus === "publish" ?
              <button
                className="event-w-full event-bg-blue-600 event-text-white event-mt-4 event-py-2 event-rounded-lg"
                onClick={() => openModal(selectedTickets)}
                disabled={event?.tickets[0]?.isDonationEvent == "no" && getTotalPrice() === 0}
                style={{ background: "#ed3c5d", border: "none" }}
              >
                Checkout for £{getTotalPrice() || 0}
              </button>
              : "Tickets are available soon."}
          </div>


        </div>

        {/* Similar Events */}
        <div className="event-mt-10 event-p-6">
          <h2 className="event-text-2xl event-font-bold">Other events you may like</h2>
          <div className="event-mt-4 event-grid event-grid-cols-1 sm:event-grid-cols-2 lg:event-grid-cols-3 event-gap-4">
            {upcomingEvent.length > 0 ? upcomingEvent.map((event) => (<div className="event-bg-white event-rounded-lg event-shadow event-overflow-hidden">
              <img
                src={event.eventMedia || 'https://wa-campaign.s3.eu-west-2.amazonaws.com/image/side-view-cheesecake-with-chocolate-syrup-strawberry-mint-whipped-cream-cherry-black-tea.jpg'}
                alt={event.eventTitle}
                className="event-w-full event-h-32 event-object-cover"
              />
              <div className="event-p-4">
                <p className="event-font-bold">{event.eventTitle}</p>
                <p className="event-text-sm event-text-gray-600">{event.eventDate}</p>
              </div>
            </div>))
              : <div className="event-text-center event-text-gray-600">
                No similar events found
              </div>}
            {/* <div className="bg-white rounded-lg shadow overflow-hidden">
              <img
                src="https://via.placeholder.com/300x150"
                alt="Event 2"
                className="w-full h-32 object-cover"
              />
              <div className="p-4">
                <p className="font-bold">Event Name 2</p>
                <p className="text-sm text-gray-600">Sun, 16 Feb</p>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow overflow-hidden">
              <img
                src="https://via.placeholder.com/300x150"
                alt="Event 3"
                className="w-full h-32 object-cover"
              />
              <div className="p-4">
                <p className="font-bold">Event Name 3</p>
                <p className="text-sm text-gray-600">Mon, 17 Feb</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* Ticket Purchase Modal */}
      {selectedTickets && (
        <TicketModal
          isOpen={isModalOpen}
          toggle={closeModal}
          ticket={selectedTickets}
          event={event}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
          getTotalPrice={getTotalPrice}
          setSelectedTickets={setSelectedTickets}
          selectedSlotId={selectedSlot}
        />
      )}
    </div>
  );
};

export default Event;
