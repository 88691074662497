import { faImage, faKey, faAngleDown, faQrcode, faUsers, faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, } from "react-router-dom";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Media } from "reactstrap";
import { getLocalStorageUserData, removeSessionData } from "../../utils/session";
import ChangePassword from "../ChangePassword";
import ViewQRCode from "../../pages/Restaurant/components/viewQRCode";
import { superAdminLogin } from "../../services/middleware";
import {notification, editNotification} from '../../pages/Restaurant/utils/middleware/notification';
import moment from 'moment';

const Header = (props) => {
  const navigate = useNavigate();

  // Store
  const { restaurant } = useSelector(state => state.restaurantAdmin)
  const isAdminUser = useSelector(state => state.user.isAdminUser)

  // State
  const [changePasswordModal, setChangePasswordModal] = useState(false)
  const [viewQRModal, setViewQRModal] = useState(false)
  const [notificationDetails,setNotificationDetails]=useState()
  const [notificationCount,setNotificationCount]=useState()
  const restaurantData = JSON.parse(localStorage.getItem('userData'));
  const { restaurantId } = restaurantData;

  useEffect(() => {
    handleNotificationCall()
  }, []);

  const handleNotificationCall = () => {
    notification(restaurantId).then((res) =>{
      setNotificationDetails(res.data.data)
      const unread = res.data.data.filter(notification => !notification.isRead);
      setNotificationCount(unread)
    }) 
  }

  const handleIsRead = () => {
    const ids = notificationCount.map(notification => notification.id);
    const data = {
      notificationIds: ids
    }
    if(ids.length > 0){
      editNotification(data, restaurantId)
      handleNotificationCall()
    }
  }

  const formatDate = (dateString) => {
    return moment(dateString).format('DD-MM h:mm A');
  };

  return (
    <div className="header-wrap">
      <Navbar className="navbar-top" expand="sm" id="navbar-main">
        <>
          <div className="left-block-wrap">
            <div className="h4 mb-0 text-uppercase" to="/">
              {props.brandText}
            </div>
          </div>
          <Nav className="align-items-center d-none d-lg-flex header-profile-menu" navbar>
          <div>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <div className="fs-5 border p-2 px-3 rounded-pill cursor-pointer position-relative" onClick={handleIsRead}>
                  <FontAwesomeIcon icon={faBell} />
                  {notificationCount?.length > 0 && <span className="position-absolute noti-count">{notificationCount?.length}</span>}
                </div>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem className="noti-title notification" header tag="div">
                  <h6 className="text-overflow m-0">Notification</h6>
                  {notificationDetails?.map((notification) =>(
                    <div key={notification.id} className="noti-div">
                      <h2>{notification.customer.name}</h2>
                      <p>{notification.message}</p>
                      <p className="time">{formatDate(notification.createdAt)}</p>
                    </div>
                  ))}
                  <DropdownItem to="/admin/customnotification" tag={Link} className="viewall-noti">                    
                    <span>View All</span>
                  </DropdownItem>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav title={restaurant?.name || getLocalStorageUserData()?.name}>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    {restaurant?.logo ? <img alt="Logo" src={restaurant?.logo} className="full-height" /> :
                      <FontAwesomeIcon icon={faImage} />}
                  </span>
                  <Media className="ml-2 d-none d-lg-inline-flex align-items-center">
                    <span className="text-sm profile-name">
                      {restaurant?.name || getLocalStorageUserData()?.name}
                    </span>
                    <i className="ml-2">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </i>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                {!isAdminUser && <DropdownItem to="/admin/profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>}
                <DropdownItem tag={Link} onClick={() => setChangePasswordModal(true)}>
                  <FontAwesomeIcon icon={faKey} />
                  <span>Change password</span>
                </DropdownItem>
                {!isAdminUser && <DropdownItem tag={Link} onClick={() => setViewQRModal(true)}>
                  <FontAwesomeIcon icon={faQrcode} />
                  <span>Restaurant QR Code</span>
                </DropdownItem>}
                {!isAdminUser && getLocalStorageUserData()?.isSuperAdmin && <DropdownItem tag={Link} onClick={() => superAdminLogin(restaurant?.id, false, navigate)}>
                  <FontAwesomeIcon icon={faUsers} />
                  <span>Logged In as Super Admin</span>
                </DropdownItem>}
                <DropdownItem to="/admin/settings" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#" onClick={() => removeSessionData()}>
                  <i className="ni ni-button-power" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </>
      </Navbar>

      {changePasswordModal && <ChangePassword open={changePasswordModal} handleCloseModal={() => setChangePasswordModal(false)} />}
      {viewQRModal && <ViewQRCode open={viewQRModal} restaurantName={getLocalStorageUserData()?.name} restaurantPhone={getLocalStorageUserData()?.phone} id={restaurantId} handleCloseModal={() => setViewQRModal(false)} />}
    </div>
  );
};

export default Header;
