import React, { useState } from "react";
import { Button, Card, CardBody, CardImg, Input } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { uploadImage } from '../../../../services/middleware';
import { ImageType } from '../../../../constants/General';
import { Actions } from '../../../../redux/actions';
import { dispatch } from '../../../../utils/store';
import { handleError } from '../../../../utils/toast';
import { Info } from 'lucide-react';

const ImageUpload = ({ setSelectedFile, selectedFile, selectedVideo, setSelectedVideo, event }) => {

  // Handle Image Upload
  // const handleImageUpload = (event) => {
  //   const files = event.target.files;
  //   if (files.length > 0) {
  //     const newImages = Array.from(files).map((file) => ({
  //       eventId: "",
  //       eventMedia: URL.createObjectURL(file),
  //       mediaType: "image"
  //     }));

  //     setSelectedFile((prevImages) => [...prevImages, ...newImages]);
  //   }
  // };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const fileType = file?.type?.split('/')[0];

    if (file) {
      await uploadImage(file, fileType === 'video' ? ImageType.CAMPAIGN_VIDEO : ImageType.CAMPAIGN_IMAGE)
        .then(response => {
          const newImage = {
            eventId: event?.id,
            eventMedia: response.fileLink,
            mediaType: "image"
          };

          setSelectedFile((prevImages) => [...(prevImages || []), newImage]);
        })
        .catch(err => {
          dispatch(Actions.RestaurantAdmin.SetLoading, false);
          handleError(err);
        });
    }
  };



  // Handle Video Upload
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const videoUrl = {
        eventId: "",
        eventMedia: URL.createObjectURL(file),
        mediaType: "image"
      };
      setSelectedVideo(videoUrl);
    }
  };

  // Remove Image
  const removeImage = (index) => {
    const updatedImages = selectedFile.filter((_, i) => i !== index);
    setSelectedFile(updatedImages);
  };

  // Slider Settings
  const sliderSettings = {
    dots: true,
    infinite: selectedFile?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: selectedFile?.length > 1,
    autoplaySpeed: 3000,
  };

  return (
    <div className="border p-4 rounded">
      <h3 className="mb-3">Add Images & Video</h3>

      {/* Image Upload Section */}
      <div className="mb-4">
        <h4 className="mb-3">Images</h4>
        <p className="text-muted">
          <strong>Pro tip:</strong> Use high-quality images to set the mood. <br></br>
          <strong>Supported file types:</strong>  .jpg, .png, | Max size: 10MB
        </p>
        <div className="border rounded p-4 text-center" style={{ width: "40%", margin: "0 auto" }}>
          {typeof selectedFile === 'object' && selectedFile.length > 0 ? (
            <Slider {...sliderSettings}>
              {selectedFile.map((url, index) => (
                <div key={index} className="position-relative">
                  <Card className="border-0">
                    <CardImg top src={url.eventMedia} alt={`Uploaded ${index}`} className="rounded" />
                  </Card>
                  <Button
                    color="danger"
                    size="sm"
                    className="position-absolute top-0 end-0 m-2"
                    onClick={() => removeImage(index)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              ))}
            </Slider>
          ) : (
            <>
              <p className="text-muted">Drag and drop or</p>
              <Input type="file" accept="image/*" multiple onChange={handleImageUpload} className="d-none" id="imageUpload" />
              <label htmlFor="imageUpload" className="btn btn-outline-primary">
                Upload Images
              </label>
            </>
          )}
        </div>
      </div>

      {/* Add More Images */}
      <div className="d-flex align-items-center">
        <Input type="file" accept="image/*" disabled={selectedFile > 2 ? true : false} multiple onChange={handleImageUpload} className="d-none" id="addMoreImages" />
        <label htmlFor="addMoreImages" className="btn btn-outline-primary me-2">
          <FontAwesomeIcon icon={faPlus} /> Add More
        </label>
      </div>

      {/* Video Upload Section */}
      <div className="mt-4">
        <h4 className="mb-3">Video</h4>
        <p className="text-muted">
          Add a video to enhance engagement. Max length: 1 min. <br></br>
          <strong>Supported file types:</strong>  .mp4 | Max size: 30MB
        </p>
        <div className="border rounded p-4 text-center">
          <Input type="file" accept="video/*" onChange={handleVideoUpload} className="d-none" id="videoUpload" />
          <label htmlFor="videoUpload" className="btn btn-outline-primary">
            Upload Video
          </label>
        </div>
      </div>

      {/* Show API Data
      <pre className="mt-3 bg-light p-3 rounded">
        API Data: {JSON.stringify(apiData, null, 2)}
      </pre> */}
    </div>
  );
};

export default ImageUpload;
