import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Panel,
  useReactFlow,
  //MiniMap,
  Controls,
  Background,
} from "reactflow";
import "reactflow/dist/base.css";
import Sidebar from "./components/Sidebar.jsx";
import TextNode from "./components/TextNode.jsx";
import ConditionNode from "./components/ConditionNode.jsx";
import StartNode from "./components/StartNode.jsx";
import EndNode from "./components/EndNode.jsx";
import AgentNode from "./components/AgentNode.jsx";
import NewFlowModal from "./components/NewFlowModal.jsx";
import { v4 as uuidv4 } from "uuid";
import { getBezierPath } from "reactflow";
import "./index.css";
import {
  addNodeChatAutomation,
  addEdgeChatAutomation,
  getChatAutomation,
  SetDefaultFlow,
  CreateFlow,
  getFlowList,
  updateNodeChatAutomation,
  updateEdgeChatAutomation,
  deleteNodeChatAutomation,
  deleteEdgeChatAutomation,
} from "../../utils/middleware/chatAtomation.js";
import {
  handleError,
  handleSuccess,
  showMessage,
} from "../../../../utils/toast";
import { Actions } from "../../../../redux/actions";
import { MessageType } from "../../../../constants/General";
import { dispatch } from "../../../../utils/store";
import { toast } from "react-toastify";
// Key for local storage

// Function for generating unique IDs for nodes
const getId = () => uuidv4();

// Initial nodes setup
const initialNodes = [];

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  onDelete,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={40} // Adjusted width
        height={40} // Adjusted height
        x={labelX - 20} // Center the button horizontally
        y={labelY - 20} // Center the button vertically
        className="edgebutton-foreignobject"
      >
        <div style={{ textAlign: "center" }}>
          {/* Center the button text */}
          <button
            onClick={() => onDelete(id)}
            style={{
              background: "white",
              border: "1px solid black",
              borderRadius: "50%",
              padding: "3px",
              fontSize: "10px",
              width: "20px",
              height: "20px",
              marginTop: "10px",
            }}
          >
            X
          </button>
        </div>
      </foreignObject>
    </>
  );
};
const FlowWithProvider = () => {
  // States and hooks setup
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedElements, setSelectedElements] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [uploadImageData, setUploadImageData] = useState([]);
  const [uploadUrlData, setUploadUrlData] = useState([]);
  const [uploadVideoData, setUploadVideoData] = useState([]);
  const [nodeText, setNodeText] = useState("");
  const [flowText, setFlowText] = useState("");
  const [nodeValue, setNodeValue] = useState({});
  const [nodeType, setNodeType] = useState("");
  const [nodeLabel, setNodeLabel] = useState("");
  const [updateStatus, setUpdateStatus] = useState(true);
  const [selectedFile, setSelectedFile] = useState([]);
  const [buttonUrl, setButtonUrl] = useState({});
  const [selectedPDFFile, setSelectedPDFFile] = useState([]);
  const [selectedVideoFile, setSelectedVideoFile] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState([]);
  const [flowOptions, setFlowOptions] = useState([]);
  const [fileUrl, setFileUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [flowIdd, setFlowIdd] = useState(null);
  const [oldFlowId, setOldFlowId] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [systemDefault, setSystemDefault] = useState(null);

  const handleNewFlow = () => {
    setIsModalOpen(true); // Open modal when Add New Flow button is clicked
  };
  const restaurant = JSON.parse(localStorage.getItem("userData"));
  const userRestaurantId = restaurant.restaurantId;

  useEffect(() => {
    // onRestore();
    getDefaultFlowList();
  }, []);

  const handleDeleteEdge = useCallback(
    (edgeId) => {
      setEdges((eds) => {
        const newEdges = eds.filter((edge) => edge.id !== edgeId);
        const filteredOutEdges = eds.filter((edge) => edge.id === edgeId);
        filteredOutEdges.forEach((element) => {
          if (element.createdAt) {
            deleteEdgeChatAutomation(element.id);
          }
        });
        return newEdges;
      });
    },
    [setEdges]
  );

  const edgeTypes = useMemo(
    () => ({
      custom: (props) => <CustomEdge {...props} onDelete={handleDeleteEdge} />,
    }),
    [handleDeleteEdge]
  );

  const handleDeleteNode = useCallback(
    (nodeId) => {
      // Clear sidebar state if the selected node is deleted
      if (isDisable) {
        toast.info("System Default flow can not be editable.", {
          position: "bottom-right",
          theme: "colored",
        });
      } else {
        if (selectedElements[0]?.id === nodeId) {
          setNodeText("");
          setNodeValue({});
          setNodeType("");
          setNodeLabel("");
          setSelectedPDFFile([]);
          setSelectedVideoFile([]);
          setSelectedFile([]);
          setButtonUrl({});
          setSelectedElements([]);
        }

        // Function to check if a parent node is connected to any child nodes
        const isParentConnectedToChild = (parentId, edges) => {
          // Simply check if there's any edge with the source as parentId
          return edges.some((edge) => edge.source === parentId);
        };

        setEdges((eds) => {
          if (!isParentConnectedToChild(nodeId, eds)) {
            const newEdges = eds.filter(
              (edge) => edge.source !== nodeId && edge.target !== nodeId
            );
            const filteredOutEdges = eds.filter(
              (edge) => edge.source === nodeId || edge.target === nodeId
            );
            setNodes((nds) => {
              const newNodes = nds.filter((node) => node.id !== nodeId);
              const filterOutNodes = nds.filter((node) => node.id === nodeId);
              if (filterOutNodes && filterOutNodes[0].createdAt) {
                deleteNodeChatAutomation(filterOutNodes[0].id);
              }
              return newNodes;
            });
            filteredOutEdges.forEach((element) => {
              if (element.createdAt) {
                deleteEdgeChatAutomation(element.id);
              }
            });
            return newEdges;
          } else {
            alert("Please remove the child node first", true);

            return eds;
          }
        });
      }
    },
    [selectedElements, setNodes, setEdges]
  );

  const nodeTypes = useMemo(
    () => ({
      textnode: (props) => (
        <TextNode {...props} id={props.id} onDelete={handleDeleteNode} />
      ),
      conditionnode: (props) => (
        <ConditionNode {...props} id={props.id} onDelete={handleDeleteNode} />
      ),
      startnode: (props) => (
        <StartNode {...props} id={props.id} onDelete={handleDeleteNode} />
      ),
      endnode: (props) => (
        <EndNode {...props} id={props.id} onDelete={handleDeleteNode} />
      ),
      agentnode: (props) => (
        <AgentNode {...props} id={props.id} onDelete={handleDeleteNode} />
      ),
    }),
    [handleDeleteNode]
  );

  useEffect(() => {
    if (selectedElements.length > 0) {
      const selectedNodeId = selectedElements[0]?.id;

      const nodeExists = nodes.some((node) => node.id === selectedNodeId);
      if (!nodeExists) {
        setSelectedElements([]);
        return; // If the node doesn't exist, do nothing
      }

      // Only update the selected node
      const updatedNodes = nodes.map((node) => {
        if (node.id === selectedNodeId) {
          let updatedData = { ...node.data };
          if (node.type === "conditionnode" || node.type === "textnode") {
            updatedData = {
              ...updatedData,
              text: nodeText,
              type: nodeType,
              label: nodeLabel,
              flowId: flowIdd,
              value: nodeValue,
              pdf: selectedPDFFile.length > 0 ? selectedPDFFile : uploadData,
              file: selectedFile.length > 0 ? selectedFile : uploadImageData,
              urlButton: buttonUrl ? buttonUrl : {},
              video:
                selectedVideoFile.length > 0
                  ? selectedVideoFile
                  : uploadVideoData,
            };
          }
          if (node.type === "startnode") {
            updatedData = {
              ...updatedData,
              flowId: flowIdd,
            };
          }

          return { ...node, data: updatedData };
        }
        return node;
      });
      setNodes(updatedNodes);
    }
  }, [
    nodeText,
    nodeType,
    nodeValue,
    setFlowIdd,
    nodeLabel,
    buttonUrl,
    setButtonUrl,
    selectedElements,
    setNodes,
    selectedFile,
    selectedUrl,
    fileUrl,
    selectedPDFFile,
    selectedVideoFile,
    flowText,
  ]);

  const onNodeClick = useCallback(
    (event, node) => {
      setSelectedElements([node]);
      // Reset the values in the sidebar for the new selected node
      if (
        node.type === "textnode" ||
        node.type === "conditionnode" ||
        node.type === "startnode" ||
        node.type === "endnode" ||
        node.type === "agentNode"
      ) {
        setNodeText(node.data.text || "");
        setNodeType(node.data.type || "");
        setNodeLabel(node.data.label || "");
        setNodeValue(
          node.data.value ||
          (node.type === "conditionnode" || node.type === "textnode"
            ? []
            : "")
        );
        setSelectedFile(node.data.file || (node.type === "textnode" ? [] : ""));
        setSelectedPDFFile(
          node.data.pdf || (node.type === "textnode" ? [] : "")
        );
        setSelectedVideoFile(
          node.data.video || (node.type === "textnode" ? [] : "")
        );
        setButtonUrl(node.data.urlButton || (node.type === "textnode" ? {} : ""));
      }

      setNodes((nodes) =>
        nodes.map((n) => ({
          ...n,
          selected: n.id === node.id,
        }))
      );
    },
    [nodes, setNodes]
  );

  // Setup viewport
  const { setViewport } = useReactFlow();

  // Check for empty target handles
  const checkEmptyTargetHandles = () => {
    let emptyTargetHandles = 0;
    edges.forEach((edge) => {
      if (!edge.targetHandle) {
        emptyTargetHandles++;
      }
    });
    return emptyTargetHandles;
  };

  // Check if any node is unconnected
  const isNodeUnconnected = useCallback(() => {
    let unconnectedNodes = nodes.filter(
      (node) =>
        !edges.find(
          (edge) => edge.source === node.id || edge.target === node.id
        )
    );
    return unconnectedNodes.length > 0;
  }, [nodes, edges]);

  // Save flow to local storage
  const onSave = useCallback(() => {
    if (isDisable) {
      toast.info("System Default flow can not be editable.", {
        position: "bottom-right",
        theme: "colored",
      });
    } else {
      if (reactFlowInstance) {
        const emptyTargetHandles = checkEmptyTargetHandles();
        if (
          nodes.length > 1 &&
          (emptyTargetHandles > 1 || isNodeUnconnected())
        ) {
          toast.error("More than one node has an empty target handle or there are unconnected nodes."
            , {
              position: "bottom-right",
              theme: "colored",
            });
        } else {
          const flow = reactFlowInstance.toObject();
          const promisesNode = flow.nodes.map((node) => {
            if (node.nodeId) {
              return updateNodeChatAutomation(node);
            } else {
              return addNodeChatAutomation(node);
            }
          });
          Promise.all(promisesNode)
            .then((res) => {
              showMessage("Flow is successfully Added.", MessageType.Success);
              dispatch(Actions.RestaurantAdmin.SetLoading, false);
            })
            .catch((error) => {
              handleError(error);
              dispatch(Actions.RestaurantAdmin.SetLoading, false);
            });
          const promisesEdge = flow.edges.map((edge) => {
            if (edge.edgeId) {
              updateEdgeChatAutomation(edge);
            } else {
              addEdgeChatAutomation(edge);
            }
          });

          Promise.all(promisesEdge)
            .then((res) => {
              // Display success message only once
              handleSuccess(res);
              dispatch(Actions.RestaurantAdmin.SetLoading, false);
            })
            .catch((error) => {
              handleError(error);
              dispatch(Actions.RestaurantAdmin.SetLoading, false);
            });
        }
      }
    }
  }, [reactFlowInstance, nodes, isNodeUnconnected, setFlowIdd]);

  // Restore flow from local storage
  const onRestore = useCallback(
    (flowid, defaultFlowId) => {
      const restaurant = JSON.parse(localStorage.getItem("userData"));
      const restaurantId = restaurant.restaurantId;
      const payload = {
        restaurantId: restaurantId,
        flowId: flowid,
      };
      if (parseInt(flowid) !== parseInt(defaultFlowId)) {
        getChatAutomation(payload)
          .then((res) => {
            if (res[0].data.data.length > 0) {
              setUpdateStatus(false);
            }
            const dataNode = res[0].data.data.map((e) => {
              if (e.type == "textnode") {
                e.data.file = e.data.file;
                e.data.pdf = e.data.pdf;
                e.data.urlButton = e.data.urlButton;
              }
              let temp = e.id;
              e.id = e.nodeId;
              e.nodeId = temp;
              return e;
            });
            const dataEdge = res[1].data.data.map((e) => {
              let temp = e.id;
              e.id = e.edgeId;
              e.edgeId = temp;
              return e;
            });
            if (res) {
              const x = 0;
              const y = 0;
              const zoom = 1;
              setNodes(dataNode || []);
              setEdges(dataEdge || []);
              setViewport({ x, y, zoom });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        const dataNode = [
          {
            nodeId: 218,
            id: "4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4e",
            type: "textnode",
            data: {
              pdf: [],
              urlButton: {},
              file: [],
              text: "Hey There, Welcome to Our Restaurant. How Can we help you? ",
              type: "",
              label: "",
              value: [
                {
                  id: "cda9866a-1b1a-4969-9892-0efbbd6041db",
                  value: "Talk to Chatbot",
                },
                {
                  id: "df5e4555-6a19-447d-8f22-cb20649557bc",
                  value: "Chat With Agent",
                },
                {
                  id: "fa0496e5-7c0a-45e4-82d8-d3a9b1492182",
                  value: "Reservation",
                },
              ],
              video: [],
              flowId: "default",
              source: "",
              target: "",
              nodePDF: [],
              nodeVideo: [],
              nodeImages: [],
            },
            position: {
              x: 72.25,
              y: 208.5,
            },
            selected: true,
            dragging: false,
            flowId: 14,
            width: 160,
            height: 245,
            createdAt: "2024-12-26T08:34:23.000Z",
            updatedAt: "2024-12-26T08:34:23.000Z",
            deletedAt: null,
            restaurantId: null,
          },
          {
            nodeId: 219,
            id: "d1b129ed-06fa-45b7-b5df-c33aabc35e0e",
            type: "startnode",
            data: {
              pdf: "",
              file: "",
              type: "",
              label: "",
              value: "",
              video: "",
              source: "",
            },
            position: {
              x: -53,
              y: 227,
            },
            selected: false,
            dragging: false,
            flowId: "default",
            width: 64,
            height: 18,
            createdAt: "2024-12-23T15:38:43.000Z",
            updatedAt: "2024-12-23T15:38:43.000Z",
            deletedAt: null,
            restaurantId: null,
          },
          {
            nodeId: 220,
            id: "bc46969c-c821-42e0-9627-54b673ce070f",
            type: "endnode",
            data: {
              pdf: "",
              file: "",
              type: "",
              label: "",
              value: "",
              video: "",
              target: "",
            },
            position: {
              x: 310.25,
              y: 243,
            },
            selected: false,
            dragging: false,
            flowId: "default",
            width: 64,
            height: 18,
            createdAt: "2024-12-26T08:33:02.000Z",
            updatedAt: "2024-12-26T08:33:02.000Z",
            deletedAt: null,
            restaurantId: null,
          },
        ];
        const dataEdge = [
          {
            source: "d1b129ed-06fa-45b7-b5df-c33aabc35e0e",
            sourceHandle: "1626d315-eac2-46da-a92a-438f6321cad7",
            target: "4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4e",
            targetHandle: "d31e287b-ebf4-45e3-94be-82a9553ec5ed",
            type: "custom",
            restaurantId: 44,
            flowId: "default",
            id: "reactflow__edge-d1b129ed-06fa-45b7-b5df-c33aabc35e0e1626d315-eac2-46da-a92a-438f6321cad7-4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4ed31e287b-ebf4-45e3-94be-82a9553ec5ed",
          },
          {
            source: "4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4e",
            sourceHandle: "cda9866a-1b1a-4969-9892-0efbbd6041db",
            target: "bc46969c-c821-42e0-9627-54b673ce070f",
            targetHandle: "173a6341-c203-46c1-a160-e3e3078e6666",
            type: "custom",
            restaurantId: 44,
            flowId: "default",
            id: "reactflow__edge-4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4ecda9866a-1b1a-4969-9892-0efbbd6041db-bc46969c-c821-42e0-9627-54b673ce070f173a6341-c203-46c1-a160-e3e3078e6666",
          },
          {
            source: "4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4e",
            sourceHandle: "df5e4555-6a19-447d-8f22-cb20649557bc",
            target: "bc46969c-c821-42e0-9627-54b673ce070f",
            targetHandle: "b2db2b0c-3566-4aae-9fa5-afd84a7e1a10",
            type: "custom",
            restaurantId: 44,
            flowId: "default",
            id: "reactflow__edge-4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4edf5e4555-6a19-447d-8f22-cb20649557bc-bc46969c-c821-42e0-9627-54b673ce070fb2db2b0c-3566-4aae-9fa5-afd84a7e1a10",
          },
          {
            source: "4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4e",
            sourceHandle: "fa0496e5-7c0a-45e4-82d8-d3a9b1492182",
            target: "bc46969c-c821-42e0-9627-54b673ce070f",
            targetHandle: "b2db2b0c-3566-4aae-9fa5-afd8ydhy1a10",
            type: "custom",
            restaurantId: 44,
            flowId: "default",
            id: "reactflow__edge-4a7f62c6-b516-4d24-8a3d-5ce0a2c4ea4efa0496e5-7c0a-45e4-82d8-d3a9b1492182-bc46969c-c821-42e0-9627-54b673ce070fb2db2b0c-3566-4aae-9fa5-afd84a7e1a10",
          },
        ];
        const x = 0;
        const y = 0;
        const zoom = 1;
        setNodes(dataNode || []);
        setEdges(dataEdge || []);
        setViewport({ x, y, zoom });
      }
    },
    [setNodes, setViewport, setEdges, setFlowIdd]
  );

  const handleFlowSubmit = async (flowName) => {
    // Replace with your API call to create a flow
    try {
      const payload = {
        name: flowName,
        isDefault: false,
        restaurantId: userRestaurantId,
      };
      const response = await CreateFlow(payload);

      if (response.code === 201) {
        setFlowIdd(response.data.id); // Set the flowId from the API response
        setIsModalOpen(false); // Close modal
        getDefaultFlowList();
        toast.success("Flow created successfully.", {
          position: "bottom-right",
          theme: "colored",
        });
      } else {
        // Handle error if API call fails
        toast.error("Something went wrong. Please try again.", {
          position: "bottom-right",
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error creating flow:", error);
      toast.error("Something went wrong. Please try again.", {
        position: "bottom-right",
        theme: "colored",
      });
    }
  };

  const handleSetDefaultFlow = () => {
    const payload = {
      newFlowId: flowIdd,
      oldFlowId: oldFlowId,
    };
    SetDefaultFlow(payload)
      .then((res) => {
        if (res[0].data.code === 201) {
          toast.success(res[0].data.message, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSelectDefaultFlow = (value) => {
    setOldFlowId(flowIdd);
    setFlowIdd(value);
    const flowData = systemDefault;

    if (parseInt(value) === parseInt(flowData)) {
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
    setTimeout(() => {
      onRestore(value, flowData);
    }, 100);
  };

  const getDefaultFlowList = () => {
    getFlowList(userRestaurantId)
      .then((res) => {
        const defaultObj = res[0].data.data
          .filter((item) => item.isDefault === true)
          .at(-1);

        const systemDefaultFlow = res[0].data.data.filter((item) => item.name === "System Default")
        setSystemDefault(systemDefaultFlow[0].id);
        const firstDrop = defaultObj ? defaultObj.id : "default";
        setFlowIdd(firstDrop)
        if (defaultObj && parseInt(defaultObj.id) === parseInt(systemDefaultFlow[0].id)) {
          setIsDisable(true)
        } else {
          setIsDisable(false)
        }

        setFlowOptions(res[0].data.data);
        setTimeout(() => {
          onRestore(firstDrop, systemDefaultFlow[0].id);
        }, 100);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  // Handle edge connection
  const onConnect = useCallback(
    (params) => {
      const { source, sourceHandle, target } = params;

      // Check if the source handle already has an edge connected
      const isSourceHandleOccupied = edges.some(
        (edge) => edge.source === source && edge.sourceHandle === sourceHandle
      );

      // Get the node corresponding to the source ID
      const sourceNode = nodes.find((node) => node.id === source);

      // Limit outgoing edges from the "startNode" type
      const maxOutgoingEdgesFromStart = 1;
      const existingOutgoingEdgesFromStart = edges.filter(
        (edge) => edge.source === source
      );

      // If the source handle is already occupied, prevent the connection
      if (isSourceHandleOccupied) {
        alert("Source handle already occupied.", true);
        return;
      }
      // Prevent multiple connections from a node of type "startnode"
      if (
        sourceNode &&
        sourceNode.type === "startnode" &&
        existingOutgoingEdgesFromStart.length >= maxOutgoingEdgesFromStart
      ) {
        alert("Only one connection is allowed from the Start node.", true);
        return;
      }
      // else if(sourceNode && sourceNode.type === 'textnode' && existingOutgoingEdgesFromStart.length >= maxOutgoingEdgesFromStart){
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === source) {
            return {
              ...node,
              data: {
                ...node.data,
                source: target, // Add target node ID as source's connected target
              },
            };
          }
          if (node.id === target) {
            return {
              ...node,
              data: {
                ...node.data,
                target: source, // Add source node ID as target's connected source
              },
            };
          }
          return node;
        })
      );

      // Add the new edge to the edges state
      setEdges((eds) =>
        addEdge(
          {
            ...params,
            type: "custom",
            restaurantId: userRestaurantId,
            flowId: flowIdd,
          },
          eds
        )
      );
      console.error(edges, nodes);
    },
    [edges, nodes, setEdges, setNodes]
  );

  // Enable drop effect on drag over
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Handle drop event to add a new node
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      if (typeof type === "undefined" || !type) {
        return;
      }
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const newNode = {
        id: getId(),
        type,
        position,
        flowId: flowIdd,
        data: {
          label: `${type === "textnode" ? "" : type === "conditionnode" ? "" : ""
            }`,
          type: "",
          value: type === "conditionnode" ? [] : "",
          pdf: type === "textnode" ? [] : "",
          file: type === "textnode" ? [] : "",
          urlButton: type === "textnode" ? [] : "",
          video: type === "textnode" ? [] : "",
        },
        restaurantId: userRestaurantId,
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes, setFlowIdd, flowIdd]
  );

  const rfStyle = {
    backgroundColor: "#ffffff",
  };
  return (
    <div className="flow-container">
      <div className="grow-container" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          style={rfStyle}
          onNodeClick={onNodeClick}
          nodesDraggable={!isDisable}
          nodesConnectable={!isDisable}
          elementsSelectable={!isDisable}
          onPaneClick={() => {
            setSelectedElements([]); // Reset selected elements when clicking on pane
            setNodes((nodes) =>
              nodes.map((n) => ({
                ...n,
                selected: false, // Reset selected state of nodes when clicking on pane
              }))
            );
          }}
          fitView
        >
          <Background variant="dots" gap={12} size={1} />
          <Controls />
          {/* <MiniMap zoomable pannable /> */}
          <Panel>
            <label htmlFor="flowSelect" className="panel-label">
              Select Flow:
            </label>
            <select
              id="flowSelect"
              className="panel-button"
              value={flowIdd}
              onChange={(e) => handleSelectDefaultFlow(e.target.value)}
            >
              {flowOptions.map((option, index) => (
                <option
                  key={index}
                  value={option.id}
                  selected={option.isDefault}
                >
                  {option.name}
                </option>
              ))}
            </select>
            <button className="panel-button" onClick={onSave}>
              Save Flow
            </button>
            <button className="panel-button" onClick={handleSetDefaultFlow}>
              Set Default Flow
            </button>
            <button className="panel-button" onClick={handleNewFlow}>
              Add New Flow
            </button>
          </Panel>
        </ReactFlow>
      </div>
      <NewFlowModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)} // Close the modal
        onSubmit={handleFlowSubmit} // Handle flow creation
      />
      <Sidebar
        nodes={nodes}
        selectedNode={selectedElements[0]}
        setSelectedElements={setSelectedElements}
        nodeText={nodeText}
        flowText={flowText}
        nodeValue={nodeValue}
        nodeType={nodeType}
        nodeLabel={nodeLabel}
        setNodeLabel={setNodeLabel}
        buttonUrl={buttonUrl}
        setButtonUrl={setButtonUrl}
        setNodeText={setNodeText}
        setFlowText={setFlowText}
        setNodeType={setNodeType}
        setNodeValue={setNodeValue}
        setSelectedFile={setSelectedFile}
        selectedFile={selectedFile}
        selectedPDFFile={selectedPDFFile}
        selectedVideoFile={selectedVideoFile}
        setSelectedVideoFile={setSelectedVideoFile}
        setSelectedPDFFile={setSelectedPDFFile}
        fileUrl={fileUrl}
        isDisable={isDisable}
        setFileUrl={setFileUrl}
      />
    </div>
  );
};

// Wrap App with ReactFlowProvider
function ChatsFlow() {
  return (
    <ReactFlowProvider>
      <FlowWithProvider />
    </ReactFlowProvider>
  );
}

export default ChatsFlow;
