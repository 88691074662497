import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useFormik } from 'formik';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import "react-quill/dist/quill.snow.css";
import { addEvent, updateEvent } from '../../utils/middleware/events';
import { removeEmptyKeysFromObject, trimObjectValues } from '../../../../services/middleware';
import { toast } from "react-toastify";
import ImageUpload from "./ImageUpload";
import EventForm from "./EventForm";
import EventOverview from "./EventOverview";
import OverviewComponent from "./OverviewComponent";
import AgeRestriction from "./AgeRestriction";
import "./event.css";

export default function EventPage(props) {
  // Props
  const { event, setEventData, changePage } = props;

  // State (same as before)
  const [eventTitle, setEventTitle] = useState('');
  const [eventSummary, setEventSummary] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventStartDate, setEventStartDate] = useState('');
  const [eventStartTime, setEventStartTime] = useState('');
  const [eventEndTime, setEventEndTime] = useState('');
  const [eventEntryTime, setEventEntryTime] = useState('');
  const [selectedAge, setSelectedAge] = useState(null);
  const [eventOverView, setEventOverView] = useState([]);
  const [parkingOption, setParkingOption] = useState('paid');
  const [isOnline, setIsOnline] = useState('no');
  const [isHours, setIsHours] = useState('no');
  const [eventType, setEventType] = useState('single');
  const [ageRestriction, setAgeRestriction] = useState('no');
  const [isParentNeed, setIsParentNeed] = useState('no');
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState({});
  const [isContinue, setIsContinue] = useState(false);
  const [isRsvp, setIsRsvp] = useState('no');

  // Validation function (same as before)
  function validateEventData(eventData) {
    let errors = [];
    if (!eventData.eventTitle) {
      errors.push("Event title is required");
    }
    if (!eventData.eventSummery) {
      errors.push("Event summery is required");
    }
    if (eventData.eventType === 'single' && !eventData.eventDate) {
      errors.push("Event date is required");
    }
    if (eventData.eventType === 'single' && !eventData.eventStartTime) {
      errors.push("Event start time is required");
    }
    if (eventData.eventType === 'single' && !eventData.eventEndTime) {
      errors.push("Event end time is required");
    }
    if (eventData.isOnlineEvent === 'no' && !eventData.eventLocation) {
      errors.push("Event location is required");
    }
    if (!eventData.eventEntryTime) {
      errors.push("Event entry time is required");
    }

    if (errors.length > 0) {
      toast.info(errors[0], {
        position: "bottom-right",
        theme: "colored",
      });
      return false;
    } else {
      return true;
    }
  }

  // Button Click Handlers (same as before)
  const handleSave = () => {
    setIsContinue(false);
    formik.handleSubmit();
  };

  const handleSaveAndContinue = () => {
    formik.handleSubmit();
  };

  // useEffect to populate form if event exists (same as before)
  console.log("event",event)
  useEffect(() => {
    if (event) {
      
      console.log("if",event)
      setEventTitle(event?.eventTitle);
      setSelectedFile(event?.eventMedias|| []);
      setEventSummary(event?.eventSummery);
      setEventLocation(event?.eventLocation);
      setEventStartDate(event?.eventDate);
      setEventStartTime(event?.eventStartTime);
      setEventEndTime(event?.eventEndTime);
      setSelectedAge(event?.allowAges);
      setEventOverView(event?.eventOverview);
      setSelectedVideo(event?.eventMedia);
      setIsParentNeed(event?.isParentNeed);
      setAgeRestriction(event?.isAgeRestircted || 'no');
      setParkingOption(event?.isParkingAvailable || "paid");
      setIsOnline(event?.isOnlineEvent || 'no');
      setIsHours(event?.isHours || 'no');
      setEventType(event?.eventType || 'single');
      setIsRsvp(event?.isRsvp || 'no');
      setEventEntryTime(event?.eventEntryTime);
    }
  }, [event]);

  // Formik setup (same as before)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: event?.id || null,
      eventMedia: event?.eventMedias || "",
      mediaType: event?.mediaType || "",
      eventTitle: event?.eventTitle || "",
      eventSummery: event?.eventSummery || "",
      eventName: event?.eventName || "",
      eventType: event?.eventType || "",
      eventDate: event?.eventDate || "",
      eventStartTime: event?.eventStartTime || "",
      eventEndTime: event?.eventEndTime || "",
      isOnlineEvent: event?.isOnlineEvent || "",
      eventLocation: event?.eventLocation || "",
      isReservation: event?.isReservation || "",
      eventOverview: event?.eventOverview || "",
      eventEntryTime: event?.eventEntryTime || "",
      isHours: event?.isHours || "",
      isAgeRestircted: event?.isAgeRestircted || "",
      isParentNeed: event?.isParentNeed || "",
      allowAges: event?.allowAges || "",
      isParkingAvailable: event?.isParkingAvailable || "",
      isPaidParking: event?.isPaidParking || "",
      parkingCharges: event?.parkingCharges || "",
      restaurantId: event?.restaurantId || "",
      eventstatus: event?.eventstatus || 'draft',
      isRsvp: event?.isRsvp || 'draft',
    },
    onSubmit: async (values) => {
      trimObjectValues(values, "media");
      values.message = values.message ? values.message.replace(/\n/g, " ") : "";
      const filterData = removeEmptyKeysFromObject(values);
      filterData.eventMedia = selectedFile;
      filterData.mediaType = selectedFile;
      filterData.eventTitle = eventTitle;
      filterData.eventSummery = eventSummary;
      filterData.eventName = eventTitle;
      filterData.eventType = eventType;
      filterData.eventDate = eventStartDate;
      filterData.eventStartTime = eventStartTime;
      filterData.eventEndTime = eventEndTime;
      filterData.isOnlineEvent = isOnline;
      filterData.eventLocation = eventLocation;
      filterData.isReservation = values.isReservation;
      filterData.eventOverview = eventOverView;
      filterData.isHours = isHours;
      filterData.eventEntryTime = eventEntryTime;
      filterData.isAgeRestircted = ageRestriction;
      filterData.isParentNeed = ageRestriction === "parent" ? "yes" : "no";
      filterData.allowAges = selectedAge;
      filterData.isParkingAvailable = parkingOption;
      filterData.isPaidParking = parkingOption === "paid" ? "yes" : "no";
      filterData.parkingCharges = values.parkingCharges || 0;
      filterData.eventstatus = values.eventstatus || 'draft';
      filterData.isRsvp = isRsvp;
      const restaurant = JSON.parse(localStorage.getItem("userData"));
      const userRestaurantId = restaurant.restaurantId;
      filterData.restaurantId = userRestaurantId || 0;
      if (event?.id && event?.id !== null && event?.id !== "") {
        if (validateEventData(filterData)) {
          eventType == 'single' ? changePage("Add tickets") : changePage("Manage dates and times")
          setIsContinue(true);
          updateEvent(
            event.id,
            filterData,
            setEventData
          );
        }
      } else {
        if (validateEventData(filterData)) {
          eventType == 'single' ? changePage("Add tickets") : changePage("Manage dates and times")
          setIsContinue(true);
          addEvent(
            filterData,
            setEventData
          );
        }
      }
    },
  });

  return (
    <Container fluid className="event-page" style={{ height: "100%" }}>
      <Card className="border-0 edit-card">
        <CardHeader>
          <h3 className="page-title">Build Event Page</h3>
        </CardHeader>
        <CardBody style={{ height: "80%", overflow: 'auto' }}>
          <Row className="file-upload-preview">
            <Col md={12}>
              <ImageUpload
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                selectedVideo={selectedVideo}
                setSelectedVideo={setSelectedVideo}
                event={event}
              />
            </Col>
          </Row>
          <Row className="file-upload-preview">
            <Col md={12}>
              <EventOverview
                eventTitle={eventTitle}
                setEventTitle={setEventTitle}
                eventSummary={eventSummary}
                setEventSummary={setEventSummary}
              />
            </Col>
          </Row>
          <Row className="file-upload-preview">
            <Col md={12}>
              <EventForm
                eventLocation={eventLocation}
                setEventLocation={setEventLocation}
                eventType={eventType}
                setEventType={setEventType}
                eventStartDate={eventStartDate}
                setEventStartDate={setEventStartDate}
                eventStartTime={eventStartTime}
                setEventStartTime={setEventStartTime}
                eventEndTime={eventEndTime}
                setEventEndTime={setEventEndTime}
                isOnline={isOnline}
                setIsOnline={setIsOnline}
                isRsvp={isRsvp}
                setIsRsvp={setIsRsvp}
              />
            </Col>
          </Row>
          <Row className="file-upload-preview">
            <Col md={12}>
              <OverviewComponent
                eventOverView={eventOverView}
                setEventOverView={setEventOverView}
              />
            </Col>
          </Row>
          <Row className="file-upload-preview">
            <Col md={12}>
              <AgeRestriction
                isHours={isHours}
                setIsHours={setIsHours}
                ageRestriction={ageRestriction}
                setAgeRestriction={setAgeRestriction}
                parkingOption={parkingOption}
                selectedAge={selectedAge}
                setSelectedAge={setSelectedAge}
                setParkingOption={setParkingOption}
                isParentNeed={isParentNeed}
                setIsParentNeed={setIsParentNeed}
                eventEntryTime={eventEntryTime}
                setEventEntryTime={setEventEntryTime}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <div className="page-footer">
        <Button
          color='outline-primary'
          type="button"
          onClick={() => window.history.back()}
        >
          Cancel
        </Button>
        <Button className='d-none' color='primary' type="button" value='exit' disabled={!!Object.values(formik.errors)?.length} onClick={handleSave}>
          Submit
        </Button>
        <Button color='primary' type="button" value="continue" disabled={!!Object.values(formik.errors)?.length} onClick={handleSaveAndContinue}>
          Save and Continue
        </Button>
      </div>
    </Container>
  );
}