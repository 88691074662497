import { EVENT_API, TICKET_API, EVENT_PAYMENT_REFUND_API, EVENT_PAYMENT_VERIFY_API } from "../../../../constants/Configs"
import { API_REQUEST } from "../../../../constants/General"
import { Actions } from "../../../../redux/actions"
import API from "../../../../services/api"
import { getPageSize } from "../../../../services/middleware"
import { dispatch } from "../../../../utils/store"
import { handleError, handleSuccess } from "../../../../utils/toast"
import { ticketsListDTO, ticketsSelectListDTO } from "../dtos/tickets"
import { eventsListDTO, eventsSelectListDTO } from "../dtos/events";
import { toast } from "react-toastify";

export const getEvent = ({ search, sortBy, orderBy, page = 1, pageSize }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true);
  const restaurant = JSON.parse(localStorage.getItem("userData"));
  const restaurantId = restaurant.restaurantId;

  const params = {
    ...(search?.eventName ? { eventName: search.eventName } : {}),
    ...(search?.isRsvp ? { isRsvp: search.isRsvp } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, limit: pageSize } : {}),
    restaurantId
  };

  API(API_REQUEST.get, EVENT_API, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminEvents.SetTables, eventsListDTO(res.data.data.data));
      dispatch(Actions.RestaurantAdminEvents.SetEvents, eventsSelectListDTO(res.data.data.data));
      dispatch(Actions.RestaurantAdminEvents.SetTotalCount, res.data.data.pagination.totalItems);
      dispatch(Actions.RestaurantAdmin.SetLoading, false);
    })
    .catch(err => {
      handleError(err);
      dispatch(Actions.RestaurantAdmin.SetLoading, false);
    });
};

export const getEventById = (id, setEventData) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true);

  API(API_REQUEST.get, `${EVENT_API}/${id}`)
    .then(res => {
      const event = res?.data?.data?.eventData;
      setEventData(event)
      dispatch(Actions.RestaurantAdminEvents.SetSlots, res?.data?.data?.eventData?.timeSlots);
      dispatch(Actions.RestaurantAdmin.SetLoading, false);
    })
    .catch(err => {
      handleError(err);
      dispatch(Actions.RestaurantAdmin.SetLoading, false);
    });
};


export const getAllTickets = ({ search, sortBy, orderBy, page = 1 }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    ...(!!search ? { search } : {}),
    ...(!!sortBy ? { sortBy } : {}),
    ...(!!orderBy ? { orderBy } : {}),
    ...(!!page ? { page, 'size': getPageSize() } : {}),
  }
  API(API_REQUEST.get, `${TICKET_API}`, { params })
    .then(res => {
      dispatch(Actions.RestaurantAdminTickets.SetTables, ticketsListDTO(res.data.data.data))
      dispatch(Actions.RestaurantAdminTickets.SetTicket, ticketsSelectListDTO(res.data.data.data))
      dispatch(Actions.RestaurantAdminEvents.SetTotalCount, res.data.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getEventTickets = ({ id }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.get, `${TICKET_API}/events/${id}`)
    .then(res => {
      dispatch(Actions.RestaurantAdminTickets.SetTables, ticketsListDTO(res.data.data))
      dispatch(Actions.RestaurantAdminOrders.SetTotalCount, res.data.data.count)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const getEventOrders = ({ id, page }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.get, `${EVENT_API}/order?page=${page}&size=${getPageSize()}&eventId=${id}`)
    .then(res => {
      dispatch(Actions.RestaurantAdminEvents.SetOrders, res.data.data.data)
      dispatch(Actions.RestaurantAdminOrders.SetTotalCount, res.data.data.pagination)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}


export const getEventCustomer = ({ id, page }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    page,
    size: getPageSize(),
    eventId: id
  }
  API(API_REQUEST.get, `${EVENT_API}/customer/${id}`)
    .then(res => {

      dispatch(Actions.RestaurantAdminEvents.SetCustomers, res.data.data)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const refundPayment = ({ id, eventId }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const params = {
    eventId: eventId,
    eventOrderId: id
  }
  API(API_REQUEST.post, EVENT_PAYMENT_REFUND_API, params)
    .then(res => {
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      toast.success("Refund process initiate.", { position: 'bottom-right', theme: 'colored' });
    })
    .catch(err => {

      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const verifyPayment = ({ sessionId }) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true);
  const params = {
    sessionId
  }
  API(API_REQUEST.post, EVENT_PAYMENT_VERIFY_API, params)
    .then(res => {

      dispatch(Actions.RestaurantAdmin.SetLoading, false)
      toast.success("Verifying payment initiate.", { position: 'bottom-right', theme: 'colored' });
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addEvent = (data, setEventData) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, EVENT_API, data)
    .then((res) => {
      handleSuccess(res)
      const event = res.data.data;
      setEventData(event)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    }).catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addTimeSlots = (data, handleCloseModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${EVENT_API}/recurring`, data)
    .then((res) => {
      handleSuccess(res)
      handleCloseModal()
      dispatch(Actions.RestaurantAdminEvents.SetSlots, res.data.data.timeSlots)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    }).catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteTimeSlots = (id) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${EVENT_API}/timeslot`, { id })
    .then((res) => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    }).catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const addTicket = (data, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, TICKET_API, data)
    .then((res) => {
      handleSuccess(res)
      handleCloseAddModal()
      getEventTickets({ id: data.eventId })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch((error) => {
      handleError(error);
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateEventStatus = (id, eventstatus, search, activeSort, sortOrder, page, handleCloseAddModal, stepPayload) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  const payload = {
    eventstatus: eventstatus === 'draft' ? 'publish' : 'draft',
    tags: stepPayload?.tags || "",
    typeId: stepPayload?.typeId || "",
    categorieId: stepPayload?.categorieId || "",
    subcategorieId: stepPayload?.subcategorieId || "",
  }
  API(API_REQUEST.put, `${EVENT_API}/${id}/publish`, payload)
    .then(res => {
      handleSuccess(res)
      handleCloseAddModal()
      getEvent({ search, activeSort, sortOrder, page })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {

      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateEvent = (id, payload, setEventData) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${EVENT_API}/${id}`, payload)
    .then(res => {
      handleSuccess(res)
      const event = res.data.data;
      setEventData(event)
      getEvent({ page: 1, pageSize: getPageSize() })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const updateTicket = (id, payload, handleCloseAddModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.put, `${TICKET_API}/${id}`, payload)
    .then(res => {
      handleSuccess(res)
      handleCloseAddModal()
      getEventTickets({ id: payload.eventId })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const verifyTicket = (payload) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)

  API(API_REQUEST.post, `${EVENT_API}/order/ticket/customer`, payload)
    .then(res => {
      handleSuccess(res)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteEvent = (id, search, activeSort, sortOrder, page, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.post, `${EVENT_API}/${id}/remove`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal()
      getEvent({ search, activeSort, sortOrder, page })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}

export const deleteTicket = (id, event, handleCloseDeleteModal) => {
  dispatch(Actions.RestaurantAdmin.SetLoading, true)
  API(API_REQUEST.delete, `${TICKET_API}/${id}`)
    .then(res => {
      handleSuccess(res)
      handleCloseDeleteModal()
      getEventTickets({ id: event.value })
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
    .catch(err => {
      handleError(err)
      dispatch(Actions.RestaurantAdmin.SetLoading, false)
    })
}