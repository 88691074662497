// Admin
import AdminDashboard from "../../pages/Admin/pages/Dashboard";
import Restaurants from "../../pages/Admin/pages/Restaurants";
import AdminSubscriptions from "../../pages/Admin/pages/Subscriptions";
import AdminPayments from "../../pages/Admin/pages/Payments";
import AdminSettings from "../../pages/Admin/pages/Settings";
import AdminCms from "../../pages/Admin/pages/Cms";

// Restaurant
import Dashboard from "../../pages/Restaurant/pages/Dashboard";
import Categories from "../../pages/Restaurant/pages/Categories";
import Orders from "../../pages/Restaurant/pages/Orders";
import Profile from "../../pages/Restaurant/pages/Profile";
import Subscription from "../../pages/Restaurant/pages/Subscription";
import Tables from "../../pages/Restaurant/pages/Tables";
import Customers from "../../pages/Restaurant/pages/Customers";
import Products from "../../pages/Restaurant/pages/Products";
import Settings from "../../pages/Restaurant/pages/Settings";
import Offers from "../../pages/Restaurant/pages/Offers";
import Marketing from "../../pages/Restaurant/pages/Marketing";
import Coupons from "../../pages/Restaurant/pages/Coupons";
import HourlyReports from "../../pages/Restaurant/pages/Reports/HourlyReports";
import CategoryReports from "../../pages/Restaurant/pages/Reports/CategoryReports";
import ProductReports from "../../pages/Restaurant/pages/Reports/ProductReports";
import MonthReports from "../../pages/Restaurant/pages/Reports/MonthReports";
import WeekReports from "../../pages/Restaurant/pages/Reports/WeekReports";
import DayReports from "../../pages/Restaurant/pages/Reports/DayReports";
import YearlyReports from "../../pages/Restaurant/pages/Reports/YearlyReports";
import Reservations from "../../pages/Restaurant/pages/Reservations";
import Transactions from "../../pages/Restaurant/pages/Transactions";
import Feedbacks from "../../pages/Restaurant/pages/Feedbacks";
import Tags from "../../pages/Restaurant/pages/Tags";
import Templates from "../../pages/Restaurant/pages/Templates";
import TemplateAnalytics from "../../pages/Restaurant/pages/TemplateAnalytics";
import Chats from "../../pages/Restaurant/pages/Chats";
import ChatsFlow from "../../pages/Restaurant/pages/ChatsFlow";
import ChatsWidget from "../../pages/Restaurant/pages/ChatsWidget";
import ComingSoon from "../../pages/Restaurant/pages/ComingSoon";
import CustomNotification from "../../pages/Restaurant/pages/CustomNotification";
// import QRCodePage from "../../pages/Restaurant/pages/QRCode";
import Event from "../../pages/Restaurant/pages/Event";
import CreateEvent from "../../pages/Restaurant/pages/CreateEvent";
import EventTicket from "../../pages/Restaurant/pages/EventTicket";
import Finance from "../../pages/Restaurant/pages/Finances";
import QRCodeScanner from "./../../pages/Restaurant/pages/QRCodeScanner";
import QRCodeBadge from "./../../pages/Restaurant/pages/QRCodeBadge";
import EventInvitation from "./../../pages/Restaurant/pages/EventInvitation";

export const RestaurantRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Dashboard />,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-single-copy-04 text-primary",
    component: <Orders />,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-circle-08 text-primary",
    component: <Customers />,
    layout: "/admin",
  },
  {
    path: "/tags",
    name: "Tags",
    icon: "ni ni-tag text-primary",
    component: <Tags />,
    layout: "/admin",
  },
  {
    path: "/tables",
    name: "Tables",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: <Tables />,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "ni ni-bullet-list-67 text-primary",
    component: <Categories />,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-box-2 text-primary",
    component: <Products />,
    layout: "/admin",
  },
  {
    path: "/offers",
    name: "Offers and Discounts",
    icon: "ni ni-spaceship text-primary",
    layout: "/admin",
    subMenu: [
      {
        path: "/offers",
        name: "Offers",
        icon: "ni ni-spaceship text-primary",
        component: <Offers />,
        layout: "/offers-and-discounts",
      },
      {
        path: "/coupons",
        name: "Coupons",
        icon: "ni ni-tag text-primary",
        component: <Coupons />,
        layout: "/offers-and-discounts",
      },
    ],
  },
  {
    path: "/marketings",
    name: "Marketings",
    icon: "ni ni-notification-70 text-primary",
    layout: "/admin",
    subMenu: [
      {
        path: "/campaigns",
        name: "Campaigns",
        icon: "ni ni-spaceship text-primary",
        component: <Marketing />,
        layout: "/marketings",
      },
      {
        path: "/templates",
        name: "Templates",
        icon: "ni ni-tag text-primary",
        component: <Templates />,
        layout: "/marketings",
      },
      {
        path: "/templateAnalytics",
        name: "Templates Analytics",
        icon: "ni ni-sound-wave text-primary",
        component: <TemplateAnalytics />,
        layout: "/marketings",
      },
    ],
  },
  {
    path: "/profile",
    name: "My Profile",
    icon: "ni ni-circle-08 text-primary",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-primary",
    component: <Settings />,
    layout: "/admin",
  },
  {
    path: "/subscription",
    name: "Subscription",
    icon: "ni ni-collection text-primary",
    component: <Subscription />,
    layout: "/admin",
  },
  {
    path: "/reservations",
    name: "Reservations",
    icon: "ni ni-bullet-list-67 text-primary",
    component: <Reservations />,
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "ni ni-money-coins text-primary",
    component: <Transactions />,
    layout: "/admin",
  },
  {
    path: "/feedbacks",
    name: "Feedbacks",
    icon: "ni ni-badge text-primary",
    component: <Feedbacks />,
    layout: "/admin",
  },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-single-copy-04 text-primary",
    layout: "/admin",
    subMenu: [
      {
        path: "/hourly",
        name: "Hourly Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <HourlyReports />,
        layout: "/reports",
      },
      {
        path: "/daily",
        name: "Daily Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <DayReports />,
        layout: "/reports",
      },
      {
        path: "/weekly",
        name: "Weekly Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <WeekReports />,
        layout: "/reports",
      },
      {
        path: "/monthly",
        name: "Monthly Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <MonthReports />,
        layout: "/reports",
      },
      {
        path: "/yearly",
        name: "Yearly Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <YearlyReports />,
        layout: "/reports",
      },
      {
        path: "/product",
        name: "Product Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <ProductReports />,
        layout: "/reports",
      },
      {
        path: "/category",
        name: "Category Reports",
        icon: "ni ni-single-copy-04 text-primary",
        component: <CategoryReports />,
        layout: "/reports",
      },
    ],
  },
  {
    path: "/chats",
    name: "Chats",
    icon: "ni ni-chat-round text-primary",
    component: <Chats />,
    layout: "/admin",
  },
  {
    path: "/chatflow",
    name: "Chat Automation",
    icon: "ni ni-vector text-primary",
    component: <ChatsFlow />,
    layout: "/admin",
  },
  {
    path: "/customnotification",
    name: "Notifications ",
    icon: "ni ni ni-bell-55 text-primary",
    component: <CustomNotification />,
    layout: "/admin",
  },

  // {
  //   path: "/catalogs",
  //   name: "Catalogs",
  //   icon: "ni ni-bullet-list-67 text-primary",
  //   component: <ComingSoon />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/customerengagement",
  //   name: "Customer Engagement",
  //   icon: "ni ni-world text-primary",
  //   component: <ComingSoon />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/wpqrcode",
  //   name: "WhatsApp QR Code",
  //   icon: "ni ni-app text-primary",
  //   component: <QRCodePage />,
  //   layout: "/admin",
  // },
  {
    path: "/chatwidget",
    name: "Chat Widget",
    icon: "ni ni-mobile-button text-primary",
    component: <ChatsWidget />,
    layout: "/admin",
  },
  {
    path: "/event",
    name: "Event",
    icon: "ni ni ni-calendar-grid-58 text-primary",
    layout: "/admin",
    subMenu: [
      {
        path: "/event",
        name: "Event",
        icon: "ni ni-paper-diploma text-primary",
        component: <Event />,
        layout: "/event",
      },
      {
        path: "/create",
        name: "Event",
        icon: "ni ni-chat-round text-primary",
        component: <CreateEvent />,
        layout: "/event",
      },
      {
        path: "/edit/:id",
        name: "Event",
        icon: "ni ni-chat-round text-primary",
        component: <CreateEvent />,
        layout: "/event",
      },
      // {
      //   path: "/invitation",
      //   name: "Invitation",
      //   icon: "ni ni-chat-round text-primary",
      //   component: <EventInvitation />,
      //   layout: "/event",
      // },
      {
        path: "/verify",
        name: "Verify Ticket",
        icon: "ni ni-check-bold text-primary",
        component: <QRCodeScanner />,
        layout: "/event",
      },
      {
        path: "/qrbadge",
        name: "QR-Badges",
        icon: "ni ni-badge text-primary",
        component: <QRCodeBadge />,
        layout: "/event",
      },
      {
        path: "/tickets",
        name: "Tickets",
        icon: "ni ni-collection text-primary",
        component: <EventTicket />,
        layout: "/event",
      },
      {
        path: "/finance",
        name: "Finance",
        icon: "ni ni-credit-card text-primary",
        component: <Finance />,
        layout: "/event",
      }
    ]
  },
  {
    path: "/comingoon",
    name: "Coming Soon",
    icon: "ni ni-time-alarm text-primary",
    layout: "/admin",
    subMenu: [
      {
        path: "/wpchatbutton",
        name: "WhatsApp Chat Button",
        icon: "ni ni-chat-round text-primary",
        component: <ComingSoon />,
        layout: "/comingoon",
      },
      {
        path: "/chatwidget",
        name: "Click to WhatsApp Ad",
        icon: "ni ni-mobile-button text-primary",
        component: <ComingSoon />,
        layout: "/comingoon",
      },
      {
        path: "/wpchatlink",
        name: "WhatsApp Chat Link",
        icon: "ni ni-active-40 text-primary",
        component: <ComingSoon />,
        layout: "/comingoon",
      },
      {
        path: "/sharedteaminbox",
        name: "Multi Agent Chat",
        icon: "ni ni-email-83 text-primary",
        component: <ComingSoon />,
        layout: "/comingoon",
      },
    ],
  }
];

export const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <AdminDashboard />,
    layout: "/admin",
  },
  {
    path: "/restaurants",
    name: "Restaurants",
    icon: "ni ni-shop text-primary",
    component: <Restaurants />,
    layout: "/admin",
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: "ni ni-bullet-list-67 text-success",
    component: <AdminSubscriptions />,
    layout: "/admin",
  },
  {
    path: "/payments",
    name: "Payments",
    icon: "ni ni-money-coins text-orange",
    component: <AdminPayments />,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-primary",
    component: <AdminSettings />,
    layout: "/admin",
  },
  {
    path: "/cms",
    name: "CMS",
    icon: "ni ni-tv-2 text-primary",
    component: <AdminCms />,
    layout: "/admin",
  },
];
